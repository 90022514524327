import TimeCapsule from "@mobilemind/common/src/components/TimeCapsule"
import { convertMinutesToHoursMinutes } from "@mobilemind/common/src/functions"
import Plus from "@mobilemind/common/src/icons/Plus"
import yup from "@mobilemind/common/src/utility/yup"
import { Box, FormHelperText } from "@mui/material"
import { createNextState } from "@reduxjs/toolkit"
import { useFormikContext } from "formik"
import { isEmpty, isString, sum } from "lodash"
import moment from "moment"
import pluralize from "pluralize"
import { useMemo } from "react"
import "react-quill/dist/quill.snow.css"
import ButtonSmall from "../../../components/ButtonSmall"
import "../../../styles/external-pd.scss"
import { ExternalEvent, TimeBlock } from "./RecommendedEventModal"
import { TimeBlockList } from "./TimeBlockList"

export const calculateTotalTime = (startDate: string, endDate: string) => {
  return moment(endDate).diff(moment(startDate), "minutes")
}

export const calculateTimeBlocksTime = (timeBlocks: TimeBlock[]) => {
  const hours = sum(timeBlocks.map((block) => Number(block.hours)))
  const minutes = sum(timeBlocks.map((block) => Number(block.minutes)))
  return hours * 60 + minutes
}

export const timeBlockSchema: yup.ObjectSchema<TimeBlock> = yup.object({
  hours: yup.number().min(0).nullable().required().default(null),
  minutes: yup.number().min(0).max(59).nullable().required().default(null),
  categoryId: yup.string().nullable().required(),
})

export const validationSchema2 = yup.object({
  timeBlocks: yup
    .array(timeBlockSchema)
    .min(1)
    .test(
      "time not zero",
      "The total time must not be empty.",
      (value: TimeBlock[] = []) => {
        return calculateTimeBlocksTime(value) > 0
      }
    )
    .test(
      "time not greater than available time",
      "The total time must not be greater than available time.",
      (value: TimeBlock[] = [], context) => {
        const timeBlocksTime = calculateTimeBlocksTime(value)
        const totalTime = calculateTotalTime(
          context.parent.startDate,
          context.parent.endDate
        )
        return context.parent.isDateless || timeBlocksTime <= totalTime
      }
    ),
})

export const ExternalEventStep2 = () => {
  const { errors, touched, setValues, values } =
    useFormikContext<ExternalEvent>()
  const totalTime = useMemo(
    () =>
      convertMinutesToHoursMinutes(
        calculateTotalTime(values.startDate, values.endDate)
      ),
    [values.startDate, values.endDate]
  )

  const timeBlocksTime = useMemo(
    () =>
      convertMinutesToHoursMinutes(calculateTimeBlocksTime(values.timeBlocks)),
    [values.timeBlocks]
  )

  return (
    <>
      <section>
        <header>Available Time</header>
        <span className="helperText">
          Enter the available credit by category
          {!values.isDateless ? (
            <>
              {" "}
              up to the maximum of
              <strong>
                {" "}
                {totalTime.hours} {pluralize("hour", totalTime.hours)}
              </strong>
              {totalTime.minutes > 0 ? (
                <strong>
                  {" "}
                  {totalTime.minutes} {pluralize("minute", totalTime.minutes)}.
                </strong>
              ) : (
                <>.</>
              )}
            </>
          ) : (
            <>.</>
          )}
        </span>

        <TimeBlockList />

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 2,
          }}
        >
          <TimeCapsule
            hours={timeBlocksTime.hours}
            minutes={timeBlocksTime.minutes}
          />
          <ButtonSmall
            onClick={() => {
              setValues(
                createNextState((draft) => {
                  draft.timeBlocks.push(timeBlockSchema.getDefault())
                })
              )
            }}
          >
            <Plus />
            <span style={{ paddingLeft: 5 }}>Add Time Category</span>
          </ButtonSmall>
        </Box>
        {/* Show the error message if its a string (i.e. not a nested error) and if the time blocks have been touched by the user */}
        {isString(errors.timeBlocks) && !isEmpty(touched.timeBlocks) && (
          <FormHelperText error={true} sx={{ mt: 2 }}>
            {errors.timeBlocks}
          </FormHelperText>
        )}
      </section>
    </>
  )
}
