import qs from "qs"
import fetchWrapper from "../functions/fetchWrapper"
import moment from "moment"

export function fetchBackpack(filters, userId) {
  return async (dispatch, getState) => {
    const { dateRange, dateMin, dateMax } = filters
    const { session } = getState()
    const category = Array.isArray(filters.category) ? filters.category : []

    /*
     * This plugin returns backpack data for authenticated users
     *
     * * * /api/backpack?
     * * * &category= [int]
     * * * &start_date = [int (unix timestamp)]
     * * * &end_date = [int (unix timestamp)]
     */

    const query = {}

    if (userId) {
      query.uid = userId
    }

    if (dateRange !== "all") {
      if (dateRange !== "custom" && dateRange !== "schoolYear") {
        query.start_date = moment().startOf(dateRange).unix()
        query.end_date = moment().add(1, "day").endOf(dateRange).unix()
      } else if (dateRange === "schoolYear") {
        let startDate =
          session.group.field_rewind_begin &&
          session.group.field_rewind_begin[0] &&
          session.group.field_rewind_begin[0].value
        let endDate =
          session.group.field_rewind_end &&
          session.group.field_rewind_end[0] &&
          session.group.field_rewind_end[0].value
        if (startDate && endDate) {
          query.start_date = moment(startDate).unix()
          query.end_date = moment(endDate).add(1, "day").unix()
        }
      } else {
        query.start_date = moment(dateMin.split("T")[0]).unix()
        query.end_date = moment(dateMax.split("T")[0]).add(1, "day").unix()
      }
    }

    if (category?.length > 0) {
      query.category = category.join()
    }

    try {
      let response = await fetchWrapper.get(
        "/api/backpack?" + qs.stringify(query)
      )
      if (response.ok) {
        let payload = await response.json()
        return dispatch({ type: "backpack/fetchBackpack", payload })
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function fetchEventsAttended(filters, userId) {
  return async (dispatch, getState) => {
    const { dateRange, dateMin, dateMax, category, print } = filters
    const { currentPage } = getState().backpack.events
    const { session } = getState()

    let data = {}
    let url = "/api/user-events-learner-backpack/user?"

    const query = {
      sort_by: "field_event_date_time_value",
      sort_order: "DESC",
      page: currentPage,
    }

    if (userId) {
      query.uid = userId
      url = "/api/user-events-hq/user?"
      query.field_parent_conference_target_id_op = "empty"
    }

    if (dateRange !== "all") {
      if (dateRange !== "custom" && dateRange !== "schoolYear") {
        query.field_event_date_time_value = {
          min: moment().startOf(dateRange).format("YYYY-MM-DD"),
          max: moment().add(1, "day").endOf(dateRange).format("YYYY-MM-DD"),
        }
      } else if (dateRange === "schoolYear") {
        let startDate =
          session.group.field_rewind_begin &&
          session.group.field_rewind_begin[0] &&
          session.group.field_rewind_begin[0].value
        let endDate =
          session.group.field_rewind_end &&
          session.group.field_rewind_end[0] &&
          session.group.field_rewind_end[0].value
        if (startDate && endDate) {
          query.field_event_date_time_value = {
            min: moment(startDate).format("YYYY-MM-DD hh:mm:ss"),
            max: moment(endDate).format("YYYY-MM-DD hh:mm:ss"),
          }
        }
      } else {
        query.field_event_date_time_value = {
          min: moment(dateMin.split("T")[0]).format("YYYY-MM-DD hh:mm:ss"),
          max: moment(dateMax.split("T")[0])
            .add(1, "day")
            .format("YYYY-MM-DD hh:mm:ss"),
        }
      }
    }

    if (category?.length > 0) {
      query.field_event_category_target_id = category
    }

    let response = await fetchWrapper.get(url + qs.stringify(query))

    if (response.ok) {
      data = await response.json()
      data.rows = data.rows.content ? [] : data.rows

      if (print) {
        let pages = data.pager ? data.pager.total_records : 0
        let i = 1

        while (i < pages) {
          query.page = i
          response = await fetchWrapper.get(url + qs.stringify(query))

          if (response.ok) {
            let nextPage = await response.json()
            data.rows = data.rows.concat(nextPage.rows)
          }
          i++
        }
      }
    }

    return dispatch({ type: "backpack/fetchEventsAttended", payload: data })
  }
}

export function getHQConferenceSessions({ userId, conferenceId }) {
  return async (dispatch, getState) => {
    const { currentPage } = getState().backpack.events
    let data = {}

    const url = "/api/user-events-hq/user?"

    const query = {
      sort_by: "field_event_date_time_value",
      sort_order: "DESC",
      page: currentPage,
      uid: userId,
      field_parent_conference_target_id_op: "=",
      field_parent_conference_target_id: {
        value: conferenceId,
      },
    }

    const stringifiedQuery = qs.stringify(query)

    let response = await fetchWrapper.get(url + stringifiedQuery)

    if (response.ok) {
      data = await response.json()
      data.rows = data.rows.content ? [] : data.rows

      if (print) {
        let pages = data.pager ? data.pager.total_records : 0
        let i = 1

        while (i < pages) {
          query.page = i
          response = await fetchWrapper.get(url + qs.stringify(query))

          if (response.ok) {
            let nextPage = await response.json()
            data.rows = data.rows.concat(nextPage.rows)
          }
          i++
        }
      }
    }

    return data
  }
}

export function fetchSingleEventAttended({ eventId }) {
  return async () => {
    let data = {}

    const query = {
      event_uuid: eventId,
    }

    let response = await fetchWrapper.get(
      "/api/user-events-learner/user?" + qs.stringify(query)
    )

    if (response.ok) {
      data = await response.json()
      data.rows = data.rows.content ? [] : data.rows
    }

    return data
  }
}

export function fetchExternalApprovals(filters, userId) {
  return async (dispatch, getState) => {
    const { dateRange, dateMin, dateMax, print } = filters
    const { currentPage } = getState().backpack.external
    const { session } = getState()

    const category = Array.isArray(filters.category) ? filters.category : []

    let data
    const query = {
      status: "accepted",
      page: {
        offset: currentPage * 25,
      },
    }

    if (userId) {
      query.uid = userId
    }

    if (dateRange !== "all") {
      if (dateRange !== "custom" && dateRange !== "schoolYear") {
        query.start_date = moment().startOf(dateRange).format("YYYY-MM-DD")
        query.end_date = moment()
          .endOf(dateRange)
          .add(1, "hour")
          .format("YYYY-MM-DD")
      } else if (dateRange === "schoolYear") {
        let startDate =
          session.group.field_rewind_begin &&
          session.group.field_rewind_begin[0] &&
          session.group.field_rewind_begin[0].value
        let endDate =
          session.group.field_rewind_end &&
          session.group.field_rewind_end[0] &&
          session.group.field_rewind_end[0].value
        if (startDate && endDate) {
          query.start_date = moment(startDate).format("YYYY-MM-DD")
          query.end_date = moment(endDate).format("YYYY-MM-DD")
        }
      } else {
        query.start_date = moment(dateMin.split("T")[0]).format("YYYY-MM-DD")
        query.end_date = moment(dateMax.split("T")[0])
          .add(1, "day")
          .format("YYYY-MM-DD")
      }
    }

    let stringifiedQuery = qs.stringify(query)

    category?.forEach((cat, index) => {
      stringifiedQuery += "&category[" + index + "]=" + cat
    })

    let response = await fetchWrapper.get(
      "/api/mm_ext_usr_event/learn?" + stringifiedQuery
    )

    if (response.ok) {
      data = await response.json()

      if (print) {
        let pages = Math.ceil(data.total_records / 25)
        let i = 1

        while (i < pages) {
          query.page.offset = i * 25
          response = await fetchWrapper.get(
            "/api/mm_ext_usr_event/learn?" + qs.stringify(query)
          )

          if (response.ok) {
            let nextPage = await response.json()
            data.external_user_event_data =
              data.external_user_event_data.concat(
                nextPage.external_user_event_data
              )
          }
          i++
        }
      }
      return dispatch({
        type: "backpack/fetchExternalApprovals",
        payload: data,
      })
    }
  }
}

export function fetchEarnedBadges(filters, userId) {
  return async (dispatch, getState) => {
    const { currentPage } = getState().backpack.badges
    const { print, dateRange, dateMin, dateMax } = filters
    const { isPartner } = getState().session
    const { session } = getState()

    const url = isPartner
      ? "/api/mm_partner_portal/badges_explore?"
      : "/api/badges_entity/explore?"

    let data
    let query = {
      archive: true,
      earned: "earned",
      sort_by: "earned_date",
      sort_order: "DESC",
      page: {
        offset: currentPage * 25,
      },
    }

    if (userId) {
      query.uid = userId
    }

    if (dateRange !== "all") {
      if (dateRange !== "custom" && dateRange !== "schoolYear") {
        query.start = moment().startOf(dateRange).format("YYYY-MM-DD")
        query.end = moment().add(1, "day").endOf(dateRange).format("YYYY-MM-DD")
      } else if (dateRange === "schoolYear") {
        let startDate =
          session.group.field_rewind_begin &&
          session.group.field_rewind_begin[0] &&
          session.group.field_rewind_begin[0].value
        let endDate =
          session.group.field_rewind_end &&
          session.group.field_rewind_end[0] &&
          session.group.field_rewind_end[0].value
        if (startDate && endDate) {
          query.start = moment(startDate).format("YYYY-MM-DD")
          query.end = moment(endDate).format("YYYY-MM-DD")
        }
      } else {
        query.start = moment(dateMin.split("T")[0]).format("YYYY-MM-DD")
        query.end = moment(dateMax.split("T")[0])
          .add(1, "day")
          .format("YYYY-MM-DD")
      }
    }

    let response = await fetchWrapper.get(url + qs.stringify(query))

    if (response.ok) {
      data = await response.json()

      if (print) {
        let pages = Math.ceil(data.total_records / 25)
        let i = 1

        while (i < pages) {
          query.page.offset = i * 25
          response = await fetchWrapper.get(url + qs.stringify(query))

          if (response.ok) {
            let nextPage = await response.json()
            data.badges_data = data.badges_data.concat(nextPage.badges_data)
          }
          i++
        }
      }
    }

    return dispatch({ type: "backpack/fetchEarnedBadges", payload: data })
  }
}

export function fetchCompletedCourses(filters, userId) {
  return async (dispatch, getState) => {
    const { dateRange, dateMin, dateMax, print } = filters
    const { currentPage } = getState().backpack.courses
    const { session } = getState()

    const category = Array.isArray(filters.category) ? filters.category : []

    const url = session.isPartner
      ? "/api/mm_partner_portal/course_explore?"
      : "/api/course_entity/explore?"

    let data
    let query = {
      status: "completed",
      archive: true,
      sort_order: "DESC",
      page: {
        offset: currentPage * 25,
      },
    }

    category?.forEach((cat, index) => {
      query["category[" + index + "]"] = cat
    })

    if (userId) {
      query.uid = userId
    }

    if (dateRange !== "all") {
      if (dateRange !== "custom" && dateRange !== "schoolYear") {
        query.start = moment().startOf(dateRange).format("YYYY-MM-DD")
        query.end = moment().add(1, "day").endOf(dateRange).format("YYYY-MM-DD")
      } else if (dateRange === "schoolYear") {
        let startDate =
          session.group.field_rewind_begin &&
          session.group.field_rewind_begin[0] &&
          session.group.field_rewind_begin[0].value
        let endDate =
          session.group.field_rewind_end &&
          session.group.field_rewind_end[0] &&
          session.group.field_rewind_end[0].value
        if (startDate && endDate) {
          query.start = moment(startDate).format("YYYY-MM-DD")
          query.end = moment(endDate).format("YYYY-MM-DD")
        }
      } else {
        query.start = moment(dateMin.split("T")[0]).format("YYYY-MM-DD")
        query.end = moment(dateMax.split("T")[0])
          .add(1, "day")
          .format("YYYY-MM-DD")
      }
    }

    let response = await fetchWrapper.get(url + qs.stringify(query))

    if (response.ok) {
      data = await response.json()

      if (print) {
        let pages = Math.ceil(data.total_records / 25)
        let i = 1

        while (i < pages) {
          query.page.offset = i * 25
          response = await fetchWrapper.get(url + qs.stringify(query))

          if (response.ok) {
            let nextPage = await response.json()
            data.data = data.data.concat(nextPage.data)
          }
          i++
        }
      }

      return dispatch({
        type: "backpack/fetchCompletedCourses",
        payload: data,
      })
    }
  }
}

export function fetchCompletedLPs(filters, userId) {
  return async (dispatch, getState) => {
    const { dateRange, dateMin, dateMax, print } = filters
    const { currentPage } = getState().backpack.learningPaths
    const { session } = getState()

    const url = session.isPartner
      ? "/api/mm_partner_portal/lp_explore?"
      : "/api/learning_path/explore?"

    let data
    let query = {
      status: "completed",
      archive: true,
      sort_order: "DESC",
      page: {
        offset: currentPage * 25,
      },
    }

    if (userId) {
      query.uid = userId
    }

    if (dateRange !== "all") {
      if (dateRange !== "custom" && dateRange !== "schoolYear") {
        query.start = moment().startOf(dateRange).format("YYYY-MM-DD")
        query.end = moment().add(1, "day").endOf(dateRange).format("YYYY-MM-DD")
      } else if (dateRange === "schoolYear") {
        let startDate =
          session.group.field_rewind_begin &&
          session.group.field_rewind_begin[0] &&
          session.group.field_rewind_begin[0].value
        let endDate =
          session.group.field_rewind_end &&
          session.group.field_rewind_end[0] &&
          session.group.field_rewind_end[0].value
        if (startDate && endDate) {
          query.start = moment(startDate).format("YYYY-MM-DD")
          query.end = moment(endDate).format("YYYY-MM-DD")
        }
      } else {
        query.start = moment(dateMin.split("T")[0]).format("YYYY-MM-DD")
        query.end = moment(dateMax.split("T")[0])
          .add(1, "day")
          .format("YYYY-MM-DD")
      }
    }

    let response = await fetchWrapper.get(url + qs.stringify(query))

    if (response.ok) {
      data = await response.json()

      if (print) {
        let pages = Math.ceil(data.total_records / 25)
        let i = 1

        while (i < pages) {
          query.page.offset = i * 25
          response = await fetchWrapper.get(url + qs.stringify(query))

          if (response.ok) {
            let nextPage = await response.json()
            data.lp_data = data.lp_data.concat(nextPage.lp_data)
          }
          i++
        }
      }

      return dispatch({ type: "backpack/fetchCompletedLPs", payload: data })
    }
  }
}

export function fetchUserRubrics(filters, userId, isRubricsOnly) {
  return async (dispatch, getState) => {
    const { currentPage } = getState().backpack.userRubrics
    const { session } = getState()
    const { dateRange, dateMax, dateMin } = filters

    const isObserver =
      session.groupRoles.includes("group-observer") ||
      session.orgRoles.includes("organization-observer")

    const shouldHitObserver =
      isObserver &&
      !session.orgRoles.includes("organization-observation_admin") &&
      !session.groupRoles.includes("group-observation_admin")

    const groupId = session.group.id[0].value

    let url = userId
      ? "/api/user-rubric-hq/" + groupId + "?"
      : "/api/user-rubric?"

    if (userId && session.groupRoles.includes("group-observation_admin")) {
      const subgroupsArray = session.subgroups.data
        .map((subgroup) => subgroup.drupal_internal__id)
        .join("+")
      url =
        "/api/user-rubric-hq/" + groupId + "/subgroup/" + subgroupsArray + "?"
    }
    let data
    let query = {
      page: currentPage * 25,
    }

    if (dateRange !== "all") {
      let schoolStart =
        session.group.field_rewind_begin &&
        session.group.field_rewind_begin[0] &&
        session.group.field_rewind_begin[0].value

      let schoolEnd =
        session.group.field_rewind_end &&
        session.group.field_rewind_end[0] &&
        session.group.field_rewind_end[0].value

      if (dateRange === "week") {
        query.completed_date = {
          min: moment().startOf("week").format("YYYY-MM-DD"),
          max: moment().endOf("week").format("YYYY-MM-DD"),
        }
      }
      if (dateRange === "month") {
        query.completed_date = {
          min: moment().startOf("month").format("YYYY-MM-DD"),
          max: moment().endOf("month").format("YYYY-MM-DD"),
        }
      }
      if (dateRange === "year") {
        query.completed_date = {
          min: moment().startOf("year").format("YYYY-MM-DD"),
          max: moment().endOf("year").format("YYYY-MM-DD"),
        }
      }
      if (dateRange === "schoolYear") {
        query.completed_date = {
          min: moment(schoolStart).format("YYYY-MM-DD"),
          max: moment(schoolEnd).format("YYYY-MM-DD"),
        }
      }
    }

    if (shouldHitObserver && userId) {
      url = "/api/user-rubric-hq/observer?"
    }

    if (dateRange === "custom") {
      query.completed_date = {
        min: moment(dateMin.split("T")[0]).format("YYYY-MM-DD"),
        max: moment(dateMax.split("T")[0]).format("YYYY-MM-DD"),
      }
    }

    if (userId) {
      query.field_learner_target_id = userId
    }

    let response = await fetchWrapper.get(url + qs.stringify(query))

    if (response.ok) {
      data = await response.json()

      if (isRubricsOnly && userId) {
        let user
        let query = {
          uid: userId,
          role: "all",
        }

        let userResponse = await fetchWrapper.get(
          "/api/organization-members/" +
            session.group.id[0].value +
            "?" +
            qs.stringify(query)
        )

        if (userResponse.ok) {
          let userData = await userResponse.json()
          user = {
            field_first_name_value: userData.rows[0]?.field_first_name,
            field_last_name_value: userData.rows[0]?.field_last_name,
            user_image:
              process.env.REACT_APP_API_URL + userData.rows[0]?.user_picture,
            user_subgroups: userData.rows[0]?.field_subgroup,
          }
        }

        data.userData = user
      }

      const uniqueData = []

      if (!data.rows.content) {
        data.rows.forEach((row) => {
          if (
            !uniqueData.find(
              (existingRow) =>
                existingRow.user_rubric_uuid === row.user_rubric_uuid
            )
          ) {
            uniqueData.push(row)
          }
        })
      }

      data.rows = uniqueData

      return dispatch({
        type: "backpack/fetchUserRubrics",
        payload: data,
      })
    }
  }
}

export function fetchMandatedTrainingHistory(filters, userId, rcsId) {
  return async (dispatch, getState) => {
    const { dateRange, dateMin, dateMax, print } = filters
    const { currentPage } = getState().backpack.mandatedTraining

    const { session } = getState()
    let data = {}

    const orgId = session.group.id[0].value
    let url = "/api/user-mta?"

    const query = {
      sort_by: "in_comp_date",
      sort_order: "DESC",
      page: currentPage,
    }

    if (userId) {
      query.uid = userId
      url = "/api/user-mta-hq/" + orgId + "?"
    } else {
      query.uid = session.user.attributes.drupal_internal__uid
    }
    if (rcsId) {
      query.rcs_id = rcsId
    }

    if (dateRange !== "all") {
      if (dateRange !== "custom" && dateRange !== "schoolYear") {
        query.field_comp_date = {
          min: moment().startOf(dateRange).format("YYYY-MM-DD"),
          max: moment().add(1, "day").endOf(dateRange).format("YYYY-MM-DD"),
        }
      } else if (dateRange === "schoolYear") {
        let startDate =
          session.group.field_rewind_begin &&
          session.group.field_rewind_begin[0] &&
          session.group.field_rewind_begin[0].value
        let endDate =
          session.group.field_rewind_end &&
          session.group.field_rewind_end[0] &&
          session.group.field_rewind_end[0].value
        if (startDate && endDate) {
          query.field_comp_date = {
            min: moment(startDate).format("YYYY-MM-DD hh:mm:ss"),
            max: moment(endDate).format("YYYY-MM-DD hh:mm:ss"),
          }
        }
      } else {
        query.field_comp_date = {
          min: moment(dateMin.split("T")[0]).format("YYYY-MM-DD hh:mm:ss"),
          max: moment(dateMax.split("T")[0])
            .add(1, "day")
            .format("YYYY-MM-DD hh:mm:ss"),
        }
      }
    }

    let response = await fetchWrapper.get(url + qs.stringify(query))

    if (response.ok) {
      data = await response.json()
      data.rows = data.rows.content ? [] : data.rows

      if (print) {
        let pages = data.pager ? data.pager.total_records : 0
        let i = 1

        while (i < pages) {
          query.page = i
          response = await fetchWrapper.get(url + qs.stringify(query))

          if (response.ok) {
            let nextPage = await response.json()
            data.rows = data.rows.concat(nextPage.rows)
          }
          i++
        }
      }
    }
    return dispatch({
      type: "backpack/fetchMandatedTrainingHistory",
      payload: data,
    })
  }
}

export function fetchSingleMandatedTrainingArchive(archiveId) {
  return async () => {
    const response = await fetchWrapper.get("/api/user-mta?id=" + archiveId)

    if (response.ok) {
      const data = await response.json()
      if (!data.content) {
        return data.rows[0]
      }
    }
  }
}
