import ButtonSmall from "@mobilemind/common/src/components/ButtonSmall"
import { useEffect, useState } from "react"
import { useAppSelector } from "store/hooks"
import { useAppDispatch } from "../../store/hooks"

import {
  getSingleRubric,
  setCriteriaComments,
  setCriteriaCommentsActive,
  setCriteriaNA,
  submitRubric,
  updateCriteriaScore,
  updateTotalScore,
} from "../../store/reducers/rubrics"
import "./rubric.scss"

import classNames from "classnames"

import ButtonLarge from "@mobilemind/common/src/components/ButtonLarge"
import Loading from "@mobilemind/common/src/components/Loading"
import UserInitial from "@mobilemind/common/src/components/UserInitial"
import nl2br from "nl2br"

import { TextField } from "@mui/material"
import { openSnackbar } from "../snackbar/snackbarSlice"
import SanitizedHTML from "@mobilemind/common/src/components/SanitizedHTML"

function RubricLayout(props) {
  const dispatch = useAppDispatch()

  const rubrics = useAppSelector((state) => state.rubrics)

  const [activeDescription, setActiveDescription] = useState(null)
  const [fetched, setFetched] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { userData } = rubrics
  const userUUID = userData?.uuid ?? props.activeEvent?.observationUser?.id

  let rubricId = props.match.params.rubricId
  let userId = props.match.params.userId
  let firstName = userData?.firstName
  let lastName = userData?.lastName
  let userEventUUID

  if (props.activeEvent) {
    rubricId = props.activeEvent.observationRubricUUID
    userId = props.activeEvent.invitees[0]?.uid
    const targetInvitee = props.activeEvent.invitees[0]
    firstName = targetInvitee?.field_first_name
    lastName = targetInvitee?.field_last_name
    userEventUUID = targetInvitee?.user_event_uuid
  }

  useEffect(() => {
    if (!fetched) {
      dispatch(
        getSingleRubric({
          rubricId,
          userId,
        })
      )
      setFetched(true)
    }
  }, [fetched, rubricId, userId, dispatch])

  const activeRubric = rubrics.activeRubric.data

  // Don't let them publish unless they've scored everything
  let allowPublish = true
  activeRubric.domain &&
    activeRubric.domain.forEach((domain) => {
      domain.criteria &&
        domain.criteria.forEach((criteria) => {
          if (criteria.score === "unselected" && !criteria.isNA) {
            allowPublish = false
          }
        })
    })

  function goBack() {
    props.history.push("/backpack/" + props.match.params.userId)
  }

  return (
    <div className="page rubric-layout">
      {(rubrics.activeRubric.submitting || isSubmitting) && (
        <div
          style={{
            marginLeft: 10,
            position: "relative",
            top: 2,
          }}
        >
          <Loading
            fullPage={true}
            message={
              <div style={{ textAlign: "center" }}>
                Submitting rubric, please <br />
                do not leave this page.
              </div>
            }
          />
        </div>
      )}
      {!props.activeEvent && (
        <>
          <div className={classNames("backButton")}>
            <ButtonSmall onClick={() => goBack()}>
              <span className="icon" />
            </ButtonSmall>
          </div>

          {userData?.firstName && activeRubric?.name && (
            <div id="topBar">
              <ButtonSmall
                onClick={() => {
                  goBack()
                }}
              >
                <span className="icon back" />
              </ButtonSmall>
              {userData.firstName && (
                <div>
                  {!userData.userPicture ||
                  userData.userPicture.includes("default_") ? (
                    <UserInitial
                      containerStyle={{
                        marginBottom: 3,
                        marginRight: 8,
                      }}
                      fontSize={12}
                      size={30}
                      initials={
                        userData.firstName.charAt(0) +
                        userData.lastName.charAt(0)
                      }
                    />
                  ) : (
                    <div className="userPictureWrapper">
                      <span
                        className="userPicture"
                        style={{
                          backgroundImage: "url(" + userData.userPicture + ")",
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                        }}
                      />
                    </div>
                  )}
                </div>
              )}

              <div className="userInfo">
                <strong className="userName">
                  {userData.firstName} {userData.lastName}
                </strong>
                <span className="groupName">{userData.group}</span>
              </div>

              {activeRubric.name && (
                <div className="title">
                  <div className="flexRow">
                    <span className="icon check-list" />
                    <strong>{activeRubric.name}</strong>
                  </div>
                </div>
              )}

              <div className="scoreTotals" style={{ marginRight: 20 }}>
                <div className="flexRow">
                  <strong>{rubrics.activeRubric.totalScore}</strong>
                </div>
              </div>
            </div>
          )}
        </>
      )}

      {rubrics.activeRubric.fetched ? (
        <>
          <div
            className={classNames(
              "rubric-container",
              rubrics.activeRubric.submitting && "isSubmitting"
            )}
          >
            <ul id="domain-list">
              {activeRubric.domain.map((domain, domainIndex) => {
                return (
                  <li key={domainIndex} className="domain-item">
                    <div className="flexRow domain-name">
                      <h3>
                        <span className="icon venn" />
                        {domain.name}
                      </h3>
                      <header>Domain {domainIndex + 1}</header>
                    </div>
                    <div className="inner">
                      {domain.description && (
                        <p className="description">{domain.description}</p>
                      )}

                      <ul className="criteria-list">
                        {domain.criteria.map((criteria, criteriaIndex) => {
                          return (
                            <li key={criteriaIndex}>
                              <header className="criteria-name">
                                <span className="icon list" />
                                <span style={{ flex: 1 }}>{criteria.name}</span>
                                <div
                                  className={classNames(
                                    "criteria-toolbar",
                                    criteria.isNA && "isNA"
                                  )}
                                >
                                  <div className="na-button">
                                    <ButtonSmall
                                      onClick={() => {
                                        dispatch(
                                          setCriteriaNA({
                                            domainIndex,
                                            criteriaIndex,
                                          })
                                        )
                                      }}
                                    >
                                      <span className="icon stop" />
                                      <div className="popover">
                                        Not Applicable
                                      </div>
                                    </ButtonSmall>
                                  </div>
                                  {!criteria.isNA &&
                                    !criteria.commentsActive && (
                                      <ButtonSmall
                                        onClick={() => {
                                          dispatch(
                                            setCriteriaCommentsActive({
                                              domainIndex,
                                              criteriaIndex,
                                            })
                                          )
                                        }}
                                      >
                                        <span className="comment icon" />
                                        <div
                                          className="popover"
                                          style={{
                                            padding: criteria.commentsActive
                                              ? "0 10px"
                                              : 0,
                                          }}
                                        >
                                          Add Comments
                                        </div>
                                      </ButtonSmall>
                                    )}

                                  {criteria.description && (
                                    <ButtonSmall
                                      onClick={() => {
                                        if (
                                          activeDescription &&
                                          activeDescription.domainIndex ===
                                            domainIndex &&
                                          activeDescription.criteriaIndex ===
                                            criteriaIndex
                                        ) {
                                          setActiveDescription(null)
                                        } else {
                                          setActiveDescription({
                                            domainIndex,
                                            criteriaIndex,
                                          })
                                        }
                                      }}
                                    >
                                      <span className="icon info" />
                                      <div className="popover">Toggle Info</div>
                                    </ButtonSmall>
                                  )}
                                </div>
                              </header>

                              {activeDescription &&
                              activeDescription.domainIndex === domainIndex &&
                              activeDescription.criteriaIndex ===
                                criteriaIndex ? (
                                <div className="criteria-description">
                                  <SanitizedHTML
                                    html={nl2br(criteria.description)}
                                  />
                                </div>
                              ) : (
                                <></>
                              )}

                              <div
                                className={classNames(
                                  "flexRow criteria-grid",
                                  criteria.isNA && "not-applicable"
                                )}
                              >
                                {criteria.criteria_levels.map(
                                  (level, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className={classNames(
                                          "criteria-item",
                                          Number(level.points) ===
                                            criteria.score && "active"
                                        )}
                                        onClick={() => {
                                          dispatch(
                                            updateCriteriaScore({
                                              domainIndex,
                                              criteriaIndex,
                                              points: level.points,
                                            })
                                          )
                                          dispatch(updateTotalScore())
                                        }}
                                      >
                                        <header>{level.name}</header>
                                        <SanitizedHTML
                                          html={nl2br(level.description)}
                                        />
                                      </div>
                                    )
                                  }
                                )}
                              </div>
                              {criteria.commentsActive && (
                                <div className="flexRow criteria-comments">
                                  <div className="icon comment" />
                                  <TextField
                                    label="Comments"
                                    onChange={(event) => {
                                      dispatch(
                                        setCriteriaComments({
                                          domainIndex,
                                          criteriaIndex,
                                          value: event.target.value,
                                        })
                                      )
                                    }}
                                    variant="standard"
                                    value={criteria.comments}
                                    multiline
                                    placeholder="Enter your comments here"
                                  />
                                  <div
                                    style={{
                                      marginTop: 15,
                                      marginLeft: 20,
                                    }}
                                  >
                                    <ButtonSmall
                                      onClick={() => {
                                        dispatch(
                                          setCriteriaCommentsActive({
                                            domainIndex,
                                            criteriaIndex,
                                          })
                                        )
                                      }}
                                    >
                                      Remove Comments
                                    </ButtonSmall>
                                  </div>
                                </div>
                              )}
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  </li>
                )
              })}
            </ul>

            {allowPublish && !rubrics.activeRubric.submitting && (
              <div className={classNames("buttonContainer")}>
                <ButtonLarge
                  onClick={() => {
                    setIsSubmitting(true)
                    dispatch(
                      submitRubric({
                        userEventUUID,
                        firstName,
                        lastName,
                        userUUID,
                      })
                    ).then(() => {
                      dispatch(
                        openSnackbar({
                          message:
                            "Rubric submitted for " +
                            firstName +
                            " " +
                            lastName +
                            ".",
                        })
                      )
                      setIsSubmitting(false)
                      if (!userEventUUID) {
                        goBack()
                      } else {
                        props.handleSubmit()
                      }
                    })
                  }}
                >
                  <span className="icon submit" />
                  Submit Rubric
                </ButtonLarge>
              </div>
            )}
          </div>
        </>
      ) : (
        <Loading
          fullPage={props.activeEvent ? false : true}
          message={"Getting rubric..."}
        />
      )}
    </div>
  )
}

export default RubricLayout
