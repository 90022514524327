import { Component } from "react"
import moment from "moment"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { Box, Button, Popover } from "@mui/material"
import classNames from "classnames"
import SanitizedHTML from "@mobilemind/common/src/components/SanitizedHTML"
import { addressToString } from "@mobilemind/common/src/functions"

import {
  fetchActiveEvent,
  startNewEvent,
} from "../eventSingle/activeEventSlice"
import {
  updateConferenceField,
  fetchSessionsForConference,
} from "../conference/activeConferenceSlice"

import he from "he"

import {
  saveEventBase,
  changeSessionColor,
  saveEventAddOn,
  savePersonnel,
  attachEventAddOn,
  fetchEventAttendance,
} from "../../../actions/events"

import {
  toggleExternalEventModal,
  openEvent,
} from "../../../store/reducers/externalPD"

import EditIcon from "../../../img/edit.svg"
import ObservationIcon from "../../../img/observation-white.svg"
import RubricIcon from "../../../img/shopping-list.svg"
import UserInitial from "@mobilemind/common/src/components/UserInitial"

import sessionColors from "./sessionColors"
import appointmentColors from "./appointmentColors"
import AttendanceSummary from "../AttendanceSummary"
import Loading from "@mobilemind/common/src/components/Loading"
import { isArray } from "lodash"

const mapStateToProps = (
  { activeConference, categories, session },
  ownProps
) => {
  // Dig the current session out of the state
  let currentConferenceEvent
  if (activeConference.conferenceEvents.length) {
    currentConferenceEvent = activeConference.conferenceEvents.find(
      (confEvent) => confEvent.event_uuid === ownProps.appointmentData.id
    )
  }

  const { appointmentData } = ownProps

  let categoryId = null
  if (appointmentData.isSession) {
    if (appointmentData.category && appointmentData.category.meta) {
      categoryId = appointmentData.category.meta.drupal_internal__target_id
    }
  } else {
    categoryId = appointmentData.categoryId
  }

  let category = categories.data.find((cat) => {
    return cat.attributes.drupal_internal__tid === categoryId
  })

  return {
    categories,
    category,
    session,
    currentConferenceEvent,
    activeConference,
  }
}

const mapDispatchToProps = {
  startNewEvent,
  saveEventBase,
  saveEventAddOn,
  attachEventAddOn,
  changeSessionColor,
  savePersonnel,
  fetchActiveEvent,
  fetchEventAttendance,
  fetchSessionsForConference,
  updateConferenceField,
  toggleExternalEventModal,
  openEvent,
}

class EventTooltip extends Component {
  state = {
    colorAnchorEl: null,
    selectedColor: null,
    attendance: null,
  }

  componentDidMount = async () => {
    const { currentConferenceEvent, appointmentData } = this.props
    let eventId = currentConferenceEvent
      ? currentConferenceEvent.id
      : appointmentData.drupal_internal__id

    if (!this.props.appointmentData.isExternal) {
      this.props.fetchEventAttendance(eventId).then((response) => {
        this.setState({ attendance: response.attendance_data })
      })
    }
  }

  copySession = (event) => {
    this.props.updateConferenceField({ field: "isCopyingEvent", value: true })

    const isCopy = true
    const isDraft = true
    const isSession = true

    this.props
      .fetchActiveEvent({ id: event.drupal_internal__id })
      .then((eventResponse) => {
        this.props
          .saveEventBase({ copiedEvent: event, isDraft, isCopy, isSession })
          .then((eventBase) => {
            this.props
              .saveEventAddOn({ eventBase, isSession, isCopy })
              .then((addOn) => {
                this.props
                  .attachEventAddOn({
                    eventBase,
                    addOn,
                    isDraft,
                    isCopy,
                    isSession,
                  })
                  .then(() => {
                    const personnel =
                      eventResponse.payload.included &&
                      eventResponse.payload.included.filter((included) => {
                        return (
                          included.type ===
                          "mm_event_personnel--mm_event_personnel"
                        )
                      })

                    this.props.savePersonnel({
                      event: eventBase,
                      personnel,
                      isCopy,
                    })
                  })
                  .then(() => {
                    this.props
                      .fetchSessionsForConference({
                        id: this.props.activeConference.drupal_internal__id,
                      })
                      .then(() => {
                        this.props.updateConferenceField({
                          field: "isCopyingEvent",
                          value: false,
                        })
                      })
                    this.props.startNewEvent()
                  })
              })
          })
      })
  }

  render() {
    const { colorAnchorEl, selectedColor, attendance } = this.state

    const {
      image,
      title,
      startDate,
      endDate,
      description,
      location,
      isSession,
      isConference,
      isObservation,
      observationUser,
      isAllDay,
      field_draft,
      field_parent_conference,
      drupal_internal__id,
      isExternal,
      loadConferenceEvent,
      bundle,
      eventRole,
    } = this.props.appointmentData

    const { currentConferenceEvent, categories, activeConference, session } =
      this.props

    const category = currentConferenceEvent
      ? categories.data.find(
          (cat) =>
            cat.attributes.drupal_internal__tid ===
            Number(currentConferenceEvent.field_event_category)
        )
      : this.props.category

    const { orgRoles, groupRoles } = session

    const allRoles = orgRoles.concat(groupRoles).join(",")

    let isSingleDayEvent =
      moment(this.props.appointmentData.startDate).format("DD") ===
        moment(endDate).format("DD") ||
      moment(this.props.appointmentData.startDate).format("DD") ===
        moment(endDate).subtract(1, "hour").format("DD")

    let canSchedule =
      allRoles.includes("admin") ||
      allRoles.includes("scheduler") ||
      allRoles.includes("observer") ||
      eventRole === "Manager" ||
      eventRole === "Presenter"

    let eventLabel = "Event"
    if (isConference || bundle === "conference") {
      eventLabel = "Conference"
    }
    if (isSession || bundle === "conference_event") {
      eventLabel = "Session"
    }
    if (isObservation || bundle === "observation") {
      eventLabel = "Observation"
    }

    let viewUrl = "/events/view/event/" + drupal_internal__id
    if (isConference) {
      viewUrl = "/events/view/conference/" + drupal_internal__id
    } else if (isSession || bundle === "conference_event") {
      viewUrl = "/events/view/session/" + drupal_internal__id
    } else if (isObservation || bundle === "observation") {
      viewUrl = "/events/view/observation/" + drupal_internal__id
    }

    let isConferenceManager

    let hasImage =
      ((!image &&
        currentConferenceEvent &&
        currentConferenceEvent.image &&
        currentConferenceEvent.image.image) ||
        image) &&
      true

    if (isSession) {
      let targetConferencePersonnel =
        activeConference.personnel &&
        activeConference.personnel.find((user) => user.id === session.user.id)
      let conferenceRole =
        targetConferencePersonnel &&
        targetConferencePersonnel.field_event_role_name
      isConferenceManager =
        conferenceRole === "Manager" || conferenceRole === "Presenter"
    }

    let backgroundImageSrc
    let backgroundColor = appointmentColors.event

    if (image) {
      if (image.attributes) {
        backgroundImageSrc =
          process.env.REACT_APP_API_URL + image.attributes.uri.url
      } else {
        backgroundImageSrc = process.env.REACT_APP_API_URL + image
      }
    } else {
      if (currentConferenceEvent && currentConferenceEvent.image) {
        backgroundImageSrc = currentConferenceEvent.image.image
      }
      if (isConference) {
        backgroundColor = appointmentColors.conference
      } else if (isExternal) {
        backgroundColor = appointmentColors.external
      }
    }

    if (this.props.appointmentData.color) {
      backgroundColor = this.props.appointmentData.color
    }
    if (currentConferenceEvent && currentConferenceEvent.color) {
      backgroundColor = currentConferenceEvent.field_hex_color
    }

    let fullRoom
    if (this.props.activeConference && this.props.appointmentData.roomId) {
      const availableRooms = []

      this.props.activeConference.locations.forEach((location) => {
        availableRooms.push(...location.rooms)
      })

      fullRoom = availableRooms.find(
        (room) => room.id === this.props.appointmentData.roomId
      )
    }

    let startTime = moment(startDate).format("h:mma")
    let endTime = moment(endDate).format("h:mma")

    return (
      <>
        {!this.props.activeConference.isEditingEvent && (
          <div
            className={classNames("eventTooltip", isExternal && "isExternal")}
          >
            <div
              className={classNames(
                "eventImage",
                !backgroundImageSrc && "noImage"
              )}
              style={
                backgroundImageSrc
                  ? {
                      backgroundImage: "url(" + backgroundImageSrc + ")",
                    }
                  : { backgroundColor }
              }
            >
              {fullRoom && (
                <div className="room-info">
                  <span className="icon room" />
                  {he.decode(fullRoom.attributes.name)}
                </div>
              )}

              {isObservation && (
                <Box
                  sx={(theme) => ({
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: 35,
                    width: 35,
                    borderRadius: "100%",
                    position: "absolute",
                    right: 10,
                    top: 10,
                    zIndex: 4,
                    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.42)",
                    backgroundColor: theme.palette.primary.main,
                  })}
                >
                  <img src={ObservationIcon} alt="Observation" width={20} />
                </Box>
              )}

              <header>
                <div className="title">{he.decode(title)}</div>
                <div className="dateTime">
                  {isAllDay ? (
                    <>{moment().utc(startDate).local().format()}</>
                  ) : (
                    <>
                      {isSingleDayEvent ? (
                        <>
                          {moment(startDate).format("dddd, MMMM Do")}{" "}
                          {moment(startDate).format("h:mma") !==
                            moment(endDate).format("h:mma") && (
                            <>
                              {startTime} - {endTime}
                            </>
                          )}
                        </>
                      ) : (
                        <div
                          className="flexRow"
                          style={{
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <div style={{ marginBottom: 5 }}>
                            <span className="timeline" />
                            {moment(startDate).format("dddd, MMMM Do")}{" "}
                            {startTime} -{" "}
                            {moment(endDate).format("dddd, MMMM Do")} {endTime}
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>

                {category && category.image && category.image.attributes && (
                  <div className="capsuleContainer">
                    <div className="capsule">
                      <div
                        className="icon category-image"
                        style={{
                          backgroundImage:
                            "url(" +
                            process.env.REACT_APP_API_URL +
                            category.image.attributes.uri.url +
                            ")",
                        }}
                      />
                      {category.attributes.name}

                      {attendance?.remaining_seats &&
                        attendance?.max_participants && (
                          <>
                            <span className="icon chair" />
                            {attendance?.remaining_seats} /{" "}
                            {attendance?.max_participants} seats available
                          </>
                        )}
                    </div>
                  </div>
                )}
              </header>
            </div>

            <div className="inner">
              <Popover
                id="colors-popover"
                anchorEl={colorAnchorEl}
                open={colorAnchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                onClose={() => this.setState({ colorAnchorEl: null })}
                disableRestoreFocus
              >
                <div className="row">
                  {sessionColors.map((color) => {
                    return (
                      <Button
                        key={color}
                        onClick={() => {
                          this.setState({
                            colorAnchorEl: null,
                            selectedColor: color,
                          })
                          this.props.changeSessionColor(
                            this.props.appointmentData.id,
                            color
                          )
                        }}
                        className="color-button"
                      >
                        <div
                          className="color"
                          style={{ backgroundColor: color }}
                        />
                      </Button>
                    )
                  })}
                </div>
              </Popover>

              {isConference && (
                <div
                  style={{ backgroundColor }}
                  className="conferenceIndicator"
                />
              )}

              {eventRole && (
                <div className="banner role">
                  <span
                    className={classNames("icon", eventRole.toLowerCase())}
                  />
                  {eventLabel} {eventRole}
                </div>
              )}

              <header
                style={{ marginTop: isConference && !hasImage ? 40 : 0 }}
                className={classNames(field_parent_conference && "session")}
              >
                {field_parent_conference && (
                  <div className="conferenceLabel">
                    <span className="icon" />
                    {field_parent_conference}
                  </div>
                )}

                {!window.location.href.includes("sync/view/conference") ? (
                  <span
                    className={classNames(
                      "chip",
                      field_draft && "draft",
                      isConference && "conference"
                    )}
                    style={{
                      backgroundColor:
                        bundle === "conference_event"
                          ? this.props.appointmentData.color
                          : "inherit",
                    }}
                  />
                ) : (
                  <Button
                    style={{
                      minWidth: 0,
                      padding: 8,
                      marginTop: -7,
                      marginLeft: -5,
                      marginRight: 5,
                      borderRadius: 50,
                      height: 40,
                      flexShrink: 0,
                    }}
                    onClick={(event) =>
                      this.setState({ colorAnchorEl: event.currentTarget })
                    }
                  >
                    <div
                      style={{
                        backgroundColor: selectedColor
                          ? selectedColor
                          : this.props.appointmentData.color,
                        width: 25,
                        height: 25,
                        borderRadius: 25,
                      }}
                    />
                  </Button>
                )}
              </header>
              {this.props.appointmentData.color && (
                <Button
                  className="colorPicker"
                  style={{
                    minWidth: 0,
                    padding: 8,
                    marginTop: -7,
                    marginLeft: -5,
                    marginRight: 5,
                    borderRadius: 50,
                    height: 40,
                    flexShrink: 0,
                  }}
                  onClick={(event) =>
                    this.setState({ colorAnchorEl: event.currentTarget })
                  }
                >
                  <div
                    style={{
                      backgroundColor: selectedColor
                        ? selectedColor
                        : this.props.appointmentData.color,
                      width: 25,
                      height: 25,
                      borderRadius: 25,
                    }}
                  />
                </Button>
              )}

              {location && location.length > 0 && isArray(location) && (
                <div>
                  {location.map((loc) => {
                    if (!loc) {
                      return null
                    }
                    return (
                      <div className="flexRow location">
                        <span className="icon location color" />
                        <div>
                          <strong>{loc.attributes.name}</strong>
                          <span className="address">
                            {addressToString(loc.attributes.field_address)}
                          </span>
                        </div>
                      </div>
                    )
                  })}
                </div>
              )}
              {description && (
                <div className="description">
                  <SanitizedHTML
                    html={he.decode(
                      description.value ? description.value : description
                    )}
                  />
                </div>
              )}
              {observationUser?.field_first_name && (
                <div className="flexRow" style={{ marginBottom: 15 }}>
                  <div>
                    {observationUser.user_picture.includes("default_temp") ||
                    !observationUser.user_picture ? (
                      <UserInitial
                        containerStyle={{ marginRight: 10 }}
                        fontSize={12}
                        size={15}
                        initials={
                          observationUser.field_first_name
                            .substring(0, 1)
                            .toUpperCase() +
                          observationUser.field_last_name
                            .substring(0, 1)
                            .toUpperCase()
                        }
                      />
                    ) : (
                      <span
                        className="userImage"
                        style={{
                          height: 15,
                          width: 15,
                          border: "2px solid white",
                          marginLeft: 1,
                          backgroundImage:
                            "url(" +
                            process.env.REACT_APP_API_URL +
                            observationUser.user_picture +
                            ")",
                        }}
                      />
                    )}
                  </div>
                  <strong style={{ fontSize: 14, marginLeft: 2 }}>
                    {observationUser.field_first_name}{" "}
                    {observationUser.field_last_name}
                  </strong>
                </div>
              )}

              {isObservation && this.props.appointmentData.field_rubric && (
                <div className="flexRow">
                  <img
                    src={RubricIcon}
                    alt="Rubric"
                    width={20}
                    style={{ marginRight: 10, opacity: 0.5 }}
                  />
                  <div>
                    <strong style={{ display: "block", fontSize: 14 }}>
                      {this.props.appointmentData.field_rubric}
                    </strong>
                  </div>
                </div>
              )}

              {!this.props.appointmentData.isExternal && !isObservation && (
                <div id="attendance-summary-container">
                  {attendance ? (
                    <AttendanceSummary
                      attendance={attendance}
                      startTime={startDate}
                      showLabels={false}
                    />
                  ) : (
                    <Loading message="Getting attendance..." />
                  )}
                </div>
              )}
              {!this.props.appointmentData.isExternal && (
                <footer>
                  {isSession && canSchedule && (
                    <div style={{ flex: 1 }}>
                      <Button
                        onClick={() => {
                          this.copySession(this.props.appointmentData)
                        }}
                        className="button small"
                      >
                        <span className="icon copy blue"></span>
                        Copy
                      </Button>
                    </div>
                  )}

                  {(canSchedule || isConferenceManager) && (
                    <>
                      {isSession ? (
                        <Button
                          onClick={loadConferenceEvent}
                          className="button small"
                        >
                          <span className="icon edit"></span>
                          Edit
                        </Button>
                      ) : (
                        <Link
                          to={
                            "/events/" +
                            eventLabel.toLowerCase() +
                            "/" +
                            drupal_internal__id
                          }
                        >
                          <Button
                            startIcon={<img src={EditIcon} alt="Edit" />}
                            size="small"
                          >
                            Edit
                          </Button>
                        </Link>
                      )}
                    </>
                  )}
                  {(!field_draft || isConference) && (
                    <Link to={viewUrl}>
                      <Button className="button small">
                        <span className="icon view"></span>
                        View
                      </Button>
                    </Link>
                  )}
                </footer>
              )}

              {this.props.appointmentData.isExternal && (
                <footer>
                  <Button
                    onClick={() => {
                      this.props.toggleExternalEventModal(true)
                      this.props.openEvent(this.props.appointmentData.fullEvent)
                    }}
                    className="button small"
                  >
                    <span className="icon edit"></span>
                    Edit
                  </Button>
                </footer>
              )}
            </div>
          </div>
        )}
      </>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EventTooltip)
