import { Box, Button, FormControl, FormLabel, Typography } from "@mui/material"
import { UserSelectUpdateArgs } from "features/userSelect/types"
import { useFormikContext } from "formik"
import { has } from "lodash"
import { useState } from "react"
import plusImg from "../../../img/plus.svg"
import UserSelect from "../../userSelect/UserSelect"
import { CourseFormValues } from "../types"
import { AuthorListIndividuals } from "./AuthorListIndividuals"

export const CourseAuthorField = () => {
  const { errors, setFieldValue, submitCount, values } =
    useFormikContext<CourseFormValues>()
  const [isAuthorModalOpen, setAuthorModalOpen] = useState(false)

  return (
    <FormControl
      required={true}
      error={submitCount > 0 && has(errors, "author")}
      sx={{ alignItems: "flex-start" }}
    >
      <FormLabel required={false} sx={{ color: "unset", marginBottom: 2 }}>
        <Typography variant="h3" color="inherit" display="inline">
          Course Author
        </Typography>
      </FormLabel>
      <Box sx={{ flexWrap: "wrap", display: "flex" }}>
        <AuthorListIndividuals />
      </Box>
      <UserSelect
        open={isAuthorModalOpen}
        entity={values}
        update={(args: UserSelectUpdateArgs) => {
          const { value } = args

          setFieldValue("author", value)
        }}
        onClose={() => setAuthorModalOpen(false)}
        visibleTabs={"users"}
        initialTab={"users"}
        userLabel={"author"}
        searchText={"Manually set the author of this course."}
        maxSelectable={1}
      />
      <Button
        onClick={() => setAuthorModalOpen(true)}
        size="small"
        startIcon={<img alt={"Add Author"} src={plusImg} />}
        sx={{ marginTop: 4 }}
      >
        Set Author
      </Button>
    </FormControl>
  )
}
