import { SkillLevel, SubmissionType } from "@mobilemind/common/src/types/course"
import { SubGroup } from "@mobilemind/common/src/types/groups"
import {
  AnswerKeyCourseAnswers,
  CourseEntityCourseEntity,
  CourseTemplateCourseTemplate,
  QuizEntityCourseQuiz,
  TaxonomyTermTags,
} from "@mobilemind/common/src/types/jsonapi"
import { MobileMindUser } from "@mobilemind/common/src/types/session"
import { Categories } from "@mobilemind/common/src/types/taxonomy/category"
import { JobTitle } from "@mobilemind/common/src/types/taxonomy/jobTitle"
import { Entity } from "@mobilemind/common/src/types/utilities"
import { YouTubeVideo } from "@mobilemind/genie-api/src/types"
import { genieApi } from "features/genie/common/genieApi"
import getYouTubeID from "get-youtube-id"
import { isArray, isString, memoize } from "lodash"
import moment from "moment"
import { CourseFormValues, MultipleChoiceQuestionAnswer } from "./types"

export const getInitialCourseFormValues = (): CourseFormValues => {
  return {
    id: "",
    name: "",
    author: null,
    tags: [],
    archived: false,
    field_draft: false,
    field_category: null,
    estimatedTime: 0,
    objective: "",
    content: "",
    level: SkillLevel.Basic,
    resources: "",
    submissionType: SubmissionType.MultipleChoice,
    reviewMethod: "exact-match",
    exactMatch: "",
    includeOption: "any",
    phrases: ["", ""],
    question: "",
    field_video_link: "",
    learnerInstructions: "",
    reviewerInstructions: "",
    unauthorized: false,
    answerKeyId: null,
    hint: "",
    relatedCourses: [],
    checkboxOptions: [""],
    subGroups: [],
    jobTitles: [],
    quizPassThreshold: 75,
    quizId: null,
    showMissedQuestions: false,
    showCorrectAnswers: false,
    mobileFriendly: true,
    excludeFromExplore: false,
    shareSettings: ["credit"],
    creatorOrgName: "",
    isTemplate: false,
    supportEmail: "",
    subGroupOwner: null,
    reviewers: [],
    reviewerGroups: [],
    audioLink: "",
    mc_shuffle: false,
    answers: [
      {
        type: "paragraph--multiple_choice",
        attributes: {
          field_mc_answer: "",
          field_mc_answer_type: "correct",
        },
      },
      {
        type: "paragraph--multiple_choice",
        attributes: {
          field_mc_answer: "",
          field_mc_answer_type: "incorrect",
        },
      },
    ],
    quizQuestions: [
      {
        value: "",
        requireAllAnswers: false,
        answers: [
          {
            value: "",
            isCorrect: true,
          },
          {
            value: "",
            isCorrect: false,
          },
        ],
      },
      {
        value: "",
        requireAllAnswers: false,
        answers: [
          {
            value: "",
            isCorrect: true,
          },
          {
            value: "",
            isCorrect: false,
          },
        ],
      },
    ],
    shareWithCommunity: false,
    shareWithMM: false,
    releaseDate: moment().format(),
  }
}

export type RawCourseData = {
  id: string
  type: string
  attributes?: Partial<
    CourseEntityCourseEntity["data"]["attributes"] &
      CourseTemplateCourseTemplate["data"]["attributes"]
  >
  relationships?: Partial<
    CourseEntityCourseEntity["data"]["relationships"] &
      CourseTemplateCourseTemplate["data"]["relationships"]
  >
}

export type RawQuizData = {
  name: string
  pass_rate: string
  questions: Array<{
    answers: Array<{
      answer_id: string
      answer_uuid: string
      isCorrect: boolean
      value: string
    }>
    question: string
    question_uuid: string
    require_all: string
  }>
}

export const transformCourseToFormValues = (
  course: RawCourseData,
  options: {
    included?: Entity[]
    categories?: Categories
    answers?: AnswerKeyCourseAnswers["data"]
    quiz?: RawQuizData
  }
): CourseFormValues => {
  let state = getInitialCourseFormValues()

  state.subGroupOwner =
    course.relationships?.field_subgroup_owner?.data?.id ?? null

  state.field_category =
    options.categories?.data.find(
      (cat) => cat.id === course.relationships?.field_category?.data?.id
    ) ?? null

  state.id = course.id
  state.name = course.attributes?.name ?? ""

  // Handle tags
  state.tags =
    options.included
      ?.filter(
        (included): included is TaxonomyTermTags["data"] =>
          included.type === "taxonomy_term--tags"
      )
      .map((tag) => {
        return {
          id: tag.id,
          uuid: tag.id,
          text: tag.attributes?.name,
        }
      }) ?? []

  state.shareSettings = course.attributes?.field_mm_community ?? []
  if (
    course.attributes?.field_release_date &&
    isString(course.attributes.field_release_date)
  ) {
    state.releaseDate = course.attributes.field_release_date
  } else if (
    course.attributes?.field_release_date?.value &&
    isString(course.attributes?.field_release_date?.value)
  ) {
    state.releaseDate = course.attributes.field_release_date.value
  } else {
    state.releaseDate = moment().format()
  }

  state.excludeFromExplore = !!course.attributes?.field_ex_from_lib
  state.requireAllAnswers = !!course.attributes?.field_mc_require_all

  state.archived = !!course.attributes?.field_archive
  state.field_draft = !!course.attributes?.field_draft
  if (course.attributes?.field_course_estimated_time) {
    state.estimatedTime = course.attributes.field_course_estimated_time
  }
  if (course.attributes?.field_course_objective) {
    state.objective = course.attributes.field_course_objective
  }
  if (course.attributes?.field_course_content) {
    state.content = course.attributes.field_course_content
  }
  if (course.attributes?.field_level) {
    state.level = course.attributes?.field_level as SkillLevel
  }
  if (course.attributes?.field_course_hint) {
    state.hint = course.attributes.field_course_hint
  }
  if (course.attributes?.field_additional_resources?.value) {
    state.resources = course.attributes.field_additional_resources.value
  }
  if (course.attributes?.field_submission_type) {
    state.submissionType = course.attributes
      .field_submission_type as SubmissionType
  }
  if (course.attributes?.field_ques) {
    state.question = course.attributes.field_ques
  }
  if (course.attributes?.field_video_link) {
    state.field_video_link = course.attributes.field_video_link
  }
  if (course.attributes?.field_teacher_instructions) {
    state.learnerInstructions = course.attributes.field_teacher_instructions
  }

  state.field_created_with_genie = !!course.attributes?.field_created_with_genie

  // Reviewers
  if (course.relationships?.field_reviewers?.data) {
    state.reviewers =
      options.included
        ?.filter(
          (included): included is MobileMindUser =>
            included.type === "user--user"
        )
        .filter((user) => {
          return course.relationships?.field_reviewers?.data?.some(
            (reviewer) => reviewer.id === user.id
          )
        }) ?? []
  }

  state.reviewerGroups = course.relationships?.field_reviewer_group?.data ?? []

  const courseAuthor = options.included
    ?.filter(
      (included): included is MobileMindUser => included.type === "user--user"
    )
    .find(
      (user: MobileMindUser) =>
        user.id === course.relationships?.user_id?.data?.id
    )

  if (courseAuthor) {
    state.author = {
      field_first_name: courseAuthor?.attributes.field_first_name,
      field_last_name: courseAuthor?.attributes.field_last_name,
      field_subgroup: courseAuthor?.attributes.field_subgroup,
      user_picture: "",
      id: courseAuthor?.id,
    }
  }

  if (course.attributes?.field_mm_community) {
    state.shareSettings = course.attributes.field_mm_community
  }
  state.displayCredit = course.attributes?.field_credit_organization

  state.isFromTemplate = !!course.relationships?.field_source_template?.data
  state.isTemplate = course.type === "course_template--course_template"

  state.answers = options.included
    ?.filter(
      (included): included is Required<MultipleChoiceQuestionAnswer> =>
        included.type === "paragraph--multiple_choice"
    )
    .map((answer) => {
      if (answer.attributes?.field_mc_answer === "null") {
        answer.attributes.field_mc_answer = ""
      }
      return answer
    }) ?? [
    {
      type: "paragraph--multiple_choice",
      attributes: {
        field_mc_answer: "",
        field_mc_answer_type: "correct",
      },
    },
    {
      type: "paragraph--multiple_choice",
      attributes: {
        field_mc_answer: "",
        field_mc_answer_type: "incorrect",
      },
    },
  ]

  state.mc_shuffle = !!course.attributes?.field_mc_shuffle
  state.reviewerInstructions = course.attributes?.field_reviewer ?? ""

  state.relatedCourses =
    options.included
      ?.filter(
        (included): included is CourseEntityCourseEntity["data"] =>
          included.type === "course_entity--course_entity"
      )
      .map((course) => {
        return {
          name: course.attributes?.name ?? "",
          uuid: course.id,
        }
      }) ?? []

  state.jobTitles =
    options.included?.filter(
      (included): included is JobTitle =>
        included.type === "taxonomy_term--job_titles"
    ) ?? []

  state.subGroups =
    options.included?.filter(
      (included): included is SubGroup => included.type === "group--group"
    ) ?? []

  state.checkboxOptions = course.attributes?.field_checkbox_options ?? [""]

  if (
    course.attributes?.field_support_email &&
    isArray(course.attributes.field_support_email) &&
    course.attributes.field_support_email.length > 0
  ) {
    state.supportEmail = course.attributes.field_support_email[0]
  } else {
    state.supportEmail = ""
  }

  state.creatorOrg = course.relationships?.field_creator_org?.data

  state.mobileFriendly = !!course.attributes?.field_mobile_friendly

  state.sourceTemplate = options.included?.find(
    (included): included is CourseTemplateCourseTemplate["data"] =>
      included.type === "course_template--course_template"
  )

  if (course.attributes?.field_creator_org_name) {
    state.creatorOrgName = course.attributes.field_creator_org_name
  }

  if (options.answers) {
    state.answerKeyId = options.answers.id
    if (options.answers.attributes?.field_answer) {
      state.exactMatch = options.answers.attributes.field_answer
      state.phrases = options.answers.attributes.field_answer.split(",")
    }

    let operator = options.answers.attributes?.field_operator
    state.includeOption = operator === "and" ? "all" : "any"

    if (course.attributes?.field_automatic_review) {
      if (options.answers.attributes?.field_exact) {
        state.reviewMethod = "exact-match"
      } else {
        state.reviewMethod = "include"
      }
    } else {
      state.reviewMethod = "manual"
    }
  } else {
    state.answerKeyId = null
    state.reviewMethod = "manual"
  }

  const quizEntity = options.included?.find(
    (included): included is QuizEntityCourseQuiz["data"] =>
      included.type === "quiz_entity--course_quiz"
  )

  if (quizEntity) {
    state.quizId = quizEntity.id

    state.quizPassThreshold =
      Number(quizEntity.attributes?.field_pass_rate) * 100

    state.showMissedQuestions =
      !!quizEntity.attributes?.field_show_missed_questions

    state.showCorrectAnswers =
      !!quizEntity.attributes?.field_show_correct_answers

    if (options.quiz?.questions) {
      state.quizQuestions = options.quiz.questions.map((question) => {
        let questionAnswers
        if (question.answers) {
          questionAnswers = question.answers.map((answer) => {
            return {
              id: answer.answer_uuid,
              value: answer.value ? answer.value.replaceAll("***", '"') : "",
              isCorrect: answer.isCorrect,
            }
          })
        } else {
          questionAnswers = [
            {
              value: "",
              isCorrect: true,
            },
            {
              value: "",
              isCorrect: false,
            },
          ]
        }

        return {
          id: question.question_uuid,
          requireAllAnswers: question.require_all === "1" ? true : false,
          value:
            question.question === "missing"
              ? ""
              : question.question.replaceAll("***", '"'),
          answers: questionAnswers,
        }
      })
    }
  } else {
    state.quizId = null
    state.quizPassThreshold = 75
    state.showMissedQuestions = false
    state.showCorrectAnswers = false

    state.quizQuestions = [
      {
        value: "",
        requireAllAnswers: false,
        answers: [
          {
            value: "",
            isCorrect: true,
          },
          {
            value: "",
            isCorrect: false,
          },
        ],
      },
      {
        value: "",
        requireAllAnswers: false,
        answers: [
          {
            value: "",
            isCorrect: true,
          },
          {
            value: "",
            isCorrect: false,
          },
        ],
      },
    ]
  }
  return state
}

// `memoize` should ensure that the YouTubeVideo is fetched from the API only
// once per session. This is a lazy state management implementation, but
// redux feels like overkill here.
export const getYouTubeVideo = memoize(
  async (videoUrl: string): Promise<YouTubeVideo | null> => {
    const videoId = getYouTubeID(videoUrl)
    if (videoId) {
      try {
        const video = await genieApi
          .get(`/video/${videoId}`)
          .then<YouTubeVideo>((response) => response.data)
        return video
      } catch (err) {
        console.error(err)
      }
    }
    return null
  }
)
