import { FieldArray, useFormikContext } from "formik"
import { Stack, Typography, Box } from "@mui/material"
import UserInitial from "@mobilemind/common/src/components/UserInitial"
import DeleteButton from "components/DeleteButton"
import { CourseFormValues } from "../types"

type ListProps = {
  viewOnly?: boolean
}

export const ReviewersListIndividuals = ({ viewOnly }: ListProps) => {
  const { values } = useFormikContext<CourseFormValues>()

  return (
    <FieldArray
      name="reviewers"
      render={(arrayHelpers) => (
        <>
          {values.reviewers.map((user: any, index: number) => {
            let firstName,
              lastName,
              initials = ""
            if (user.attributes && !user.attributes.field_first_name) {
              firstName = user.attributes.display_name
              initials = firstName.toUpperCase().charAt(0)
            } else {
              firstName = user.attributes
                ? user.attributes.field_first_name
                : user.field_first_name
              lastName = user.attributes
                ? user.attributes.field_last_name
                : user.field_last_name
              if (firstName && lastName) {
                initials =
                  firstName.toUpperCase().charAt(0) +
                  lastName.toUpperCase().charAt(0)
              }
            }

            const isBlocked = user.attributes && !user.attributes.status

            return (
              <Stack
                key={index}
                direction="row"
                sx={{
                  alignItems: "center",
                  marginBottom: "15px!important",
                  marginLeft: "0!important",
                  borderWidth: 3,
                  borderStyle: "solid",
                  borderColor: isBlocked ? "rgb(235, 82, 82)" : "transparent",
                  padding: isBlocked ? 1 : 2,
                  borderRadius: 6,
                  height: 25,
                  paddingBottom: "7px",
                  position: "relative",
                  top: isBlocked ? 5 : 0,
                }}
                spacing={2}
              >
                <UserInitial fontSize={12} size={30} initials={initials} />
                <Box>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: 12,
                    }}
                  >
                    {firstName} {lastName}
                  </Typography>
                  {isBlocked && (
                    <Typography
                      sx={{
                        marginTop: -0.4,
                        textTransform: "uppercase",
                        fontWeight: "bold",
                        opacity: 0.6,
                        fontSize: 8,
                      }}
                    >
                      Blocked User
                    </Typography>
                  )}
                </Box>
                {!viewOnly && (
                  <DeleteButton onClick={() => arrayHelpers.remove(index)} />
                )}
              </Stack>
            )
          })}
        </>
      )}
    />
  )
}
