import { useEffect, useState } from "react"
import {
  Dialog,
  TextField,
  DialogActions,
  Stack,
  Select,
  FormControl,
  MenuItem,
  Button,
  Box,
  InputLabel,
  FormHelperText,
} from "@mui/material"

import CopyCalendarIcon from "../../../../img/copy-calendar.svg"
import { useAppSelector, useAppDispatch } from "store/hooks"
import { updateSessionImportSearch } from "../activeConferenceSlice"
import moment, { Moment } from "moment"
import { SessionImportResult } from "./SessionImportResult"
import Loading from "@mobilemind/common/src/components/Loading"
import { TimePicker } from "@mui/x-date-pickers"
import {
  fetchSessionsForConference,
  updateConferenceField,
} from "../activeConferenceSlice"

import {
  createImportedSessionBase,
  createAttachImportedSessionAddOn,
} from "../../../../actions/sessionImport"

import { RootState } from "store/types"

type ModalProps = {
  open: boolean
  onClose: () => void
  currentDate: string
  selectedLocation: string
}

type SessionResult = {
  created: string
  field_description: string
  field_event_image: string
  field_hex_color: string
  field_parent_conference: string
  field_event_date_time_value: string
  name: string
  id: string
  uuid: string
}

type MobileMindRoom = {
  id: string
  attributes: {
    name: string
  }
  relationships: {
    field_location: {
      data: {
        id: string
      }
    }
  }
}

export function SessionImportModal({
  open,
  onClose,
  currentDate,
  selectedLocation,
}: ModalProps) {
  const [search, setSearch] = useState<string>("")
  const [selected, setSelected] = useState<string | null>(null)

  const activeConference = useAppSelector(
    (state: RootState) => state.activeConference
  )
  const dispatch = useAppDispatch()

  const [targetDateTime, setTargetDateTime] = useState<Moment>(
    moment(currentDate).startOf("day").add(8, "hours")
  )

  let availableRooms: MobileMindRoom[] = []

  activeConference.locations?.forEach((loc) => {
    // @ts-ignore
    availableRooms = availableRooms.concat(loc.rooms ?? [])
  })

  if (selectedLocation && activeConference.locations?.length > 1) {
    availableRooms = activeConference.availableRooms.filter(
      (room: MobileMindRoom) =>
        room.relationships.field_location.data.id === selectedLocation
    )
  } else {
    availableRooms = activeConference.availableRooms
  }

  const firstRoom = availableRooms.length > 0 && availableRooms[0]
  // @ts-ignore
  const [targetRoomUUID, setTargetRoomUUID] = useState<string>(
    firstRoom ? firstRoom.id : ""
  )

  useEffect(() => {
    if (search) {
      setSelected(null)
      // @ts-ignore
      dispatch(updateSessionImportSearch(search))
    }
  }, [search, dispatch])

  function clearModal() {
    onClose()
    setTimeout(() => {
      setSearch("")
      setTargetRoomUUID("")
      setSelected("")
    }, 700)
  }

  async function copySession() {
    // Close the modal and get the full session data from the view
    clearModal()
    const sessionToImport: SessionResult | undefined =
      activeConference.sessionImportSearchResults.data.find(
        (event: SessionResult) => event.uuid === selected
      )

    dispatch(
      updateConferenceField({
        field: "isCopyingEvent",
        value: true,
      })
    )

    if (sessionToImport) {
      // Make sure we have the correct day AND the correct time
      const day = moment(currentDate).format().split("T")[0]
      const time = moment(targetDateTime).format().split("T")[1]

      const startTime = moment(day + "T" + time).format()

      const baseResponse = await dispatch(
        createImportedSessionBase({
          startTime,
          sessionToImport,
          targetRoomUUID,
          selectedLocation,
          targetDateTime,
        })
      )

      // After posting the new session tied to this conference,
      // POST and attach a new addon entity for it
      if (baseResponse) {
        const { importedSession, originalAddOn } = baseResponse
        await dispatch(
          createAttachImportedSessionAddOn({
            startTime: moment(day + "T" + time).format(),
            importedSession,
            originalAddOn,
            conferenceAddOn: activeConference.addOn,
          })
        )
        // Re-fetch sessions for this conference
        await dispatch(
          // @ts-ignore
          fetchSessionsForConference({
            id: activeConference.drupal_internal__id,
          })
        )

        dispatch(
          updateConferenceField({
            field: "isCopyingEvent",
            value: false,
          })
        )
      }
    }
  }

  return (
    <Dialog
      sx={{
        "& .MuiPaper-root": {
          minWidth: 768,
          padding: 4,
          minHeight: 300,
        },
      }}
      open={open}
      onClose={clearModal}
    >
      <Box sx={{ marginBottom: 1, display: "flex", alignItems: "center" }}>
        <img
          src={CopyCalendarIcon}
          style={{ marginRight: 10 }}
          width={35}
          height={35}
          alt="Import"
        />

        <Box sx={{ flex: 1, marginTop: -3 }}>
          <h2 style={{ marginBottom: -5 }}>Import Session</h2>
          <FormHelperText>
            Start searching to find sessions from all of your previous
            conferences.
          </FormHelperText>
        </Box>
      </Box>

      <Box sx={{ marginTop: 2 }}>
        <TextField
          value={search}
          onChange={(event) => {
            setSearch(event.target.value)
          }}
          placeholder={"Search for Sessions"}
          fullWidth
          variant={"outlined"}
        />
      </Box>

      <Box style={{ paddingBottom: selected ? 120 : 0 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            padding: 2,
          }}
        >
          {search && !activeConference.sessionImportSearchResults.fetched && (
            <Box sx={{ height: 75, marginTop: 2 }}>
              <Loading message={"Getting available sessions..."} />
            </Box>
          )}
        </Box>

        {activeConference.sessionImportSearchResults.fetched && (
          <ul
            style={{
              maxHeight: 460,
              padding: 5,
              marginRight: -40,
              paddingRight: 30,
              marginTop: 0,
              marginBottom: 0,
              overflow: "auto",
            }}
          >
            {activeConference.sessionImportSearchResults.data.map(
              (event: SessionResult) => {
                const isSelected = selected === event.uuid
                return (
                  <SessionImportResult
                    isSelected={isSelected}
                    event={event}
                    onClick={() => {
                      if (!isSelected) {
                        setSelected(event.uuid)
                      } else {
                        setSelected(null)
                      }
                    }}
                  />
                )
              }
            )}
          </ul>
        )}
      </Box>

      {selected && (
        <Box
          sx={{
            marginTop: 5,
            position: "absolute",
            zIndex: 5,
            backgroundColor: "white",
            bottom: 0,
            right: 0,
            left: 0,
            padding: 4,
          }}
        >
          <Stack direction={"row"} spacing={2}>
            {availableRooms.length > 0 && (
              <FormControl>
                <InputLabel htmlFor="target-room">Desired Room</InputLabel>

                <Select
                  id="target-room"
                  label={"Desired Room"}
                  value={targetRoomUUID ?? availableRooms[0].id}
                  onChange={(event) => {
                    setTargetRoomUUID(event.target.value)
                  }}
                >
                  {availableRooms.map((room: MobileMindRoom) => {
                    return (
                      <MenuItem value={room.id}>
                        {room.attributes.name}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            )}

            <TimePicker
              value={targetDateTime}
              onChange={(newValue: any) => {
                setTargetDateTime(newValue)
              }}
              sx={{ maxWidth: 250, marginBottom: 5, marginTop: 3 }}
            />
          </Stack>

          <DialogActions sx={{ paddingBottom: 0 }}>
            <Button variant="contained" onClick={copySession}>
              Import Session
            </Button>
          </DialogActions>
        </Box>
      )}
    </Dialog>
  )
}
