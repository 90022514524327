import fetchWrapper from "@mobilemind/common/src/functions/fetchWrapper"
import { RelationshipSingle } from "@mobilemind/common/src/types"
import { SubGroup } from "@mobilemind/common/src/types/groups"
import { GroupGroup } from "@mobilemind/common/src/types/jsonapi"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import _ from "lodash"
import qs from "qs"
import type { RootState } from "../types"
import { setMaintenanceMode } from "@mobilemind/common/src/actions/session"

export const unmasqueradeUser = createAsyncThunk(
  "session/unmasqueradeUser",
  async () => {
    await fetchWrapper.get("/api/unmasquerade")
    window.location.reload()
  }
)

export const getOrganizations = createAsyncThunk(
  "session/getOrganizations",
  async () => {
    let pages = 1
    let i = 0
    let content: any[] = []

    while (i < pages) {
      let query = {
        page: {
          offset: i * 50,
        },
      }

      let response = await fetchWrapper.get(
        "/api/group/organization?" + qs.stringify(query)
      )
      if (response.ok) {
        let data = await response.json()
        pages = Math.ceil(Number(data.meta.count) / 50)
        data.data.forEach((org: any) => content.push(org))
      }
      i++
    }

    let sortedByName = _.sortBy(content, (organization: any) => {
      return organization.attributes.label
    })

    return sortedByName
  }
)

export const getGenieCreatedCourses = createAsyncThunk<
  any,
  void,
  { state: RootState }
>("session/getGenieCreatedCourses", async (args, thunkAPI) => {
  const { session } = thunkAPI.getState()
  const orgId = session.group && session.group.uuid[0].value

  if (orgId) {
    const query = {
      filter: {
        field_created_with_genie: 1,
        "field_organization.id": orgId,
      },
      page: {
        limit: 3,
      },
    }

    const response = await fetchWrapper.get(
      "/api/course_entity/course_entity?" + qs.stringify(query)
    )

    if (response.ok) {
      let data = await response.json()
      return data.data
    }
  }
})

export const getOrgSuccessChecklist = createAsyncThunk<
  any,
  void,
  { state: RootState }
>("session/getOrgSuccessChecklist", async (args, thunkAPI) => {
  const { session } = thunkAPI.getState()

  let response = await fetchWrapper.get(
    "/api/mm_success_check/mm_success_checklist?filter[field_organization.id][value]=" +
      session.group.uuid[0].value
  )

  if (response.ok) {
    let data = await response.json()
    return data.data[0] ? data.data[0] : null
  }
})

export const getJobTitles = createAsyncThunk(
  "session/getJobTitles",
  async () => {
    let response = await fetchWrapper.get("/api/taxonomy_term/job_titles")
    let data = await response.json()

    return data
  }
)

export const updateQuickActions = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("session/updateQuickActions", async (args, thunkAPI) => {
  let { updatedQuickActions } = args
  const { session } = thunkAPI.getState()

  let newProfile = {
    data: {
      id: session.user.id,
      type: "user--user",
      relationships: {
        field_quick_actions: {
          data: updatedQuickActions.map((action: any) => {
            return {
              type: "taxonomy_term--quick_links",
              id: action.id,
            }
          }),
        },
      },
    },
  }

  let body = JSON.stringify(newProfile)
  let response = await fetchWrapper.patch(
    "/api/user/user/" + session.user.id,
    session.token,
    body
  )
  if (response.ok) {
    return updatedQuickActions
  }
})

export const getSubGroups = createAsyncThunk<
  SubGroup[],
  void,
  { state: RootState }
>("session/getSubGroups", async (args, { getState }) => {
  const org = getState().session.group
  let query: any = {
    filter: {
      "gid.id": org && org.uuid && org.uuid[0].value,
    },
    include: "entity_id",
  }

  let pages = 1
  let i = 0
  let subGroups: SubGroup[] = []

  while (i < pages) {
    query.page = {
      offset: i * 50,
    }

    let response = await fetchWrapper.get(
      "/api/group_content/organization-subgroup-group?" + qs.stringify(query)
    )
    let data = await response.json()

    pages = Math.ceil(data.meta.count / 50)

    // Use the included group data instead of the subgroup content
    data.included.forEach((group: GroupGroup["data"]) => {
      subGroups.push({
        ...group,
        entity_id: group.attributes?.drupal_internal__id,
        // @ts-ignore
        field_partner: group.relationships.field_partner,
      })
    })
    i++
  }

  // Sort groups alphabetically
  let sortedGroups = _.orderBy(subGroups, [
    (group) => group.attributes?.label?.toUpperCase(),
  ])

  return sortedGroups
})

type InitialState = {
  collection: Object
  memberships: any[]
  activeMembership: RelationshipSingle | null
  excludedCategories: any[]
  group: any
  groupRoles: string[]
  isSiteAdmin: boolean
  isSiteReviewer: boolean
  canCreate: boolean
  canSchedule: boolean
  canReport: boolean
  canCreateGoals: boolean
  canReview: boolean
  reviewerTypes: string[]
  isEventPersonnel: boolean
  canViewBackpack: boolean
  canViewApp: boolean
  isOrgAdmin: boolean
  isServiceAdmin: boolean
  isGroupAdmin: boolean
  isPartner: boolean
  isCollectionAdmin: boolean
  serviceAccountId: string[]
  subscriptionEnd: string | null
  genieWishesFetched: boolean
  genieWishesRemaining: number
  successChecklist: {
    fetched: boolean
    isComplete: boolean
    field_badge: boolean
    field_course: boolean
    field_event: boolean
    field_group: boolean
    field_learning_path: boolean
    field_user: boolean
  }
  customerSupportEmail: string | null
  jobTitles: {
    fetched: boolean
    data: any[]
  }
  orgRoles: string[]
  orgUsers: {
    fetched: boolean
    data: any[]
  }
  roles: any[]
  subGroups: {
    fetching: boolean
    fetched: boolean
    data: any[]
  }
  subgroup: any
  subgroups: {
    data: SubGroup[]
  }
  token: string
  user: any
  userBadges: any[]
  userId: number | null
  userLearningPaths: any[]
  userPicture: any
  users: {
    fetched: boolean
    data: any[]
  }
  userCollection: any
  organizations: {
    fetched: boolean
    data: any[]
  }
  partnershipUUIDs: string[]
  isMasquerading: boolean
  maintenanceMode: boolean
}

const initialState: InitialState = {
  collection: {},
  memberships: [],
  activeMembership: null,
  excludedCategories: [],
  group: null,
  groupRoles: [],
  isSiteAdmin: false,
  isSiteReviewer: false,
  canCreate: false,
  canSchedule: false,
  canReport: false,
  canCreateGoals: false,
  canReview: false,
  reviewerTypes: [],
  isEventPersonnel: false,
  canViewBackpack: false,
  canViewApp: true,
  isOrgAdmin: false,
  isServiceAdmin: false,
  isGroupAdmin: false,
  isPartner: false,
  isCollectionAdmin: false,
  serviceAccountId: [],
  subscriptionEnd: null,
  genieWishesFetched: false,
  genieWishesRemaining: 0,
  successChecklist: {
    fetched: false,
    isComplete: false,
    field_badge: false,
    field_course: false,
    field_event: false,
    field_group: false,
    field_learning_path: false,
    field_user: false,
  },
  customerSupportEmail: null,
  jobTitles: {
    fetched: false,
    data: [],
  },
  orgRoles: [],
  orgUsers: {
    fetched: false,
    data: [],
  },
  roles: [],
  subGroups: {
    fetching: false,
    fetched: false,
    data: [],
  },
  subgroup: {},
  subgroups: {
    data: [],
  },
  token: "",
  user: {},
  userBadges: [],
  userId: null,
  userLearningPaths: [],
  userPicture: {},
  users: {
    fetched: false,
    data: [],
  },
  userCollection: null,
  organizations: {
    fetched: false,
    data: [],
  },
  partnershipUUIDs: [],
  isMasquerading: false,
  maintenanceMode: false,
}

export const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    getUserId: (state, action) => {
      state.userCollection = action.payload.userCollection
      state.group = action.payload.group
      state.userId = action.payload.userId
      state.roles = action.payload.roles
      state.subgroup = action.payload.subgroup && action.payload.subgroup[0]
      state.collection = action.payload.collection
      state.memberships = action.payload.memberships
      state.subscriptionEnd = action.payload.subscriptionEnd
      state.customerSupportEmail = action.payload.customerSupportEmail
      state.isMasquerading = action.payload.isMasquerading

      let constructedSubGroups =
        action.payload.subgroups &&
        action.payload.subgroups.map((group: any) => {
          return {
            id: group[0].uuid[0].value,
            drupal_internal__id: group[0].id[0].value,
            attributes: {
              label: group[0].label[0].value,
            },
            relationships: {
              entity_id: {
                data: {
                  id: group[0].uuid[0].value,
                },
              },
            },
          }
        })

      state.subgroups = action.payload.subgroups && {
        data: constructedSubGroups,
      }

      const isSiteAdmin = action.payload.roles.find(
        (role: any) => role.target_id === "administrator"
      )
      state.isSiteAdmin = isSiteAdmin

      const isSiteReviewer =
        action.payload.roles.find(
          (role: any) => role.target_id === "reviewer"
        ) ||
        action.payload.roles.find(
          (role: any) => role.target_id === "tier_1_grader"
        )
      state.isSiteReviewer = isSiteReviewer

      const orgRoles = Object.keys(action.payload.orgRoles)
      const groupRoles = action.payload.subgroup
        ? action.payload.subgroup[1] && Object.keys(action.payload.subgroup[1])
        : []

      state.orgRoles = orgRoles
      state.groupRoles = groupRoles

      const allRoles = groupRoles.concat(orgRoles).join(",")
      const hasCollection =
        action.payload.userCollection && action.payload.userCollection[0]
      let isCollectionAdmin = hasCollection ? true : false

      if (hasCollection && !action.payload.memberships.length) {
        isCollectionAdmin = true
      }

      state.isCollectionAdmin = isCollectionAdmin

      if (
        isCollectionAdmin ||
        isSiteAdmin ||
        isSiteReviewer ||
        allRoles.includes("admin") ||
        allRoles.includes("scheduler") ||
        allRoles.includes("reporter") ||
        allRoles.includes("reviewer") ||
        allRoles.includes("creator") ||
        allRoles.includes("drafter") ||
        allRoles.includes("event_personnel") ||
        allRoles.includes("organization-group_manager") ||
        allRoles.includes("organization-obervation_admin") ||
        allRoles.includes("organization-observer") ||
        allRoles.includes("group-observation_admin") ||
        allRoles.includes("group-observer") ||
        allRoles.includes("group-user_manager")
      ) {
        state.canViewApp = true
      } else {
        state.canViewApp = false
      }

      state.isGroupAdmin = groupRoles.includes("group-admin")
      state.isPartner =
        action.payload.group &&
        action.payload.group.type[0].target_id === "partner"
      state.serviceAccountId =
        action?.payload?.group?.field_service_account?.[0]?.target_uuid

      const isOrgAdmin =
        orgRoles.includes("organization-admin") || state.isPartner

      const isServiceAdmin = orgRoles.includes("organization-service_admin")

      state.isOrgAdmin = isOrgAdmin
      state.isServiceAdmin = isServiceAdmin
      state.canCreate =
        isSiteAdmin ||
        isOrgAdmin ||
        allRoles.includes("group-admin") ||
        allRoles.includes("creator") ||
        allRoles.includes("drafter")
      state.canSchedule =
        allRoles.includes("admin") || allRoles.includes("scheduler")

      state.canReport =
        isSiteAdmin ||
        allRoles.includes("admin") ||
        allRoles.includes("reporter")
      state.canCreateGoals =
        isSiteAdmin ||
        allRoles.includes("admin") ||
        allRoles.includes("creator")
      state.canReview =
        isSiteAdmin ||
        isSiteReviewer ||
        orgRoles.includes("organization-admin") ||
        groupRoles.includes("group-admin") ||
        allRoles.includes("reviewer")
      state.isEventPersonnel =
        !state.canSchedule && orgRoles.includes("organization-event_personnel")
      state.canViewBackpack =
        allRoles.includes("admin") ||
        allRoles.includes("observer") ||
        state.canReport
    },
    addPartnership: (state, action) => {
      state.partnershipUUIDs = action.payload
    },
    removePartnership: (state, action) => {
      state.partnershipUUIDs = state.partnershipUUIDs.filter(
        (uuid) => uuid !== action.payload
      )
    },
    userIsLoggedIn: (state, action: any) => {
      state.user = action.payload
      state.reviewerTypes = action.payload.attributes.field_reviewer_type

      let userPicture =
        action.meta.included &&
        action.meta.included.filter(
          (included: any) => included.type === "file--image"
        )
      state.userPicture = userPicture && userPicture[0]

      // Set active membership
      state.activeMembership = state.user.relationships.field_active_membership

      // List of UUIDs of all added partners
      let field_partner

      // If they're a collection admin who hasn't "jumped to an org"
      if (state.isCollectionAdmin && !state.activeMembership?.data) {
        field_partner = state.userCollection[0].field_partner ?? []
        // Otherwise just always look at the group (current org)
      } else {
        field_partner = state.group.field_partner ?? []
      }
      // If we've found a target field_partner, create an array of UUIDs
      state.partnershipUUIDs = field_partner
        ? field_partner.map((partner: any) => partner.target_uuid)
        : []
    },
    getToken: (state, action) => {
      state.token = action.payload
    },
    updateUserPicture: (state, action) => {
      state.userPicture = action.payload
    },
    setProductTourStarted: (state, action) => {
      state.user.attributes.field_hq_product_tour_started = action.payload
    },
    setHQAnnouncementsFetched: (state, action) => {
      state.user.attributes.field_hq_announcements_last_fetc = action.payload
    },
    updateUser: (state, action) => {
      state.user = action.payload
    },
    addRemoveQuickAction: (state, action) => {
      state.user.quickActions = action.payload.updatedQuickActions
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSubGroups.pending, (state) => {
      state.subGroups.fetching = true
    })

    builder.addCase(getSubGroups.fulfilled, (state, action) => {
      state.subGroups.fetched = true
      state.subGroups.fetching = false
      state.subGroups.data = action.payload ? action.payload : []
    })

    builder.addCase(getJobTitles.fulfilled, (state, action) => {
      state.jobTitles.fetched = true

      let fetchedTitles = action.payload.data.filter(
        (title: any) =>
          title.attributes.name !== "School" &&
          title.attributes.name !== "District"
      )
      let jobTitles = fetchedTitles.filter(
        (title: any) =>
          !title.attributes.name.includes("School") &&
          !title.attributes.name.includes("District")
      )
      let schoolTitles = fetchedTitles.filter((title: any) =>
        title.attributes.name.includes("School")
      )
      let districtTitles = fetchedTitles.filter((title: any) =>
        title.attributes.name.includes("District")
      )

      state.jobTitles.data = jobTitles.concat(schoolTitles, districtTitles)
    })

    builder.addCase(getOrganizations.fulfilled, (state, action) => {
      state.organizations.data = action.payload
      state.organizations.fetched = true
    })
    builder.addCase(getGenieCreatedCourses.pending, (state, action) => {
      state.genieWishesFetched = false
    })
    builder.addCase(getGenieCreatedCourses.fulfilled, (state, action) => {
      state.genieWishesFetched = true
      state.genieWishesRemaining = 3 - action.payload.length
    })

    builder.addCase(getOrgSuccessChecklist.fulfilled, (state, action) => {
      state.successChecklist.fetched = true

      if (action.payload) {
        const {
          field_badge,
          field_course,
          field_event,
          field_group,
          field_learning_path,
          field_user,
        } = action.payload.attributes

        state.successChecklist = {
          fetched: true,
          isComplete:
            field_badge &&
            field_course &&
            field_event &&
            field_group &&
            field_learning_path &&
            field_user,
          field_badge,
          field_course,
          field_event,
          field_group,
          field_learning_path,
          field_user,
        }
      } else {
        state.successChecklist.isComplete = true
      }
    })
    builder.addCase(setMaintenanceMode, (state, action) => {
      state.maintenanceMode = action.payload
    })
  },
})

export const { addRemoveQuickAction } = sessionSlice.actions

export default sessionSlice.reducer
