import { useAppDispatch, useAppSelector } from "store/hooks"
import classNames from "classnames"
import SidebarAnalytics from "./SidebarAnalytics"

import { Tab, Typography, Box, Fade, LinearProgress } from "@mui/material"
import { setCurrentTab } from "store/reducers/analytics"
import { TabsCapsule } from "@mobilemind/common/src/components/tabsCapsule/TabsCapsule"

import { OrgRubric } from "@mobilemind/common/src/types/rubrics"
import { YearChart } from "./YearChart"
import RubricIcon from "../../img/checklist-color.svg"
import Loading from "@mobilemind/common/src/components/Loading"
import { MonthChart } from "./MonthChart"
import LearningBuddyMessage from "@mobilemind/common/src/components/LearningBuddyMessage"
import UserLabel from "@mobilemind/common/src/components/UserLabel"
import { useEffect, useState } from "react"
import { getOrgRubrics } from "actions/rubrics"
import he from "he"
import {
  OrgRubricCriteria,
  OrgRubricDomain,
} from "@mobilemind/common/src/types/rubrics"

export function AnalyticsLayout() {
  const analytics = useAppSelector((state) => state.analytics)
  const orgRubrics = useAppSelector((state) => state.entityList.orgRubrics)
  const { rubricId, user, criteriaId } = analytics.filters
  const { singleRubricResult } = analytics

  const dispatch = useAppDispatch()

  let startingWidth = 600

  if (window.innerWidth < 1500 && window.innerWidth > 1200) {
    startingWidth = 900
  }
  if (window.innerWidth >= 1500) {
    startingWidth = 1100
  }

  const [width, setWidth] = useState(startingWidth)

  useEffect(() => {
    if (!orgRubrics.fetched) {
      dispatch(getOrgRubrics())
    }

    window.addEventListener("resize", () => {
      if (window.innerWidth < 1200 && width !== 600) {
        setWidth(600)
      } else if (
        window.innerWidth < 1500 &&
        window.innerWidth > 1200 &&
        width !== 900
      ) {
        setWidth(900)
      } else if (window.innerWidth >= 1500 && width !== 1100) {
        setWidth(1100)
      }
    })
  }, [width, orgRubrics.fetched, dispatch])

  const activeRubric: OrgRubric | undefined = orgRubrics.data.find(
    (rubric: OrgRubric) => Number(rubric?.id) === Number(rubricId)
  )

  let criteriaName: string = ""
  let criteriaDescription: string = ""
  if (criteriaId && singleRubricResult) {
    singleRubricResult.domain.forEach((domain: OrgRubricDomain) => {
      domain.criteria.forEach((criteria: OrgRubricCriteria) => {
        if (Number(criteria.criteria_id) === Number(criteriaId)) {
          criteriaName = criteria.name
          criteriaDescription = criteria.description
        }
      })
    })
  }
  return (
    <div
      style={{ maxWidth: 1200 }}
      className={classNames("page hasSidebar reports analytics")}
    >
      <SidebarAnalytics />

      {!activeRubric && (
        <Fade in={true}>
          <Box
            className="fadeIn"
            sx={{
              marginTop: 10,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <LearningBuddyMessage message="Select a Rubric in the sidebar to get started with analytics." />
          </Box>
        </Fade>
      )}

      {activeRubric && (
        <>
          <Box
            sx={{
              marginBottom: 3,
              marginTop: 4,
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={RubricIcon}
              alt="Rubric"
              style={{ marginRight: 10 }}
              width={25}
              height={25}
            />
            <Typography
              sx={{
                flex: 1,
                fontWeight: "bold",
                fontSize: 22,
              }}
            >
              {/** @ts-ignore */}
              {he.decode(activeRubric.name)}
            </Typography>
            <Typography
              sx={{
                paddingRight: 3,
                fontWeight: "bold",
                fontSize: 16,
                color: "text.secondary",
              }}
            >
              Average Total Score
            </Typography>
            <Typography
              sx={(theme) => ({
                fontSize: 22,
                fontWeight: "bold",
                backgroundColor: theme.palette.secondary.main,
                padding: 3,
                paddingBlock: 1,
                borderRadius: 1,
              })}
            >
              {analytics.totalScore}
            </Typography>
          </Box>

          {(criteriaName || criteriaDescription) && (
            <Box sx={{ marginBottom: 3 }}>
              <Typography sx={{ fontWeight: "bold" }}>
                {criteriaName}
              </Typography>
              <Typography>{criteriaDescription}</Typography>
            </Box>
          )}
          {user && (
            <UserLabel
              firstName={user.field_first_name}
              lastName={user.field_last_name}
              subgroups={user.field_subgroup}
              imagePath={
                user.user_picture
                  ? process.env.REACT_APP_API_URL + user.user_picture
                  : undefined
              }
            />
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box sx={{ marginBottom: 4 }}>
              {analytics.fetched && (
                <TabsCapsule
                  value={analytics.currentTab}
                  onChange={(event, newTab) => dispatch(setCurrentTab(newTab))}
                >
                  <Tab value={"month"} label={"Month"} />
                  <Tab value={"year"} label={"Year"} />
                </TabsCapsule>
              )}
            </Box>
            {analytics.currentTab === "year" && analytics.fetched && (
              <YearChart width={width} />
            )}

            {analytics.currentTab === "month" && analytics.fetched && (
              <MonthChart width={width} />
            )}
          </Box>
        </>
      )}

      {activeRubric && !analytics.fetched && (
        <Loading
          fullPage={true}
          hideSpinner={true}
          message={
            <Box
              sx={{
                flexDirection: "column",
                alignItems: "center",
                display: "flex",
                minWidth: 200,
              }}
            >
              <Box sx={{ marginTop: 4 }}>{"Generating analytics..."}</Box>
              {/* Only show the progress bar if we know how many steps there will be */}
              <LinearProgress
                sx={{
                  width: "100%",
                  marginTop: 2,
                  borderRadius: 1,
                  height: 6,
                }}
                color="secondary"
              />
            </Box>
          }
          sx={{ zIndex: "1000!important" }}
        />
      )}
    </div>
  )
}
