import moment from "moment"
import classNames from "classnames"

import theme from "../../../theme/theme"
import he from "he"

import "../styles/certificate.scss"

import { MandatedTrainingHistory } from "../../../types/mandatedTraining"

type CertProps = {
  training: MandatedTrainingHistory
}

export function MandatedTrainingCertificate(props: CertProps) {
  const { training } = props

  return (
    <div
      id="certificateWrapper"
      className={classNames(
        "certificateWrapper",
        window.location.href.includes("certificate/learning-path") && "print"
      )}
    >
      <div className="mm-bg" />
      <div className="certificateLearningPath">
        {
          <div className="inner">
            <h2
              style={{
                maxWidth: 400,
                marginBottom: window.location.href.includes("backpack/")
                  ? 80
                  : 20,
              }}
            >
              <span style={{ marginBottom: 10, fontStyle: "normal" }}>
                This certifies that on{" "}
                <strong>
                  {moment(training.field_comp_date).format("MMMM D, YYYY")}
                </strong>
              </span>
              <strong
                style={{
                  display: "block",
                  marginTop: -8,
                  marginBottom: -10,
                  fontSize: 18,
                  fontWeight: 700,
                }}
              >
                {he.decode(training.field_first_name)}{" "}
                {he.decode(training.field_last_name)}
              </strong>

              <span style={{ marginTop: 10, fontStyle: "normal" }}>
                successfully completed all the required courses for the mandated
                training:
              </span>
            </h2>

            <span className="title" style={{ fontSize: 28 }}>
              {he.decode(training.rcs_title)}
            </span>

            <div
              className="learningPathImage"
              style={{
                opacity: 0.15,
                backgroundImage:
                  "url(" +
                  process.env.REACT_APP_API_URL +
                  training.field_image +
                  ")",
              }}
            />
            <img
              className="learningPathImage printBackground"
              src={process.env.REACT_APP_API_URL + training.field_image}
              alt={training.umta_name}
            />

            <div
              className="logoWrapper"
              style={{
                width: "auto",
                background: "white",
                padding: "5px 10px",
                borderRadius: 50,
                bottom: 20,
                left: 15,
              }}
            >
              <img
                className="logo"
                // eslint-disable-next-line
                // @ts-ignore
                src={theme.mixins.images.logo}
                alt="MobileMind"
              />
            </div>

            <div className="mandated-certificate-circle">
              <strong>100</strong>
              <span>Compliant</span>
            </div>
          </div>
        }
      </div>
    </div>
  )
}
