import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import fetchWrapper from "@mobilemind/common/src/functions/fetchWrapper"
import qs from "qs"
import _ from "lodash"
import debounceThunk from "@mobilemind/common/src/functions/debounceThunk"
import moment from "moment"

export const getEvents = createAsyncThunk(
  "complianceSlice/getEvents",
  async (args, thunkAPI) => {
    const { session, compliance } = thunkAPI.getState()

    let response = await fetchWrapper.get(
      "/api/events-calendar/" +
        session.group.id[0].value +
        "?" +
        qs.stringify({
          name: compliance.filters.eventSearch,
          type: "1",
        })
    )

    if (response.ok) {
      let data = await response.json()
      return data.rows.content ? [] : data.rows
    }
  }
)

export const getGoals = createAsyncThunk(
  "complianceSlice/getGoals",
  async (args, thunkAPI) => {
    const { compliance } = thunkAPI.getState()

    const query = {
      filter: {
        search: {
          condition: {
            operator: "CONTAINS",
            path: "name",
            value: compliance.filters.goalSearch,
          },
        },
        draft: {
          group: {
            conjunction: "OR",
          },
        },
        "null-draft": {
          condition: {
            operator: "IS NULL",
            path: "field_draft",
            memberOf: "draft",
          },
        },
        "is-draft": {
          condition: {
            operator: "=",
            path: "field_draft",
            value: false,
            memberOf: "draft",
          },
        },
      },
      include:
        "field_learning_path,field_job_title,field_subgroup,field_teacher",
    }

    let response = await fetchWrapper.get(
      "/api/goal_entity/learning_path_goal?" + qs.stringify(query)
    )

    if (response.ok) {
      let data = await response.json()

      const users = data.included
        ? data.included.filter((included) => included.type === "user--user")
        : []
      const subGroups = data.included
        ? data.included.filter((included) => included.type === "group--group")
        : []
      const jobTitles = data.included
        ? data.included.filter(
            (included) => included.type === "taxonomy_term--job_titles"
          )
        : []
      const paths = data.included
        ? data.included.filter(
            (included) => included.type === "learning_path--learning_path"
          )
        : []

      data.data.forEach((goal) => {
        const userIds = goal.relationships.field_teacher.data.map(
          (user) => user.id
        )
        const subGroupIds = goal.relationships.field_subgroup.data.map(
          (group) => group.id
        )
        const jobTitleIds = goal.relationships.field_job_title.data.map(
          (title) => title.id
        )
        goal.users = users.filter((user) => userIds.includes(user.id))
        goal.subGroups = subGroups.filter((group) =>
          subGroupIds.includes(group.id)
        )
        goal.jobTitles = jobTitles.filter((title) =>
          jobTitleIds.includes(title.id)
        )
        goal.learningPath = paths.find(
          (path) =>
            path.id === goal.relationships.field_learning_path.data[0].id
        )
      })

      return data.data
    }
  }
)

export const getComplianceTotals = createAsyncThunk(
  "complianceSlice/getComplianceTotals",
  async (args, thunkAPI) => {
    const { filters, orgId, searchQuery } = args

    const {
      contentType,
      activeEvent,
      badge,
      course,
      goal,
      learningPath,
      rsvpStatus,
      jobTitle,
      dateFrom,
      dateTo,
      // attended,
      // group,
    } = filters

    if (contentType !== "mandated-training") {
      const query = {
        search: searchQuery,
        field_job_title_target_id: jobTitle !== "all" ? jobTitle : null,
        start: dateFrom && dateFrom.split("T")[0].replace('"', ""),
        end: dateTo.split("T")[0].replace('"', ""),
      }

      let baseUrl = "/api/compliance_"

      if (contentType === "learning-path") {
        baseUrl += "lp/" + orgId
        query.lp = learningPath.field_lp_id
      } else if (contentType === "event") {
        baseUrl += "event/" + activeEvent.id + "/" + orgId
        if (rsvpStatus !== "any") {
          query.field_rsvp_value = rsvpStatus
        }
      } else if (contentType === "course") {
        baseUrl += "course/" + orgId
        query.course = course.id
      } else if (contentType === "badge") {
        baseUrl += "badge/" + orgId
        query.id = badge.id
      } else if (contentType === "goal") {
        baseUrl += "goal/" + orgId
        query.id = goal.attributes.drupal_internal__id
      }

      let url = baseUrl + "?" + qs.stringify(query)
      let response = await fetchWrapper.get(url)

      if (response.ok) {
        let data = await response.json()
        if (contentType === "badge") {
          return data.total_records
        } else {
          return data.total_completed
        }
      }
    }
  }
)

export const getLearningPaths = createAsyncThunk(
  "complianceSlice/getLearningPaths",
  async (args, thunkAPI) => {
    const { compliance } = thunkAPI.getState()

    let response = await fetchWrapper.get(
      "/api/learning_path/explore?" +
        qs.stringify({ search: compliance.filters.LPSearch })
    )

    if (response.ok) {
      let data = await response.json()
      return data.lp_data
    }
  }
)

export const getBadges = createAsyncThunk(
  "complianceSlice/getBadges",
  async (args, thunkAPI) => {
    const { compliance } = thunkAPI.getState()
    let response = await fetchWrapper.get(
      "/api/badges_entity/explore?" +
        qs.stringify({ search: compliance.filters.badgeSearch })
    )

    if (response.ok) {
      let data = await response.json()
      return data.badges_data
    }
  }
)

export const getCourses = createAsyncThunk(
  "complianceSlice/getCourses",
  async (args, thunkAPI) => {
    const { compliance } = thunkAPI.getState()
    let response = await fetchWrapper.get(
      "/api/course_entity/explore?" +
        qs.stringify({ search: compliance.filters.courseSearch })
    )

    if (response.ok) {
      let data = await response.json()
      const courses = []
      data.data.forEach((course) => {
        if (!courses.find((existing) => existing.id === course.id)) {
          courses.push(course)
        }
      })

      return courses
    }
  }
)

const debouncedGetEvents = debounceThunk(getEvents, 750)
export const updateEventSearch = createAsyncThunk(
  "complianceSlice/updateEventSearch",
  async (args, thunkAPI) => {
    thunkAPI.dispatch(debouncedGetEvents())
    return args
  }
)

const debouncedGetLearningPaths = debounceThunk(getLearningPaths, 750)
export const updateLPSearch = createAsyncThunk(
  "complianceSlice/updateLPSearch",
  async (args, thunkAPI) => {
    thunkAPI.dispatch(debouncedGetLearningPaths())
    return args
  }
)

const debouncedGetBadges = debounceThunk(getBadges, 750)
export const updateBadgeSearch = createAsyncThunk(
  "complianceSlice/updateBadgeSearch",
  async (args, thunkAPI) => {
    thunkAPI.dispatch(debouncedGetBadges())
    return args
  }
)

const debouncedGetCourses = debounceThunk(getCourses, 750)
export const updateCourseSearch = createAsyncThunk(
  "complianceSlice/updateCourseSearch",
  async (args, thunkAPI) => {
    thunkAPI.dispatch(debouncedGetCourses())
    return args
  }
)

const debouncedGetGoals = debounceThunk(getGoals, 750)
export const updateGoalSearch = createAsyncThunk(
  "complianceSlice/updateGoalSearch",
  async (args, thunkAPI) => {
    thunkAPI.dispatch(debouncedGetGoals())
    return args
  }
)

export const getCompliance = createAsyncThunk(
  "complianceSlice/getCompliance",
  async (args, thunkAPI) => {
    const { filters, orgId, subGroups, searchQuery } = args
    const { session } = thunkAPI.getState()

    const {
      contentType,
      activeEvent,
      badge,
      course,
      goal,
      learningPath,
      mandatedTraining,
      rsvpStatus,
      attended,
      group,
      jobTitle,
      page,
      dateFrom,
      dateTo,
      sortBy,
    } = filters

    // Set up our base URL based on contentType and roles
    let baseUrl = "/api/compliance-"
    let isAdminReporter =
      args.orgRoles.includes("organization-admin") ||
      args.orgRoles.includes("organization-reporter")
    if (contentType === "event") {
      baseUrl =
        "/api/user-events/" +
        activeEvent.id +
        "/" +
        session.group.id[0].value +
        "?"
    }

    let url, data, groupId

    // Handle subgroups if Groups is not "All"
    if (group !== "all") {
      let subGroup = subGroups.find(
        (group) => group.attributes.label === filters.group
      )

      groupId = subGroup.entity_id
    } else if (!isAdminReporter) {
      groupId = filters.groupAdminGroup
        ? filters.groupAdminGroup
        : session.subgroup.id[0].value
    } else {
      groupId = orgId
    }

    let query = {
      search: searchQuery,
      page: page,
    }

    if (jobTitle !== "all") {
      if (contentType === "mandated-training") {
        query.job_title = jobTitle
      } else {
        query.field_job_title_target_id = jobTitle
      }
    }

    let dateRange = {}

    if (dateFrom) {
      dateRange.min = dateFrom.replace('"', "").slice(0, 10)
    }
    if (dateTo) {
      dateRange.max = dateTo.replace('"', "").slice(0, 10)
    }

    if (dateFrom || dateTo) {
      if (contentType !== "event") {
        if (contentType === "badge") {
          query.created = dateRange
        } else if (contentType === "mandated-training") {
          query.start = dateRange.min
          query.end = dateRange.max
        } else {
          query.changed = dateRange
        }
      }
    }

    // Deal with events, set up baseUrl & soryBy
    if (contentType === "event") {
      url = baseUrl
      query.sort_by = sortBy

      // The rest of the filters
      if (rsvpStatus !== "any") {
        query.field_rsvp_value = rsvpStatus
      }
      if (attended !== "any") {
        query.field_attended_value = attended
      }
      if (group !== "all") {
        query.field_subgroup_value = group
      }
      if (!isAdminReporter) {
        query.field_subgroup_value = session.user.attributes.field_subgroup
      }
    }

    if (contentType === "course") {
      url = baseUrl + "course/" + groupId + "?"
      query.sort_by = sortBy.replace("status", "completed")
      query.course = course.name
    }

    if (contentType === "learning-path") {
      url = baseUrl + "learning-path/" + groupId + "?"
      query.sort_by = sortBy
      query.id = learningPath.field_lp_id
    }

    if (contentType === "badge") {
      url = baseUrl + "badge/" + groupId + "?"
      query.id = badge.id
    }

    if (contentType === "goal") {
      url = baseUrl + "goal/" + groupId + "?"
      query.sort_by =
        sortBy === "field_status_value"
          ? "field_courses_completed_value"
          : sortBy
      query.sort_order = sortBy === "field_status_value" ? "DESC" : "ASC"
      query.id = goal.attributes.drupal_internal__id
    }

    if (contentType === "mandated-training") {
      /**
       * Creates an endpoint for viewing rcs data.
       * * /api/urcs/compliance/{rcs_id}/
       * *
       * * /api/urcs/compliance/{rcs_id}/?
       * * * search= [str] (user first and or last name)
       * * * subgroup= [int] (subgroup id)
       * * * job_title = [int] (job title id)
       * * * search= [str] (user first and or last name)
       * * * &start = [str](Y-m-d format)
       * * * &end = [str](Y-m-d format)
       * * * &page[offset]= [int]
       * * * &sort_by= [str (first_name, last_name, in_compliance_date)]
       * * * &sort_order= [str (ASC, DESC)]
       */
      url = "/api/urcs/compliance/" + mandatedTraining + "?"

      query.sort_by = sortBy.replace("field_", "").replace("_value", "")
      query.page = {
        offset: page * 50,
      }
      if (group !== "all") {
        query.subgroup = groupId
      } else if (
        !session.isOrgAdmin &&
        !session.orgRoles.toString().includes("creator") &&
        (session.groupRoles.includes("group-admin") ||
          session.groupRoles.includes("group-reporter") ||
          session.groupRoles.includes("group-creator"))
      ) {
        const subGroupId = session.subgroups.data[0].drupal_internal__id
        query.subgroup = filters.groupAdminGroup ?? subGroupId
      }
    }
    if (
      course ||
      learningPath ||
      badge ||
      goal ||
      activeEvent ||
      mandatedTraining
    ) {
      let response = await fetchWrapper.get(url + qs.stringify(query))
      data = await response.json()
    }
    return data
  }
)

export const getPartnerCompliance = createAsyncThunk(
  "complianceSlice/getPartnerCompliance",
  async (args, thunkAPI) => {
    const { session, compliance } = thunkAPI.getState()
    const filters = compliance.partnerFilters

    const { contentType, start, end, learningPath, badge, course } = filters
    const orgId = session.group.id[0].value
    let url = "/api/mm_partner_portal/" + contentType + "/" + orgId + "/"

    let query = {
      page: {
        offset: compliance.currentPage * 25,
      },
    }
    if (contentType === "lp_compliance_report") {
      url += learningPath.id
    }
    if (contentType === "badges_compliance_report") {
      url += badge.id
    }
    if (contentType === "course_compliance_report") {
      url += course.id
    }

    if (start) {
      query.start = moment(start.replaceAll('"', "")).format("Y-MM-DD")
    }
    if (end) {
      query.end = moment(end.replaceAll('"', "")).format("Y-MM-DD")
    }

    let response = await fetchWrapper.get(url + "?" + qs.stringify(query))

    if (response.ok) {
      let data = await response.json()
      let keys, payload, orgData, total

      if (contentType === "lp_compliance_report") {
        orgData = data.partner_lp_data["0"]
        keys = Object.keys(orgData.org_lp_count)
        payload = keys.map((key) => orgData.org_lp_count[key])
        total = orgData.total_earned_lps
      }

      if (contentType === "badges_compliance_report") {
        orgData = data.partner_badges_data["0"]
        keys = Object.keys(orgData.org_badge_count)
        payload = keys.map((key) => orgData.org_badge_count[key])
        total = orgData.total_earned_badges
      }

      if (contentType === "course_compliance_report") {
        orgData = data.partner_course_data["0"]
        keys = Object.keys(orgData.org_course_count)
        payload = keys.map((key) => orgData.org_course_count[key])
        total = orgData.total_completed_courses
      }

      return {
        total,
        payload,
      }
    }
  }
)

export const getKnowBe4Campaigns = createAsyncThunk(
  "complianceSlice/getKnowBe4Campaigns",
  async () => {
    let baseUrl =
      "/api/knowbe4-report?url=v1/training/campaigns&page=1&per_page=100&exclude_archived_users=true"
    let response = await fetchWrapper.get(baseUrl)
    const data = await response.json()

    return data
  }
)

export const getKnowBe4Compliance = createAsyncThunk(
  "complianceSlice/getKnowBe4Compliance",
  async (args, thunkAPI) => {
    const { campaign_id } = args

    // All the stuff goes here
    let data = []
    let i = 1
    let pages = 100

    while (i < pages) {
      const query = {
        url: "v1/training/enrollments",
        campaign_id,
        page: i,
        per_page: 500,
      }

      // Get our first page
      let response = await fetchWrapper.get(
        "/api/knowbe4-report?" + qs.stringify(query)
      )

      if (response.ok) {
        let responseData = await response.json()
        data = data.concat(responseData.data)

        // They don't even give us total records or total pages, so if we're less than the
        // per-page count, we're done
        if (responseData.count < 500) {
          pages = 0
        }
      }
      i++
    }

    // Now go through and rearrange all this to suit the front end
    const userBasedData = []
    data?.length &&
      data.forEach((row) => {
        if (
          !userBasedData.find((existing) => existing.userId === row.user.id)
        ) {
          userBasedData.push({
            userId: row.user.id,
            first_name: row.user.first_name,
            last_name: row.user.last_name,
            completion_date: row.completion_date,
            courseStatuses: [row.status],
          })
        } else {
          const user = userBasedData.find(
            (existing) => existing.userId === row.user.id
          )
          user.courseStatuses.push(row.status)
        }
      })

    return userBasedData
  }
)

const today = new Date()
export const complianceSlice = createSlice({
  name: "complianceSlice",
  initialState: {
    data: [],
    total: 0,
    currentPage: 0,
    searchQuery: "",
    groupMembersFetched: false,
    fetching: false,
    rubric: {
      missingEntity: true,
    },
    mandatedTraining: {
      missingEntity: true,
    },
    learningPath: {
      missingEntity: true,
    },
    course: {
      missingEntity: true,
    },
    badge: {
      missingEntity: true,
    },
    goal: {
      missingEntity: true,
    },
    activeEvent: {
      missingEntity: true,
    },
    knowBe4Campaign: {
      missingEntity: true,
    },
    eventSearchResults: [],
    LPSearchResults: [],
    badgeSearchResults: [],
    courseSearchResults: [],
    goalSearchResults: [],
    complianceTotal: 0,
    knowBe4CampaignList: [],
    partnerFilters: {
      start: null,
      end: JSON.stringify(new Date(today)),
      sortOrder: "DESC",
      contentType: "lp_compliance_report",
      learningPath: null,
      badge: null,
      course: null,
    },
    filters: {
      sortBy: "field_status_value",
      sortOrder: "ASC",
      group: "all",
      contentType: "learning-path",
      LPSearch: "",
      learningPath: null,
      mandatedTraining: null,
      rubric: null,
      badgeSearch: "",
      badge: null,
      courseSearch: "",
      course: null,
      goalSearch: "",
      goal: null,
      jobTitle: "all",
      groupAdminGroup: null,
      eventSearch: "",
      activeEvent: null,
      rsvpStatus: "any",
      attended: "any",
      dateFrom: null,
      dateTo: JSON.stringify(new Date(today)),
      searchInput: "",
      knowBe4Campaign: null,
    },
  },
  reducers: {
    setContentType: (state, action) => {
      const { isPartner, value } = action.payload

      if (isPartner) {
        state.partnerFilters.contentType = value
      } else {
        state.total = 0
        state.currentPage = 0
        state.totalPages = 0
        state.filters.contentType = value
        state.filters.activeEvent = null
        if (value !== "learning-path" && value !== "goal") {
          state.filters.sortBy = "field_first_name_value"
        } else {
          state.filters.sortBy = "field_status_value"
        }
        if (value === "event") {
          state.filters.sortBy = "field_attended_value"
        }
        if (value === "mandated-training") {
          state.filters.sortBy = "in_compliance_date"
        }
        state.data = []
      }

      if (value === "rubric") {
        state.filters.sortBy = "completed_date"
      }
    },
    increaseCurrentPage: (state) => {
      state.currentPage++
    },
    setGroup: (state, action) => {
      state.filters.group = action.payload
      state.data = []
      state.currentPage = 0
    },
    setGroupAdminGroup: (state, action) => {
      state.filters.groupAdminGroup = action.payload
      state.data = []
      state.currentPage = 0
    },
    getPartnerLearningPaths: (state, action) => {
      state.LPSearchResults = action.payload
    },
    setSortBy: (state, action) => {
      state.filters.sortBy = action.payload
    },
    setSearchInput: (state, action) => {
      state.filters.searchInput = action.payload
    },
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload
      state.currentPage = 0
      state.data = []
    },
    setSortOrder: (state) => {
      state.data.reverse()
    },
    setCourse: (state, action) => {
      const { value, isPartner } = action.payload
      if (isPartner) {
        state.partnerFilters.course = value
      } else {
        state.filters.course = value
      }
      state.course.missingEntity = false
    },
    setActiveEvent: (state, action) => {
      state.filters.activeEvent = action.payload
      state.activeEvent.missingEntity = false
    },
    setLearningPath: (state, action) => {
      const { value, isPartner } = action.payload
      if (isPartner) {
        state.partnerFilters.learningPath = value
      } else {
        state.filters.learningPath = value
      }
      state.learningPath.missingEntity = false
    },
    setMandatedTraining: (state, action) => {
      state.filters.mandatedTraining = action.payload
      state.mandatedTraining.missingEntity = false
    },
    setRubric: (state, action) => {
      state.currentPage = 0
      state.data = []
      state.filters.rubric = action.payload
      state.rubric.missingEntity = false
    },
    setBadge: (state, action) => {
      const { value, isPartner } = action.payload
      if (value) {
        if (isPartner) {
          state.partnerFilters.badge = value
        } else {
          state.filters.badge = value
        }
        state.badge.missingEntity = false
      } else {
        state.badge.missingEntity = true
      }
    },
    setGoal: (state, action) => {
      state.filters.goal = action.payload
      state.goal.missingEntity = false
    },
    resetFilters: (state) => {
      state.filters.group = "all"
      state.filters.searchInput = ""
      state.searchQuery = ""
      state.filters.jobTitle = "all"
      state.filters.dateFrom = null
      state.filters.dateTo = JSON.stringify(new Date(today))
      state.filters.eventSearch = ""
      state.filters.rsvpStatus = "any"
      state.filters.attended = "any"
    },
    setJobTitle: (state, action) => {
      state.filters.jobTitle = action.payload
    },
    setRSVPStatus: (state, action) => {
      state.filters.rsvpStatus = action.payload
    },
    setAttended: (state, action) => {
      state.filters.attended = action.payload
    },
    updateFilters: (state, action) => {
      state.filters[action.payload.name] = action.payload.value
    },
    updatePartnerFilters: (state, action) => {
      state.partnerFilters[action.payload.name] = action.payload.value
    },
    getRubricCompliancePending: (state) => {
      state.fetching = true
      state.rubric.missingEntity = false
      if (!state.currentPage) {
        state.data = []
      }
    },
    getRubricCompliance: (state, action) => {
      state.total = action.meta.total
      state.totalPages = action.meta.totalPages
      state.fetching = false

      if (!state.currentPage) {
        state.data = action.payload
      } else {
        state.data = state.data.concat(action.payload)
      }
    },
  },
  extraReducers: {
    [updateLPSearch.pending]: (state, action) => {
      state.filters.LPSearch = action.meta.arg
    },
    [getLearningPaths.fulfilled]: (state, action) => {
      state.LPSearchResults = action.payload
    },

    [updateGoalSearch.pending]: (state, action) => {
      state.filters.goalSearch = action.meta.arg
    },
    [getGoals.fulfilled]: (state, action) => {
      state.goalSearchResults = action.payload
    },

    [updateBadgeSearch.pending]: (state, action) => {
      state.filters.badgeSearch = action.meta.arg
    },
    [getBadges.fulfilled]: (state, action) => {
      state.badgeSearchResults = action.payload
    },

    [updateCourseSearch.pending]: (state, action) => {
      state.filters.courseSearch = action.meta.arg
    },
    [getCourses.fulfilled]: (state, action) => {
      state.courseSearchResults = action.payload
    },

    [updateEventSearch.pending]: (state, action) => {
      state.filters.eventSearch = action.meta.arg
    },
    [getEvents.fulfilled]: (state, action) => {
      state.eventSearchResults = action.payload
    },
    [getComplianceTotals.fulfilled]: (state, action) => {
      state.complianceTotal = action.payload
    },
    [getCompliance.pending]: (state) => {
      state.fetching = true
    },
    [getCompliance.fulfilled]: (state, action) => {
      state.fetching = false

      state.totalPages = action.payload.pager?.total_pages

      if (!state.currentPage) {
        state.data = []
      }

      if (action.payload) {
        if (action.payload.rows && !action.payload.rows.content) {
          action.payload.rows.forEach((user) => {
            if (state.filters.contentType === "course") {
              state.course.missingEntity = false
              state.data.push(user)
            }

            if (state.filters.contentType === "event") {
              state.data.push(user)
            }

            if (state.filters.contentType === "learning-path") {
              state.learningPath.missingEntity = false
              user.field_status = user.ulp_field_status
              user.changed = user.ulp_changed
              user.courses = user.lp_courses
              user.coursesComplete = user.lp_courses_completed
              state.data.push(user)
            }

            if (state.filters.contentType === "goal") {
              state.goal.missingEntity = false
              user.courses = user.field_total_courses
              user.coursesComplete = user.field_courses_completed
              state.data.push(user)
            }

            if (state.filters.contentType === "badge") {
              state.badge.missingEntity = false
              user.courses = user.badge_courses
              user.coursesComplete = user.badge_courses_completed

              if (user.user_badge_created) {
                user.badgeCreated = user.user_badge_created
                user.isStarted = true
                state.data.push(user)
              }
            }
          })

          if (state.filters.contentType === "course") {
            let sort = state.filters.sortOrder === "DESC" ? "desc" : "asc"
            state.data = _.orderBy(state.data, (row) => row.field_status, [
              sort,
            ])
          }
        } else if (state.filters.contentType === "mandated-training") {
          state.totalPages = Math.ceil(action.payload.total_records / 50)
          state.mandatedTraining.missingEntity = false

          state.data = state.currentPage
            ? state.data.concat(action.payload.data.urcs_archive_data)
            : action.payload.data.urcs_archive_data

          state.data.forEach((row) => {
            row.field_first_name = row.user_first_name
            row.field_last_name = row.user_last_name
          })
        }
      } else {
        state.missingEntity = true
      }
    },
    [getKnowBe4Campaigns.fulfilled]: (state, action) => {
      state.knowBe4CampaignList = action.payload.data
    },
    [getKnowBe4Compliance.pending]: (state) => {
      state.fetching = true
      state.knowBe4Campaign.missingEntity = false
      if (!state.currentPage) {
        state.data = []
      }
    },

    [getKnowBe4Compliance.fulfilled]: (state, action) => {
      state.data = action.payload
      state.fetching = false
    },

    [getPartnerCompliance.fulfilled]: (state, action) => {
      const { total, payload } = action.payload

      state.fetching = false
      // The way Taylor has this set up we have to return all orgs in a single page
      state.totalPages = 1
      state.total = total

      if (payload) {
        state.data = _.orderBy(
          payload,
          (item) => item.completed_count,
          "DESC"
        ).reverse()

        if (state.filters.contentType === "lp_compliance_report") {
          state.learningPath.missingEntity = false
        }
        if (state.filters.contentType === "badges_compliance_report") {
          state.badge.missingEntity = false
        }
        if (state.filters.contentType === "course_compliance_report") {
          state.course.missingEntity = false
        }
      } else {
        state.missingEntity = true
      }
    },
  },
})

export const {
  setGroup,
  setGroupAdminGroup,
  setSortBy,
  setSortOrder,
  setContentType,
  increaseCurrentPage,
  setCourse,
  setActiveEvent,
  setBadge,
  setJobTitle,
  setRSVPStatus,
  setAttended,
  updateFilters,
  updatePartnerFilters,
  setGoal,
  setLearningPath,
  setMandatedTraining,
  setRubric,
  resetFilters,
  setSearchInput,
  setSearchQuery,
} = complianceSlice.actions

export default complianceSlice.reducer
