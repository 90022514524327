import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Tab,
  Tabs,
  TextField,
} from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers"
import { useAppDispatch } from "store/hooks"
import CategorySelect from "@mobilemind/common/src/components/CategorySelect"

import {
  getLeaderboard,
  resetFilters,
  updateField,
  updateFilters,
} from "./leaderboardsSlice"

import moment from "moment"

function SidebarLeaderboards(props) {
  const dispatch = useAppDispatch()

  const { filters } = props.leaderboards
  const {
    leaderboardType,
    group,
    dateFrom,
    dateTo,
    groupAdminGroup,
    searchQuery,
    category,
    coursesCompletedCategory,
  } = filters
  const { session, categories } = props

  const { field_open_time, field_close_time } = session.group
  const hasSchoolOpenTime =
    field_open_time && field_open_time[0] && field_open_time[0].value
  const hasSchoolCloseTime =
    field_close_time && field_close_time[0] && field_close_time[0].value

  const rewindStart =
    (session.group.field_rewind_begin &&
      session.group.field_rewind_begin[0] &&
      session.group.field_rewind_begin[0].value) ??
    moment("06/01/" + moment().format("YYYY"))
      .subtract(1, "year")
      .format()

  const rewindEnd =
    (session.group.field_rewind_end &&
      session.group.field_rewind_end[0] &&
      session.group.field_rewind_end[0].value) ??
    moment("06/01/" + moment().format("YYYY")).format()

  const showCategorySelect =
    categories &&
    (leaderboardType === "courses-completed" ||
      leaderboardType.includes("time-spent"))

  function handleTabChange(event, newValue) {
    dispatch(updateField({ field: "dateOption", value: newValue }))

    if (newValue === "allTime") {
      dispatch(updateFilters({ name: "dateFrom", value: null }))
    }
    if (newValue === "week") {
      dispatch(
        updateFilters({
          name: "dateFrom",
          value: JSON.stringify(
            moment().startOf("week").add(1, "day").format()
          ),
        })
      )
    }
    if (newValue === "month") {
      dispatch(
        updateFilters({
          name: "dateFrom",
          value: JSON.stringify(moment().startOf("month").format()),
        })
      )
    }
    if (newValue === "year") {
      dispatch(
        updateFilters({
          name: "dateFrom",
          value: JSON.stringify(moment().startOf("year").format()),
        })
      )
    }
    if (newValue === "schoolYear") {
      dispatch(
        updateFilters({
          name: "dateFrom",
          value: JSON.stringify(moment(rewindStart).format()),
        })
      )
      dispatch(
        updateFilters({
          name: "dateTo",
          value: JSON.stringify(moment(rewindEnd).format()),
        })
      )
    }
    if (newValue === "custom") {
      dispatch(updateFilters({ name: "dateFrom", value: null }))
    }
  }
  return (
    <div id="sidebar">
      <form className="filters">
        <header style={{ marginBottom: 15 }}>
          <strong>Filter</strong>
          <Button
            className="button small"
            onClick={async () => {
              await dispatch(resetFilters())
              dispatch(getLeaderboard())
            }}
          >
            Reset
          </Button>
        </header>

        <FormControl variant="standard" className="inputSelect leaderboardType">
          <InputLabel id="label-leaderboardType-select">Leaderboard</InputLabel>
          <Select
            variant="standard"
            labelId="label-leaderboardType-select"
            id="leaderboardType-select"
            value={leaderboardType}
            onChange={(event) => {
              dispatch(
                updateFilters({
                  name: "leaderboardType",
                  value: event.target.value,
                })
              )
            }}
          >
            <MenuItem value={"courses-completed"}>Courses Completed</MenuItem>
            <MenuItem value={"badges-earned"}>Badges Earned</MenuItem>
            <MenuItem value={"time-spent"}>Total Time Spent</MenuItem>
            <MenuItem value={"time-spent-course"}>Course Time Spent</MenuItem>
            <MenuItem value={"event-time-spent"}>Event Time Spent</MenuItem>
            <MenuItem value={"ext-event-time-spent"}>
              External Event Time Spent
            </MenuItem>
          </Select>
        </FormControl>

        {(session.orgRoles.includes("organization-admin") ||
          session.orgRoles.includes("organization-reporter")) && (
          <FormControl variant="standard" className="inputSelect group">
            <InputLabel id="label-group-select">Group</InputLabel>
            <Select
              variant="standard"
              labelId="label-group-select"
              id="group-select"
              value={group}
              onChange={(event) => {
                dispatch(
                  updateFilters({ name: "group", value: event.target.value })
                )
              }}
            >
              <MenuItem value={"all"}>All</MenuItem>
              {session.subGroups.data.map((group) => {
                return (
                  <MenuItem key={group.id} value={group.id}>
                    {group.attributes.label}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        )}

        {filters.leaderboardType.includes("time-spent") &&
          hasSchoolOpenTime &&
          hasSchoolCloseTime && (
            <FormControl variant="standard" className="inputSelect">
              <InputLabel id="label-schoolHours-select">
                Time Completed
              </InputLabel>
              <Select
                variant="standard"
                labelId="label-schoolHours-select"
                id="schoolHours-select"
                value={filters.schoolHours}
                onChange={(event) => {
                  dispatch(
                    updateFilters({
                      name: "schoolHours",
                      value: event.target.value,
                    })
                  )
                }}
              >
                <MenuItem value={"all"}>Any</MenuItem>
                <MenuItem value={"true"}>During School Hours</MenuItem>
                <MenuItem value={"false"}>Outside of School Hours</MenuItem>
              </Select>
            </FormControl>
          )}

        {showCategorySelect && (
          <>
            {leaderboardType !== "courses-completed" ? (
              <FormControl variant="standard" className="inputSelect">
                <InputLabel id="label-schoolHours-select">
                  Category(s)
                </InputLabel>
                <Select
                  variant="standard"
                  style={{ color: "transparent", width: "100%" }}
                  label="Category"
                  multiple
                  placeholder="Select category(s)..."
                  value={category}
                  onChange={(event) => {
                    let newValue = event.target.value

                    dispatch(
                      updateFilters({ name: "category", value: newValue })
                    )
                  }}
                >
                  {categories.topCategories.map((cat) => {
                    return (
                      <MenuItem
                        key={cat.id}
                        value={cat.attributes.drupal_internal__tid}
                      >
                        <Stack
                          style={{
                            color: "black",
                            alignItems: "center",
                          }}
                          direction="row"
                          spacing={1}
                        >
                          <img
                            src={
                              process.env.REACT_APP_API_URL +
                              cat.image?.attributes.uri.url
                            }
                            style={{ marginRight: 5 }}
                            height={25}
                            className="category-icon"
                            alt={cat.attributes.name}
                          />
                          {cat.attributes.name}
                        </Stack>
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            ) : (
              <CategorySelect
                label={"Category"}
                allowedLevels={1}
                currentValue={coursesCompletedCategory}
                hideOrgContent={true}
                categories={categories}
                onChange={(newCategory) => {
                  dispatch(
                    updateFilters({
                      name: "coursesCompletedCategory",
                      value: newCategory,
                    })
                  )
                }}
              />
            )}
          </>
        )}

        <div className="flexRow inputSearch">
          <span className="icon search" />
          <TextField
            variant="standard"
            label="Learner Search"
            value={searchQuery}
            onChange={(event) =>
              dispatch(
                updateFilters({
                  name: "searchQuery",
                  value: event.target.value,
                })
              )
            }
          />
        </div>

        {!session.orgRoles.includes("organization-admin") &&
          !session.orgRoles.includes("organization-reporter") && (
            <FormControl variant="standard" className="inputSelect group">
              <InputLabel id="label-group-select">Group</InputLabel>
              <Select
                variant="standard"
                labelId="label-group-select"
                id="group-select"
                value={
                  groupAdminGroup
                    ? groupAdminGroup
                    : session.subgroups.data[0].drupal_internal__id
                }
                onChange={(event) => {
                  dispatch(
                    updateFilters({
                      name: "groupAdminGroup",
                      value: event.target.value,
                    })
                  )
                }}
              >
                {session.subgroups.data.map((group) => {
                  return (
                    <MenuItem key={group.id} value={group.drupal_internal__id}>
                      {group.attributes.label}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          )}

        <Tabs
          className="dateTabs"
          value={props.leaderboards.dateOption}
          onChange={handleTabChange}
        >
          <Tab value="allTime" label="All Time" />
          <Tab value="week" label="This Week" />
          <Tab value="month" label="This Month" />
          <Tab value="schoolYear" label="School Year" />
          <Tab value="year" label="This Year" />
          <Tab value="dateRange" label="Custom" />
        </Tabs>

        {props.leaderboards.dateOption === "dateRange" && (
          <div id="date-range-container">
            <DatePicker
              label="Date From"
              value={moment(dateFrom)}
              onChange={(date) => {
                dispatch(
                  updateFilters({
                    name: "dateFrom",
                    value: moment(date).format("YYYY-MM-DD"),
                  })
                )
              }}
            />
            <DatePicker
              label="Date To"
              value={moment(dateTo)}
              onChange={(date) => {
                dispatch(
                  updateFilters({
                    name: "dateTo",
                    value: moment(date).format("YYYY-MM-DD"),
                  })
                )
              }}
            />
          </div>
        )}
      </form>
    </div>
  )
}

export default SidebarLeaderboards
