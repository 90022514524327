import { useFormikContext } from "formik"
import { Stack, Typography } from "@mui/material"
import UserInitial from "@mobilemind/common/src/components/UserInitial"
import DeleteButton from "components/DeleteButton"
import { CourseFormValues } from "../types"

type ListProps = {
  viewOnly?: boolean
}

export const AuthorListIndividuals = ({ viewOnly }: ListProps) => {
  const { values, setFieldValue } = useFormikContext<CourseFormValues>()
  const { author } = values
  let initials

  if (author && author.field_first_name && author.field_last_name) {
    initials =
      author.field_first_name?.charAt(0) + author.field_last_name?.charAt(0)
  }
  if (author) {
    return (
      <Stack
        direction="row"
        sx={{
          alignItems: "center",
          marginBottom: "15px!important",
          marginLeft: "0!important",
          paddingRight: 6,
        }}
        spacing={2}
      >
        {initials && (
          <UserInitial fontSize={12} size={30} initials={initials} />
        )}

        <Typography sx={{ fontWeight: "bold", fontSize: 12 }}>
          {author?.field_first_name} {author?.field_last_name}
        </Typography>

        {!viewOnly && (
          <DeleteButton
            onClick={() => {
              setFieldValue("author", null)
            }}
          />
        )}
      </Stack>
    )
  }
  return null
}
