import { DatePicker } from "@mui/x-date-pickers"
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material"
import Autocomplete from "@mui/material/Autocomplete"
import classNames from "classnames"
import _ from "lodash"
import { useCallback } from "react"
import { useAppDispatch } from "store/hooks"
import moment from "moment"
import he from "he"

import {
  resetFilters,
  setActiveEvent,
  setAttended,
  setBadge,
  setContentType,
  setCourse,
  setGoal,
  setGroup,
  setGroupAdminGroup,
  setJobTitle,
  setLearningPath,
  setMandatedTraining,
  setRubric,
  setRSVPStatus,
  setSearchInput,
  setSearchQuery,
  setSortBy,
  updateBadgeSearch,
  updateCourseSearch,
  updateEventSearch,
  updateFilters,
  updateGoalSearch,
  updateLPSearch,
  getKnowBe4Compliance,
} from "./complianceSlice"

import { getRubricCompliance } from "actions/rubrics"

function SidebarCompliance(props) {
  const dispatch = useAppDispatch()

  const {
    contentType,
    rsvpStatus,
    eventSearch,
    LPSearch,
    badgeSearch,
    courseSearch,
    goalSearch,
    activeEvent,
    attended,
    course,
    goal,
    jobTitle,
    dateFrom,
    dateTo,
    sortBy,
    badge,
    learningPath,
    searchInput,
    group,
    groupAdminGroup,
    mandatedTraining,
    rubric,
    knowBe4Campaign,
  } = props.compliance.filters

  const { session } = props

  let canUseKnowBe4 =
    session.group.field_knowbe4_api_key[0]?.value &&
    (session.isOrgAdmin || session.orgRoles.includes("organization-reporter"))

  const {
    eventSearchResults,
    LPSearchResults,
    badgeSearchResults,
    courseSearchResults,
    goalSearchResults,
  } = props.compliance

  const isObserver =
    session.orgRoles.includes("observer") ||
    session.orgRoles.includes("organization-observation_admin") ||
    session.groupRoles.includes("group-observer") ||
    session.groupRoles.includes("group-observation_admin")

  const allRoles = session.orgRoles.concat(session.groupRoles).join(",")

  const showEntityContentTypes =
    allRoles.includes("organization-admin") ||
    allRoles.includes("organization-reporter") ||
    allRoles.includes("group-admin") ||
    allRoles.includes("group-reporter")

  const setQuery = (value) => dispatch(setSearchQuery(value))
  // eslint-disable-next-line
  const setQueryDebounced = useCallback(_.debounce(setQuery, 500), [])

  function handleChange(value) {
    dispatch(setSearchInput(value))

    if (contentType === "rubric") {
      dispatch(getRubricCompliance())
    } else {
      setQueryDebounced(value)
    }
  }

  return (
    <div id="sidebar">
      <form className="filters">
        <header style={{ marginBottom: 15 }}>
          <strong>Filter</strong>
          <Button
            className="button small"
            onClick={() => {
              dispatch(resetFilters())
              if (contentType === "rubric") {
                dispatch(getRubricCompliance())
              }
            }}
          >
            Reset
          </Button>
        </header>
        <FormControl variant="standard" className="inputSelect contentType">
          <InputLabel id="label-contentType-select">Content Type</InputLabel>
          <Select
            variant="standard"
            labelId="label-contentType-select"
            id="contentType-select"
            value={contentType}
            onChange={(event) => {
              dispatch(setContentType({ value: event.target.value }))
            }}
          >
            {showEntityContentTypes && (
              <MenuItem value={"learning-path"}>Learning Path</MenuItem>
            )}
            {showEntityContentTypes && (
              <MenuItem value={"badge"}>Badge</MenuItem>
            )}
            {showEntityContentTypes && (
              <MenuItem value={"course"}>Course</MenuItem>
            )}
            {showEntityContentTypes && (
              <MenuItem value={"event"}>Event</MenuItem>
            )}
            {showEntityContentTypes && <MenuItem value={"goal"}>Goal</MenuItem>}
            {showEntityContentTypes && (
              <MenuItem value={"mandated-training"}>Mandated Training</MenuItem>
            )}
            {isObserver && <MenuItem value={"rubric"}>Rubric</MenuItem>}

            {canUseKnowBe4 && (
              <MenuItem value={"knowbe4"}>KnowBe4 Campaign</MenuItem>
            )}
          </Select>
        </FormControl>
        {contentType === "knowbe4" && (
          <FormControl
            variant="standard"
            className={classNames(
              "inputSelect knowBe4Campaign",
              !knowBe4Campaign && "attract"
            )}
          >
            <InputLabel id="label-knowBe4Campaign-select">Campaign</InputLabel>
            <Select
              variant="standard"
              labelId="label-knowBe4Campaign-select"
              id="knowBe4Campaign-select"
              value={knowBe4Campaign}
              onChange={(event) => {
                dispatch(
                  updateFilters({
                    name: "knowBe4Campaign",
                    value: event.target.value,
                  })
                )
                dispatch(
                  getKnowBe4Compliance({ campaign_id: event.target.value })
                )
              }}
            >
              {props.compliance.knowBe4CampaignList.map((campaign) => {
                return (
                  <MenuItem
                    key={campaign.campaign_id}
                    value={campaign.campaign_id}
                  >
                    {campaign.name}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        )}

        {contentType === "rubric" && (
          <FormControl
            variant="standard"
            className={classNames("inputSelect rubric", !rubric && "attract")}
          >
            <InputLabel id="label-rubric-select">Rubric</InputLabel>
            <Select
              variant="standard"
              labelId="label-rubric-select"
              id="rubric-select"
              value={rubric}
              onChange={(event) => {
                dispatch(setRubric(event.target.value))
                dispatch(getRubricCompliance({ rubricId: event.target.value }))
              }}
            >
              {props.entityList.orgRubrics.data.map((rubric) => {
                if (rubric.field_draft === "0") {
                  return (
                    <MenuItem key={rubric.id} value={rubric.id}>
                      {he.decode(rubric.name)}
                    </MenuItem>
                  )
                }
                return null
              })}
            </Select>
          </FormControl>
        )}

        {contentType === "mandated-training" && (
          <FormControl
            variant="standard"
            className={classNames(
              "inputSelect mandatedTraining",
              !mandatedTraining && "attract"
            )}
          >
            <InputLabel id="label-mandatedTraining-select">
              Mandated Training
            </InputLabel>
            <Select
              variant="standard"
              labelId="label-mandatedTraining-select"
              id="mandatedTraining-select"
              value={mandatedTraining}
              onChange={(event) =>
                dispatch(setMandatedTraining(event.target.value))
              }
            >
              {props.entityList.mandatedTraining.data.map((training) => {
                return (
                  <MenuItem key={training.uuid} value={training.id}>
                    {training.name}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        )}
        {contentType === "learning-path" && (
          <FormControl
            variant="standard"
            className={classNames(
              "inputSelect learningPath",
              !learningPath && "attract"
            )}
          >
            <Autocomplete
              id="learning-path-select"
              loadingText={
                LPSearch
                  ? "Getting learning paths..."
                  : "Start typing to search..."
              }
              options={LPSearchResults}
              value={learningPath ? learningPath : LPSearch}
              getOptionLabel={(path) => (path.name ? path.name : LPSearch)}
              renderInput={(params) => (
                <TextField
                  variant="standard"
                  {...params}
                  value={LPSearch}
                  onChange={(event) =>
                    dispatch(updateLPSearch(event.target.value))
                  }
                  label="Learning Path"
                />
              )}
              onChange={(event, newValue) =>
                dispatch(setLearningPath({ value: newValue }))
              }
            />
          </FormControl>
        )}
        {contentType === "badge" && (
          <FormControl
            variant="standard"
            className={classNames("inputSelect badge", !goal && "attract")}
          >
            <Autocomplete
              id="badge-select"
              loadingText={
                badgeSearch ? "Getting badges..." : "Start typing to search..."
              }
              options={badgeSearchResults}
              value={badge ? badge : badgeSearch}
              getOptionLabel={(badge) =>
                badge.name ? badge.name : badgeSearch
              }
              renderInput={(params) => (
                <TextField
                  variant="standard"
                  {...params}
                  value={badgeSearch}
                  onChange={(event) =>
                    dispatch(updateBadgeSearch(event.target.value))
                  }
                  label="Badge"
                />
              )}
              onChange={(event, newValue) =>
                dispatch(setBadge({ value: newValue }))
              }
            />
          </FormControl>
        )}
        {contentType === "course" && (
          <FormControl
            variant="standard"
            className={classNames("inputSelect course", !course && "attract")}
          >
            <Autocomplete
              id="course-select"
              loadingText={
                courseSearch
                  ? "Getting courses..."
                  : "Start typing to search..."
              }
              options={courseSearchResults}
              value={course ? course : "none"}
              getOptionLabel={(course) =>
                course.name ? course.name : courseSearch
              }
              renderInput={(params) => (
                <TextField
                  variant="standard"
                  {...params}
                  value={courseSearch}
                  onChange={(event) =>
                    dispatch(updateCourseSearch(event.target.value))
                  }
                  label="Course"
                />
              )}
              onChange={(event, newValue) =>
                dispatch(setCourse({ value: newValue }))
              }
            />
          </FormControl>
        )}
        {contentType === "goal" && (
          <FormControl
            variant="standard"
            className={classNames("inputSelect goal", !goal && "attract")}
          >
            <Autocomplete
              id="goal-select"
              loadingText={
                goalSearch ? "Getting goals..." : "Start typing to search..."
              }
              options={goalSearchResults}
              value={goal ? goal : "none"}
              getOptionLabel={(goal) =>
                goal.attributes && goal.attributes.name
                  ? goal.attributes.name
                  : goalSearch
              }
              renderInput={(params) => (
                <TextField
                  variant="standard"
                  {...params}
                  value={goalSearch}
                  onChange={(event) =>
                    dispatch(updateGoalSearch(event.target.value))
                  }
                  label="Goal"
                />
              )}
              onChange={(event, newValue) => dispatch(setGoal(newValue))}
            />
          </FormControl>
        )}
        {contentType === "event" && (
          <FormControl
            variant="standard"
            className={classNames(
              "inputSelect event",
              !activeEvent && "attract"
            )}
          >
            <Autocomplete
              id="event-select"
              loadingText={
                eventSearch ? "Getting events..." : "Start typing to search..."
              }
              options={eventSearchResults}
              value={activeEvent ? activeEvent : eventSearch}
              getOptionLabel={(event) =>
                event.name ? event.name : eventSearch
              }
              renderInput={(params) => (
                <TextField
                  variant="standard"
                  {...params}
                  value={eventSearch}
                  onChange={(event) =>
                    dispatch(updateEventSearch(event.target.value))
                  }
                  label="Event"
                />
              )}
              onChange={(event, newValue) => dispatch(setActiveEvent(newValue))}
            />
          </FormControl>
        )}
        {contentType !== "knowbe4" && (
          <>
            {(session.orgRoles.includes("organization-admin") ||
              session.orgRoles.includes("organization-reporter") ||
              session.orgRoles.includes("organization-observation_admin")) && (
              <FormControl variant="standard" className="inputSelect group">
                <InputLabel id="label-group-select">Group</InputLabel>
                <Select
                  variant="standard"
                  labelId="label-group-select"
                  id="group-select"
                  value={group}
                  onChange={(event) => {
                    dispatch(setGroup(event.target.value))

                    if (
                      contentType === "rubric" &&
                      !props.compliance.rubric.missingEntity
                    ) {
                      dispatch(getRubricCompliance())
                    }
                  }}
                >
                  <MenuItem value={"all"}>All</MenuItem>
                  {session.subGroups.data.map((group) => {
                    return (
                      <MenuItem key={group.id} value={group.attributes.label}>
                        {group.attributes.label}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            )}

            {!session.orgRoles.includes("organization-admin") &&
              !session.orgRoles.includes("organization-reporter") &&
              (session.groupRoles.includes("group-reporter") ||
                session.groupRoles.includes("group-admin")) && (
                <FormControl variant="standard" className="inputSelect group">
                  <InputLabel id="label-group-select">Group</InputLabel>
                  <Select
                    variant="standard"
                    labelId="label-group-select"
                    id="group-select"
                    value={
                      groupAdminGroup
                        ? groupAdminGroup
                        : session.subgroups.data[0].drupal_internal__id
                    }
                    onChange={(event) => {
                      dispatch(setGroupAdminGroup(event.target.value))
                    }}
                  >
                    {session.subgroups.data.map((group) => {
                      return (
                        <MenuItem
                          key={group.id}
                          value={group.drupal_internal__id}
                        >
                          {group.attributes.label}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              )}

            {contentType !== "event" && (
              <FormControl variant="standard" className="inputSelect jobTitle">
                <InputLabel id="label-jobTitle-select">Job Title</InputLabel>
                <Select
                  variant="standard"
                  labelId="label-jobTitle-select"
                  id="jobTitle-select"
                  value={jobTitle}
                  onChange={(event) => {
                    dispatch(setJobTitle(event.target.value))

                    if (contentType === "rubric") {
                      dispatch(getRubricCompliance())
                    }
                  }}
                >
                  <MenuItem value={"all"}>All</MenuItem>
                  {props.jobTitles.data.map((jobTitle) => {
                    return (
                      <MenuItem
                        key={jobTitle.id}
                        value={jobTitle.attributes.drupal_internal__tid}
                      >
                        {jobTitle.attributes.name}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            )}
          </>
        )}
        {contentType === "event" && (
          <>
            <FormControl variant="standard" className="inputSelect rsvp">
              <InputLabel id="label-rsvp-select">RSVP</InputLabel>
              <Select
                variant="standard"
                labelId="label-rsvp-select"
                id="rsvp-select"
                value={rsvpStatus}
                onChange={(event) =>
                  dispatch(setRSVPStatus(event.target.value))
                }
              >
                <MenuItem value={"any"}>Any</MenuItem>
                <MenuItem key={"accept"} value={"accept"}>
                  Accept
                </MenuItem>
                <MenuItem key={"tentative"} value={"tentative"}>
                  Tentative
                </MenuItem>
                <MenuItem key={"decline"} value={"decline"}>
                  Decline
                </MenuItem>
              </Select>
            </FormControl>

            <FormControl variant="standard" className="inputSelect attending">
              <InputLabel id="label-attending-select">Attended</InputLabel>
              <Select
                variant="standard"
                labelId="label-attending-select"
                id="attending-select"
                value={attended}
                onChange={(event) => dispatch(setAttended(event.target.value))}
              >
                <MenuItem value={"any"}>Any</MenuItem>
                <MenuItem key={1} value={1}>
                  Attended
                </MenuItem>
                <MenuItem key={0} value={0}>
                  Did Not Attend
                </MenuItem>
              </Select>
            </FormControl>
          </>
        )}
        {contentType !== "event" && contentType !== "knowbe4" && (
          <div id="date-range-container">
            <DatePicker
              label="Date From"
              value={moment(dateFrom)}
              onChange={(date) => {
                dispatch(
                  updateFilters({
                    name: "dateFrom",
                    value: JSON.stringify(date),
                  })
                )
                if (contentType === "rubric") {
                  dispatch(getRubricCompliance())
                }
              }}
            />

            <DatePicker
              label="Date To"
              value={moment(dateTo)}
              onChange={(date) => {
                dispatch(
                  updateFilters({
                    name: "dateTo",
                    value: JSON.stringify(date),
                  })
                )
                if (contentType === "rubric") {
                  dispatch(getRubricCompliance())
                }
              }}
            />
          </div>
        )}
        <div className="flexRow inputSearch">
          <span className="icon search" />
          <TextField
            variant="standard"
            label={
              contentType === "event" ? "Participant Search" : "Learner Search"
            }
            value={
              contentType !== "knowbe4"
                ? searchInput
                : props.compliance.filters.knowBe4Search
            }
            onChange={(event) => {
              if (contentType === "knowbe4") {
                dispatch(
                  updateFilters({
                    name: "knowBe4Search",
                    value: event.target.value,
                  })
                )
              } else {
                handleChange(event.target.value)
              }
            }}
          />
        </div>

        <header style={{ marginBottom: 20 }}>
          <strong>Sort</strong>
        </header>
        <FormControl variant="standard" className="inputSelect sort">
          <InputLabel id="label-sort-select">Sort By</InputLabel>
          <Select
            variant="standard"
            labelId="label-sort-select"
            id="sort-select"
            value={sortBy}
            onChange={(event) => {
              dispatch(setSortBy(event.target.value))

              if (contentType === "rubric") {
                dispatch(getRubricCompliance())
              }
            }}
          >
            {contentType === "event" && (
              <MenuItem value={"field_attended_value"}>Attended</MenuItem>
            )}

            {(contentType === "learning-path" || contentType === "goal") && (
              <MenuItem value={"field_status_value"}>Progress</MenuItem>
            )}

            {contentType === "knowbe4" && (
              <MenuItem value={"field_status_value"}>Completed</MenuItem>
            )}
            {contentType === "rubric" && (
              <MenuItem value={"completed_date"}>Completed</MenuItem>
            )}
            <MenuItem value={"field_first_name_value"}>First Name</MenuItem>
            <MenuItem value={"field_last_name_value"}>Last Name</MenuItem>
            {contentType === "course" && (
              <MenuItem value={"field_quiz_score_value"}>Quiz Score</MenuItem>
            )}

            {contentType === "mandated-training" && (
              <MenuItem value={"in_compliance_date"}>Completed Date</MenuItem>
            )}
          </Select>
        </FormControl>
      </form>
    </div>
  )
}

export default SidebarCompliance
