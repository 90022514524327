import ButtonLarge from "@mobilemind/common/src/components/ButtonLarge"
import Loading from "@mobilemind/common/src/components/Loading"
import ProgressBar from "@mobilemind/common/src/components/ProgressBar"
import SanitizedHTML from "@mobilemind/common/src/components/SanitizedHTML"
import TimeCapsule from "@mobilemind/common/src/components/TimeCapsule"
import UserLabel from "@mobilemind/common/src/components/UserLabel"
import { convertMinutesToHoursMinutes } from "@mobilemind/common/src/functions"
import RatingStar from "@mobilemind/common/src/icons/RatingStar"
import theme from "@mobilemind/common/src/theme/theme"
import { Button, Dialog, TextField } from "@mui/material"
import classNames from "classnames"
import { Form, Formik, FormikProps } from "formik"
import he from "he"
import React, { useEffect, useMemo, useRef, useState } from "react"
import "react-quill/dist/quill.snow.css"
import { useAppSelector } from "store/hooks"
import ImageUpload from "../../../components/ImageUpload"
import { useAppDispatch } from "../../../store/hooks"

import {
  archiveRecommendedEvent,
  fetchRecommendedEventOverview,
  saveRecommendedEvent,
  unArchiveRecommendedEvent,
} from "../../../store/reducers/externalPD"

import { getSubGroups } from "../../../store/reducers/session"
import "../../../styles/external-pd.scss"
import { getJobTitles } from "../../jobTitles/jobTitlesSlice"
import { ExternalEventStep1, validationSchema1 } from "./ExternalEventStep1"
import { ExternalEventStep2, validationSchema2 } from "./ExternalEventStep2"
import { ExternalEventStep3, validationSchema3 } from "./ExternalEventStep3"
import { ExternalEventStep4 } from "./ExternalEventStep4"

interface ModalProps {
  open: boolean
  close: () => void
}

export type JobTitle = {
  id: string
  entity_id: string
}

export type Group = {
  entity_id: string
}

export type TimeBlock = {
  hours: number
  minutes: number
  categoryId: string | null
}

export type ExternalEvent = {
  id?: string
  title: string
  description: string
  isDateless: boolean
  startDate: string
  endDate: string
  jobTitles: JobTitle[]
  subGroups: Group[]
  timeBlocks: TimeBlock[]
  participantMethod: "all" | "custom"
  image?: string
  imageFilename?: string
  archived?: boolean
}

const RecommendedEventModal: React.FC<ModalProps> = ({ open, close }) => {
  const dispatch = useAppDispatch()

  const currentEvent = useAppSelector((state) => state.externalPD.currentEvent)
  const session = useAppSelector((state) => state.session)
  const jobTitles = useAppSelector((state) => state.jobTitles)
  const [currentStep, setCurrentStep] = useState(currentEvent.id ? 0 : 1)

  const [confirmArchive, setConfirmArchive] = useState(false)
  const [confirmUnarchive, setConfirmUnarchive] = useState(false)
  const [learnerSearch, setLearnerSearch] = useState("")

  useEffect(() => {
    if (!session.subGroups.fetched) {
      dispatch(getSubGroups())
    }
    if (!jobTitles.fetched) {
      dispatch(getJobTitles())
    }

    if (currentEvent.id) {
      setCurrentStep(0)
      if (!currentEvent.overview.fetched) {
        dispatch(
          fetchRecommendedEventOverview({
            id: currentEvent.drupal_internal__id,
          })
        )
      }
    } else {
      setCurrentStep(1)
    }
  }, [
    dispatch,
    currentEvent.overview.fetched,
    currentEvent.drupal_internal__id,
    currentEvent.id,
    jobTitles.fetched,
    session.subGroups.fetched,
  ])

  function onClose() {
    close()
    setConfirmArchive(false)
    setConfirmUnarchive(false)
    setTimeout(() => {
      setCurrentStep(1)
    }, 600)
  }

  // If we're looking at a saved recommended event
  const userSearchResults = currentEvent.overview.data.filter((user: any) => {
    const nameStr = user.field_first_name + " " + user.field_last_name
    return (
      !learnerSearch.length ||
      nameStr.toLowerCase().includes(learnerSearch.toLowerCase())
    )
  })

  const approvedCount = currentEvent.overview.data.filter(
    (user: any) => user.field_status === "Accepted"
  ).length

  const declinedCount = currentEvent.overview.data.filter(
    (user: any) => user.field_status === "Declined"
  ).length

  // Ref to the Formik instance, to imperatively call `validateForm` whenever the current step changes
  const ref = useRef<FormikProps<ExternalEvent>>(null)

  // Get the validation schema for the current step
  const validationSchema = useMemo(() => {
    switch (currentStep) {
      case 1: {
        return validationSchema1
      }
      case 2: {
        return validationSchema2
      }
      case 3: {
        return validationSchema3
      }
    }
  }, [currentStep])

  // Whenever the current step changes, revalidate the form using the latest validation schema returned from the above `useMemo`
  useEffect(() => void ref.current?.validateForm(), [currentStep])

  return (
    <Dialog
      id="new-external-event-modal"
      className={classNames({ "preview-step": currentStep === 4 })}
      open={open}
    >
      <Formik
        innerRef={ref}
        initialValues={currentEvent as ExternalEvent}
        onSubmit={(values) => {
          // Returning a Promise from `onSubmit` will automatically set `isSubmitting`
          // to `true` while the Promise is resolving, and then `false` once it's finished.
          // @ts-ignore
          return dispatch(saveRecommendedEvent(values))
        }}
        validationSchema={validationSchema}
        validateOnMount={true}
      >
        {/**
         * Formik provides helper values for automatically determining whether the
         * form is valid, submitting, etc.
         **/}
        {({
          errors,
          isSubmitting,
          isValid,
          setFieldValue,
          submitForm,
          values,
        }) => {
          return (
            <Form>
              <div
                className={classNames(
                  "dialog",
                  isSubmitting && "isSaving",
                  "recommended",
                  currentStep === 4 && "lastStep"
                )}
              >
                {currentStep !== 4 && (
                  <h2
                    style={{
                      backgroundColor: theme.palette.primary.main,
                      backgroundImage: values.image
                        ? "url(" + values.image + ")"
                        : "",
                    }}
                  >
                    {currentStep > 0 && (
                      <ImageUpload
                        setImage={(image: unknown) => () =>
                          setFieldValue("image", image)}
                        setFilename={(filename: string) => () =>
                          setFieldValue("imageFilename", filename)}
                      />
                    )}
                    {values.title}

                    <span
                      onClick={() => {
                        onClose()
                      }}
                      className="icon close"
                    />
                    {!values.image && currentStep > 0 && (
                      <div className="uploadLabel">
                        <span className="icon camera white" />
                        Click or Drag & Drop
                      </div>
                    )}
                  </h2>
                )}

                {!currentStep && (
                  <div className="overview-report">
                    <section className="report-totals">
                      <div
                        className="flexRow"
                        style={{
                          alignItems: "flex-start",
                          justifyContent: "space-between",
                        }}
                      >
                        <header>
                          <span className="title">Requested</span>
                          <div className="flexRow requested">
                            <span className="icon raised-hand" />
                            <strong>{currentEvent.overview.data.length}</strong>
                          </div>
                        </header>
                        <header>
                          <span className="title">Declined</span>
                          <div className="flexRow declined">
                            <span className="icon incorrect" />
                            <strong>{declinedCount}</strong>
                          </div>
                        </header>
                        <header>
                          <span className="title">Approved</span>
                          <div className="flexRow approved">
                            <span className="icon check" />
                            <strong>{approvedCount}</strong>
                          </div>
                        </header>
                        <header>
                          <span className="title">Average Rating</span>
                          <div className="flexRow ratingRow">
                            <RatingStar selected={true} />
                            <strong>{currentEvent.overview.avgRating}</strong>
                          </div>
                        </header>
                      </div>
                      <TextField
                        variant="standard"
                        label={"Search for learners"}
                        onChange={(event) => {
                          setLearnerSearch(event.target.value)
                        }}
                        value={learnerSearch}
                      />
                    </section>

                    {currentEvent.overview.fetched && (
                      <section className="user-list">
                        <ul>
                          {userSearchResults.map((user: any) => {
                            return (
                              <li>
                                <div className="flexRow">
                                  <div
                                    style={{
                                      flex: 1,
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div className="userInfo">
                                      <UserLabel
                                        firstName={he.decode(
                                          user.field_first_name
                                        )}
                                        lastName={he.decode(
                                          user.field_last_name
                                        )}
                                        imagePath={
                                          process.env.REACT_APP_API_URL +
                                          user.user_picture
                                        }
                                        subgroups={user.field_subgroup}
                                      />
                                    </div>
                                  </div>
                                  <div className="flexRow ratingRow">
                                    {[1, 2, 3, 4, 5].map((item: number) => {
                                      const selected =
                                        item <= Number(user.field_rating)
                                      return (
                                        <RatingStar
                                          key={item}
                                          selected={selected}
                                        />
                                      )
                                    })}
                                  </div>

                                  <TimeCapsule
                                    hours={
                                      convertMinutesToHoursMinutes(
                                        Number(user.field_total_time)
                                      ).hours
                                    }
                                    minutes={
                                      convertMinutesToHoursMinutes(
                                        Number(user.field_total_time)
                                      ).minutes
                                    }
                                  />

                                  {user.field_status === "Accepted" && (
                                    <span className="icon check" />
                                  )}
                                  {user.field_status === "Pending" && (
                                    <span className="icon raised-hand" />
                                  )}
                                  {user.field_status === "Declined" && (
                                    <span className="icon incorrect" />
                                  )}
                                </div>
                                {user.field_learner_comment && (
                                  <div className="comment">
                                    <div className="triangle"></div>
                                    <SanitizedHTML
                                      html={user.field_learner_comment}
                                    />
                                  </div>
                                )}
                              </li>
                            )
                          })}
                        </ul>
                      </section>
                    )}

                    {!currentEvent.overview.fetched && (
                      <section className="user-list">
                        <Loading message="Getting external requests..." />
                      </section>
                    )}
                  </div>
                )}
                {/**
                 * Split each step into its own component. The form state
                 * can be accessed by each component using `useFormikContext`.
                 * */}
                <div className="modal-body">
                  {currentStep === 1 && <ExternalEventStep1 />}
                  {currentStep === 2 && <ExternalEventStep2 />}
                  {currentStep === 3 && <ExternalEventStep3 />}
                  {currentStep === 4 && <ExternalEventStep4 />}
                </div>

                <footer>
                  {!confirmArchive && !confirmUnarchive && (
                    <div className="flexRow">
                      {currentStep === 1 && values.archived && (
                        <Button
                          onClick={() => {
                            setConfirmUnarchive(true)
                          }}
                          className="button small"
                        >
                          <span className="icon unarchive blue" />
                          Unarchive
                        </Button>
                      )}

                      {currentStep > 0 ? (
                        <Button
                          onClick={() => {
                            setCurrentStep(currentStep - 1)
                          }}
                          className="button large secondary"
                        >
                          Back
                        </Button>
                      ) : (
                        <>
                          {!values.archived && (
                            <>
                              {values.id ? (
                                <Button
                                  onClick={() => {
                                    setConfirmArchive(true)
                                  }}
                                  className="button small"
                                >
                                  <span className="icon archive blue" />
                                  Archive
                                </Button>
                              ) : (
                                <Button
                                  onClick={() => {
                                    onClose()
                                  }}
                                  className="button large secondary"
                                >
                                  Cancel
                                </Button>
                              )}
                            </>
                          )}

                          <Button
                            onClick={() => {
                              setCurrentStep(1)
                            }}
                            className="button small"
                          >
                            <span className="icon edit blue" />
                            Edit
                          </Button>
                        </>
                      )}

                      {currentStep > 0 && currentStep < 4 ? (
                        <Button
                          disabled={!isValid}
                          onClick={() => {
                            setCurrentStep(currentStep + 1)
                          }}
                          className={"button large secondary"}
                        >
                          Next
                        </Button>
                      ) : (
                        <>
                          {currentStep > 0 && (
                            <ButtonLarge
                              onClick={async () => {
                                await submitForm()
                                onClose()
                              }}
                              className="button large secondary"
                            >
                              Finish
                            </ButtonLarge>
                          )}
                        </>
                      )}
                    </div>
                  )}

                  {(confirmArchive || confirmUnarchive) && (
                    <div className="flexRow confirm">
                      <p style={{ fontSize: 14, fontStyle: "italic", flex: 1 }}>
                        Are you sure you want to{" "}
                        {confirmArchive ? <>archive</> : <>unarchive</>} this
                        event?
                      </p>
                      <Button
                        onClick={async () => {
                          setConfirmArchive(false)
                          setConfirmUnarchive(false)
                        }}
                        style={{ color: "#888" }}
                        className="button small"
                      >
                        Cancel
                      </Button>

                      <Button
                        onClick={() => {
                          if (confirmArchive) {
                            dispatch(archiveRecommendedEvent(values))
                          }

                          if (confirmUnarchive) {
                            dispatch(unArchiveRecommendedEvent(values))
                          }

                          close()
                          setConfirmArchive(false)
                          setConfirmUnarchive(false)
                          setTimeout(() => {
                            setCurrentStep(1)
                          }, 600)
                        }}
                        className="button small"
                      >
                        Yes, {confirmArchive ? <>Archive</> : <>Unarchive</>}
                      </Button>
                    </div>
                  )}

                  {currentStep > 0 && currentStep < 4 && (
                    <ProgressBar
                      width={
                        currentStep !== 1 ? ((currentStep - 1) / 3) * 100 : 4
                      }
                    />
                  )}
                </footer>
              </div>

              {isSubmitting && (
                <Loading message={"Saving recommended event..."} />
              )}
            </Form>
          )
        }}
      </Formik>
    </Dialog>
  )
}

export default RecommendedEventModal
