import { useEffect } from "react"
import { useAppDispatch } from "store/hooks"
import Autocomplete from "@mui/material/Autocomplete"
import _ from "lodash"
import he from "he"
import {
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material"

import {
  setSearchQuery,
  setStatus,
  setSortBy,
  setCategory,
  setPathType,
  setAnnouncementType,
  setSubGroup,
  setOrganization,
  setJobTitle,
  resetFilters,
  debouncedGetCourseAuthors,
  setCourseAuthor,
} from "./entityListSlice"

import "../../styles/sidebar.scss"
import CategorySelect from "@mobilemind/common/src/components/CategorySelect"

function SidebarEntityList(props) {
  const {
    currentTab,
    filters,
    organizations,
    categories,
    learningPathTypes,
    announcementTypes,
    type,
    subGroups,
    jobTitles,
    session,
  } = props

  const {
    searchQuery,
    sortBy,
    status,
    category,
    pathType,
    announcementType,
    subGroup,
    organization,
    jobTitle,
  } = filters

  const { authorSearchQuery, authorSearchResults } = props.entityList.orgCourses

  const orgRoles = session.orgRoles
  const isOrgLevel =
    orgRoles.includes("organization-admin") ||
    orgRoles.includes("organization-creator") ||
    orgRoles.includes("organization-drafter")

  let fetchData = props.fetchData
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (type === "Rubrics") {
      dispatch(fetchData())
    } else {
      fetchData(filters)
    }
  }, [fetchData, filters, type, dispatch, currentTab])

  function handleChange(value) {
    dispatch(setSearchQuery(value))
  }

  let filteredAnnouncementTypes =
    announcementTypes &&
    announcementTypes.filter((type) => {
      let name = type.attributes.name
      return (
        name !== "Support" &&
        name !== "Social" &&
        name !== "Survey" &&
        name !== "MobileMind News"
      )
    })

  return (
    <div id="sidebar" style={{ paddingTop: props.hasTabs ? 65 : 20 }}>
      <form className="filters">
        <header>
          <strong>Filter {type}</strong>
          <Button
            className="button small"
            onClick={() => {
              dispatch(resetFilters())
              fetchData(filters)
            }}
          >
            Reset
          </Button>
        </header>

        <div className="inputSearch">
          <span className="icon search" />
          <TextField
            variant="standard"
            label="Search"
            value={searchQuery}
            onChange={(event) => {
              handleChange(event.target.value)
            }}
          />
        </div>

        {categories && (
          <CategorySelect
            label={"Category"}
            allowedLevels={3}
            currentValue={category}
            categories={categories}
            onChange={(newCategory) =>
              dispatch(setCategory({ value: newCategory }))
            }
          />
        )}
        <FormControl variant="standard" className="inputSelect status">
          <InputLabel id="label-status-select">Status</InputLabel>
          <Select
            variant="standard"
            labelId="label-status-select"
            id="status-select"
            value={status}
            onChange={(event) => dispatch(setStatus(event.target.value))}
          >
            <MenuItem value={"any"}>Any</MenuItem>
            <MenuItem value={1}>Published</MenuItem>
            <MenuItem value={0}>Draft</MenuItem>
            {!announcementTypes && (
              <MenuItem value={"archived"}>Archived</MenuItem>
            )}
          </Select>
        </FormControl>

        {learningPathTypes && pathType && (
          <FormControl variant="standard" className="inputSelect pathType">
            <InputLabel id="label-pathType-select">Type</InputLabel>
            <Select
              variant="standard"
              labelId="label-pathType-select"
              id="pathType-select"
              value={pathType}
              onChange={(event) => dispatch(setPathType(event.target.value))}
            >
              <MenuItem value={"any"}>Any</MenuItem>
              {_.orderBy(learningPathTypes, [
                (type) => type.attributes.name.toUpperCase(),
              ]).map((pathType) => {
                return (
                  <MenuItem
                    key={pathType.attributes.drupal_internal__tid}
                    value={pathType.attributes.drupal_internal__tid}
                  >
                    {pathType.attributes.name}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        )}

        {filteredAnnouncementTypes && (
          <FormControl
            variant="standard"
            className="inputSelect announcementType"
          >
            <InputLabel id="label-announcementType-select">Type</InputLabel>
            <Select
              variant="standard"
              labelId="label-announcementType-select"
              id="announcementType-select"
              value={announcementType ? announcementType : "any"}
              onChange={(event) =>
                dispatch(setAnnouncementType(event.target.value))
              }
            >
              <MenuItem value={"any"}>Any</MenuItem>
              {filteredAnnouncementTypes.map((announcementType) => {
                return (
                  <MenuItem
                    key={announcementType.id}
                    value={announcementType.id}
                  >
                    {announcementType.attributes.name}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        )}

        {type !== "Goals" && type !== "Rubrics" && session.isSiteAdmin && (
          <FormControl
            variant="standard"
            className="inputSelect organizationSelect"
          >
            <Autocomplete
              id="organizationSelect"
              loading={!organizations.data.length}
              loadingText={"Getting organizations..."}
              options={organizations.data}
              value={organization ? organization : "any"}
              getOptionLabel={(org) =>
                org.attributes ? org.attributes.label : "Any"
              }
              renderInput={(params) => (
                <TextField
                  variant="standard"
                  {...params}
                  label="Organization"
                />
              )}
              onChange={(event, newValue) =>
                dispatch(setOrganization(newValue))
              }
            />
          </FormControl>
        )}

        {!session.isSiteAdmin && isOrgLevel && type !== "Rubrics" && (
          <FormControl variant="standard" className="inputSelect subGroup">
            <InputLabel id="label-subGroup-select">Group</InputLabel>
            <Select
              variant="standard"
              labelId="label-subGroup-select"
              id="subGroup-select"
              value={subGroup ? subGroup : "any"}
              onChange={(event) => dispatch(setSubGroup(event.target.value))}
            >
              <MenuItem value={"any"}>Any</MenuItem>
              <MenuItem value={"all"}>All Groups</MenuItem>
              {subGroups.data.length > 0 &&
                subGroups.data.map((group) => {
                  return (
                    <MenuItem key={group.entity_id} value={group.entity_id}>
                      {group.attributes.label}
                    </MenuItem>
                  )
                })}
            </Select>
          </FormControl>
        )}

        {!filteredAnnouncementTypes &&
          !session.isSiteAdmin &&
          type !== "Rubrics" && (
            <FormControl variant="standard" className="inputSelect jobTitle">
              <InputLabel id="label-jobTitle-select">Job Title</InputLabel>
              <Select
                variant="standard"
                labelId="label-jobTitle-select"
                id="jobTitle-select"
                value={jobTitle ? jobTitle : "any"}
                onChange={(event) => dispatch(setJobTitle(event.target.value))}
              >
                <MenuItem value={"any"}>Any</MenuItem>
                {jobTitles.data.length > 0 &&
                  jobTitles.data.map((jobTitle) => {
                    return (
                      <MenuItem
                        key={jobTitle.id}
                        value={jobTitle.attributes.name}
                      >
                        {jobTitle.attributes.name}
                      </MenuItem>
                    )
                  })}
              </Select>
            </FormControl>
          )}

        {type === "Courses" && (
          <FormControl variant="standard" className="inputSelect courseAuthor">
            <Autocomplete
              id="related-courses-select"
              options={authorSearchResults ?? []}
              value={authorSearchQuery}
              getOptionLabel={(option) => {
                if (option?.field_first_name) {
                  return (
                    he.decode(option.field_first_name) +
                    " " +
                    he.decode(option.field_last_name)
                  )
                } else return authorSearchQuery
              }}
              renderInput={(params) => (
                <TextField
                  label={"Course Author"}
                  variant="standard"
                  {...params}
                  value={authorSearchQuery}
                  onChange={(event) => {
                    dispatch(debouncedGetCourseAuthors(event.target.value))
                  }}
                  placeholder="Search for users"
                />
              )}
              onChange={(event, newValue) => {
                if (!newValue) {
                  dispatch(setCourseAuthor(""))
                } else {
                  dispatch(setCourseAuthor(newValue.uid))
                }
              }}
            />
          </FormControl>
        )}

        <header style={{ marginTop: 10, marginBottom: 20 }}>
          <strong>Sort {type}</strong>
        </header>

        <FormControl variant="standard" className="inputSelect">
          <InputLabel>Sort By</InputLabel>
          <Select
            variant="standard"
            value={sortBy}
            onChange={(event) => dispatch(setSortBy(event.target.value))}
          >
            <MenuItem value={"changed"}>Date Updated</MenuItem>
            {type === "Announcements" && (
              <MenuItem value={"field_expiration_date_value"}>
                Date Expires
              </MenuItem>
            )}
            {type === "Goals" && (
              <MenuItem value={"field_goal_date_value"}>Goal Deadline</MenuItem>
            )}
            {type !== "Goals" && session.isSiteAdmin && (
              <MenuItem
                value={
                  type === "Badges"
                    ? "field_group_target_id"
                    : "field_organization_target_id"
                }
              >
                Organization
              </MenuItem>
            )}

            <MenuItem value={"name"}>Name</MenuItem>
            {type === "Courses" && (
              <MenuItem value={"field_first_name_value"}>Creator Name</MenuItem>
            )}
          </Select>
        </FormControl>
      </form>
    </div>
  )
}

export default SidebarEntityList
