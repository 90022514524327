import { useState, useEffect } from "react"
import { useDispatch } from "react-redux"

import moment from "moment"
import classNames from "classnames"
import theme from "../../../theme/theme"
import Loading from "../../Loading"
import SocialShare from "./SocialShare"
import he from "he"

import "../styles/certificate.scss"
// eslint-disable-next-line
// @ts-ignore
import badgeCertificateBgImg from "../../../img/badge-certificate-bg.png"

import { createBadgeCertificate } from "../../../actions/user"
import { Badge } from "../../../types/explore"
import { BackpackUserData } from "../../../types/backpack"
import { MobileMindUser } from "../../../types/session"

type CertificateProps = {
  badge: Badge
  isSharing: boolean
  userData?: BackpackUserData
  user: MobileMindUser
}

function BadgeCertificate(props: CertificateProps) {
  const { badge, user, isSharing, userData } = props
  const [certificateGenerated, setCertificateGenerated] = useState(false)
  const [newCertId, setNewCertId] = useState("")

  const dispatch = useDispatch()

  useEffect(() => {
    if (isSharing) {
      if (badge.field_user_badge_cert_uuid) {
        setCertificateGenerated(true)
        setNewCertId(badge.field_user_badge_cert_uuid)
      } else if (!newCertId) {
        createCertificate()
      }
    }
  }, [badge.field_user_badge_cert_uuid, newCertId, isSharing])

  const createCertificate = async () => {
    const newCert = await dispatch(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      createBadgeCertificate(badge)
    )

    setTimeout(() => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setNewCertId(newCert.data.id)
      setCertificateGenerated(true)
    }, 1000)
  }

  const shareUrl =
    process.env.REACT_APP_TEACHER_URL + "/certificate-share/badge/" + newCertId

  let userName =
    user.attributes.field_first_name + " " + user.attributes.field_last_name

  if (userData) {
    userName =
      userData.field_first_name_value + " " + userData.field_last_name_value
  }
  return (
    <div className={classNames("certificateWrapper", "print")}>
      <div className="certificateBadge">
        {window.location.href.includes("mobilemind.io") ||
        window.location.href.includes("localhost") ? (
          <img
            className="bg-badge"
            src={badgeCertificateBgImg}
            alt="You earned a badge!"
          />
        ) : (
          <div
            style={{
              opacity: 0.2,
              backgroundPosition: "center",
              backgroundImage:
                "url(\"data:image/svg+xml,%3Csvg width='100' height='20' viewBox='0 0 100 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.184 20c.357-.13.72-.264 1.088-.402l1.768-.661C33.64 15.347 39.647 14 50 14c10.271 0 15.362 1.222 24.629 4.928.955.383 1.869.74 2.75 1.072h6.225c-2.51-.73-5.139-1.691-8.233-2.928C65.888 13.278 60.562 12 50 12c-10.626 0-16.855 1.397-26.66 5.063l-1.767.662c-2.475.923-4.66 1.674-6.724 2.275h6.335zm0-20C13.258 2.892 8.077 4 0 4V2c5.744 0 9.951-.574 14.85-2h6.334zM77.38 0C85.239 2.966 90.502 4 100 4V2c-6.842 0-11.386-.542-16.396-2h-6.225zM0 14c8.44 0 13.718-1.21 22.272-4.402l1.768-.661C33.64 5.347 39.647 4 50 4c10.271 0 15.362 1.222 24.629 4.928C84.112 12.722 89.438 14 100 14v-2c-10.271 0-15.362-1.222-24.629-4.928C65.888 3.278 60.562 2 50 2 39.374 2 33.145 3.397 23.34 7.063l-1.767.662C13.223 10.84 8.163 12 0 12v2z' fill='" +
                theme.palette.secondary.main.replace("#", "%23") +
                "' fill-opacity='1' fill-rule='evenodd'/%3E%3C/svg%3E\")",
            }}
            className="bg-badge"
          />
        )}

        <h2>
          {he.decode(userName)}
          <strong>Earned a Badge!</strong>
        </h2>

        <div className="badgeImageWrapper">
          <span
            className="badgeImage"
            style={{
              backgroundImage: "url(" + badge.field_badge_image_uri + ")",
            }}
          />
          <img
            className="badgeImage printBadge"
            src={badge.field_badge_image_uri}
            alt={badge.name}
          />
          <span className="badgePlaceholder" />
        </div>

        <footer>
          <span className="title">{he.decode(badge.name)}</span>
          <span className="timestamp">
            {moment(badge.earned_date).format("MMMM D, YYYY")}
          </span>
          <img
            className="logo"
            // eslint-disable-next-line
            // @ts-ignore
            src={theme.mixins.images.logo}
            alt="MobileMind"
          />
        </footer>
      </div>

      {isSharing && (
        <div className="shareLink">
          {certificateGenerated ? (
            <>
              <strong>Copy the link below to share your certificate!</strong>
              <SocialShare shareUrl={shareUrl} badge={badge} />
            </>
          ) : (
            <Loading message={"Preparing certificate..."} />
          )}
        </div>
      )}
    </div>
  )
}

export default BadgeCertificate
