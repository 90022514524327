import Loading from "@mobilemind/common/src/components/Loading"
import { useState } from "react"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { RootState } from "../../store/types"

import { Field, Formik } from "formik"

import ButtonLarge from "@mobilemind/common/src/components/ButtonLarge"
import { SettingSwitch } from "@mobilemind/common/src/components/SettingSwitch"
import {
  Button,
  Card,
  FormControl,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material"
import ReactQuill from "react-quill"
import { formats, modules } from "../../constants/reactQuillConfig"
import { DatePicker, TimePicker } from "@mui/x-date-pickers"
import {
  removeKB4APIKey,
  updateOrgSettings,
} from "features/session/sessionSlice"
import moment from "moment"
import LeaderboardIcon from "../../img/i-leaderboards.svg"
import KB4Icon from "../../img/kb4.svg"
import Mail from "../../img/invitation-color.svg"
import ObservationIcon from "../../img/observation-color.svg"
import RewindIcon from "../../img/school-bus.svg"
import SettingsIcon from "../../img/settings-color.svg"
import TimeZoneIcon from "../../img/time-zone.svg"
import ClockIcon from "../../img/working-hours.svg"
import { convertSchoolStartEndTime } from "./utility"

export const OrgSettingsLayout = () => {
  const session = useAppSelector((state: RootState) => state.session)

  const {
    field_teacher_leaderboard_visibi,
    field_rewind_begin,
    field_rewind_end,
    field_open_time,
    field_close_time,
    field_knowbe4_api_key,
    field_org_time_zone,
    field_disable_observation,
    field_custom_mail,
    field_email_subject,
    field_email_body,
  } = session.group

  type OrgSettings = {
    field_teacher_leaderboard_visibi: string
    field_rewind_begin: string
    field_rewind_end: string
    field_open_time: string
    field_close_time: string
    field_knowbe4_api_key: string
    field_org_time_zone: string
    field_disable_observation: boolean
    field_custom_mail: boolean
    field_email_subject: string
    field_email_body: string
  }

  const { startTime, endTime } = convertSchoolStartEndTime({
    field_open_time,
    field_close_time,
  })

  const initialValues: OrgSettings = {
    field_teacher_leaderboard_visibi:
      field_teacher_leaderboard_visibi[0]?.value,
    field_org_time_zone: field_org_time_zone[0]?.value,
    field_disable_observation: field_disable_observation[0]?.value,
    field_custom_mail: field_custom_mail[0]?.value,
    field_email_subject: field_email_subject[0]?.value ?? "",
    field_email_body: field_email_body[0]?.value ?? "",
    field_rewind_begin:
      field_rewind_begin[0]?.value ??
      moment()
        .subtract(1, "year")
        .startOf("year")
        .add(7, "months")
        .format("YYYY-MM-DD"),
    field_rewind_end:
      field_rewind_end[0]?.value ??
      moment()
        .startOf("year")
        .add(4, "months")
        .add(27, "days")
        .format("YYYY-MM-DD"),
    field_open_time: startTime,
    field_close_time: endTime,
    field_knowbe4_api_key: field_knowbe4_api_key[0]?.value ?? "",
  }
  const [isSaving, setIsSaving] = useState(false)
  const [isMissingKB4] = useState(
    field_knowbe4_api_key[0]?.value ? false : true
  )
  const [isCustomEmail, setIsCustomEmail] = useState(
    field_custom_mail[0]?.value ?? false
  )

  const dispatch = useAppDispatch()

  return (
    <div
      className="page org-settings"
      style={{ maxWidth: 1024, margin: "0 auto", paddingTop: 80 }}
    >
      <h2 style={{ marginBottom: 20, justifyContent: "center" }}>
        <img
          src={SettingsIcon}
          alt="Settings"
          style={{ width: 35, height: 35, marginRight: 10 }}
        />
        Organization Settings
      </h2>
      {isSaving && <Loading fullPage={true} message="Just a moment..." />}

      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          setIsSaving(true)
          //@ts-ignore
          dispatch(updateOrgSettings({ values })).then(() => {
            setTimeout(() => {
              window.location.reload()
            }, 750)
          })
        }}
      >
        {({ values, setFieldValue, submitForm }) => (
          <Card sx={{ padding: 5 }}>
            {
              <div
                style={{
                  opacity: isSaving ? 0.5 : 1,
                  pointerEvents: isSaving ? "none" : "all",
                }}
              >
                <FormControl margin="normal">
                  <header style={styles.header}>
                    <img
                      src={RewindIcon}
                      alt="School Year"
                      style={styles.headerIcon}
                    />
                    School Year
                  </header>
                  <p style={styles.helperText}>
                    Used for the School Year filter on reports as well as
                    MobileMind Rewind.
                  </p>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: 10,
                    }}
                  >
                    <div style={{ marginRight: 20, flex: 1 }}>
                      <Field
                        label="School Year Begins"
                        as={DatePicker}
                        value={moment(values.field_rewind_begin)}
                        onChange={(event: any) => {
                          setFieldValue(
                            "field_rewind_begin",
                            moment(event).format()
                          )
                        }}
                        name="field_rewind_begin"
                      />
                    </div>
                    <div style={{ flex: 1 }}>
                      <Field
                        label="School Year Ends"
                        as={DatePicker}
                        value={moment(values.field_rewind_end)}
                        onChange={(event: any) => {
                          setFieldValue(
                            "field_rewind_end",
                            moment(event).format()
                          )
                        }}
                        name="field_rewind_end"
                      />
                    </div>
                  </div>
                </FormControl>

                <FormControl margin="normal">
                  <header style={styles.header}>
                    <img
                      src={ClockIcon}
                      alt="School Hours"
                      style={styles.headerIcon}
                    />
                    School Hours
                  </header>
                  <p style={styles.helperText}>
                    Used for the Time of Day filter on Leaderboards.
                  </p>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: 10,
                    }}
                  >
                    <div style={{ marginRight: 20, flex: 1 }}>
                      <Field
                        label="School Hours Begin"
                        as={TimePicker}
                        value={moment(values.field_open_time)}
                        onChange={(event: any) => {
                          setFieldValue(
                            "field_open_time",
                            moment(event).format()
                          )
                        }}
                        name="field_open_time"
                      />
                    </div>

                    <div style={{ flex: 1 }}>
                      <Field
                        label="School Hours End"
                        component={TimePicker}
                        value={moment(values.field_close_time)}
                        onChange={(event: any) => {
                          setFieldValue(
                            "field_close_time",
                            moment(event).format()
                          )
                        }}
                        name="field_close_time"
                      />
                    </div>
                  </div>
                </FormControl>

                <FormControl margin="normal">
                  <header style={styles.header}>
                    <img
                      src={TimeZoneIcon}
                      alt="Time Zone"
                      style={styles.headerIcon}
                    />
                    Time Zone
                  </header>
                  <p style={styles.helperText}>
                    This will be the default time zone for any{" "}
                    <strong>new</strong> users added to your organization. Users
                    can always change their specific time zone later.
                  </p>

                  <div style={{ maxWidth: 600 }}>
                    <Field
                      as={Select}
                      value={values.field_org_time_zone}
                      name="field_org_time_zone"
                      labelId="label-text-field_org_time_zone-select"
                      id="text-field_org_time_zone-select"
                    >
                      <MenuItem value={"America/New_York"}>
                        Eastern Standard Time
                      </MenuItem>
                      <MenuItem value={"America/Chicago"}>
                        Central Standard Time
                      </MenuItem>
                      <MenuItem value={"America/Denver"}>
                        Mountain Standard Time
                      </MenuItem>
                      <MenuItem value={"America/Los_Angeles"}>
                        Pacific Standard Time
                      </MenuItem>
                      <MenuItem value={"America/Anchorage"}>
                        Alaska Standard Time
                      </MenuItem>
                    </Field>
                  </div>
                </FormControl>

                <FormControl margin="normal">
                  <header style={styles.header}>
                    <img
                      src={LeaderboardIcon}
                      alt="Leaderboards"
                      style={styles.headerIcon}
                    />
                    Learner Leaderboard Visibility
                  </header>
                  <p style={styles.helperText}>
                    Determines the <strong>other individuals</strong> that
                    learners can see from the Learn leaderboards. Choose from
                    all individuals in the organization, only those in the
                    learner's groups, or none at all (leaderboards are turned
                    off).
                  </p>

                  <div style={{ maxWidth: 500 }}>
                    <Field
                      as={Select}
                      value={values.field_teacher_leaderboard_visibi}
                      name="field_teacher_leaderboard_visibi"
                      labelId="label-text-field_teacher_leaderboard_visibi-select"
                      id="text-field_teacher_leaderboard_visibi-select"
                    >
                      <MenuItem value={"org"}>
                        All Individuals in Organization
                      </MenuItem>
                      <MenuItem value={"group"}>
                        Only Individals in Learner's Groups
                      </MenuItem>
                      <MenuItem value={"none"}>
                        None (Hide Leaderboard)
                      </MenuItem>
                    </Field>
                  </div>
                </FormControl>

                <FormControl margin="normal">
                  <header style={styles.header}>
                    <img
                      src={ObservationIcon}
                      alt="Observation"
                      style={styles.headerIcon}
                    />
                    Org Observation Admin
                  </header>
                  <p style={styles.helperText}>
                    If turned off, no users will be able to receive the{" "}
                    <strong>Org Observation Admin</strong> role moving forward.
                    This role is capable of viewing all user rubrics in the
                    organization.
                  </p>

                  <div style={{ maxWidth: 350 }}>
                    <SettingSwitch
                      checked={!values.field_disable_observation}
                      label="Enable Org Observation Admin Role"
                      onChange={() => {
                        setFieldValue(
                          "field_disable_observation",
                          !values.field_disable_observation
                        )
                      }}
                    />
                  </div>
                </FormControl>

                <FormControl margin="normal">
                  <header style={styles.header}>
                    <img src={KB4Icon} alt="KnowB4" style={styles.headerIcon} />
                    KnowBe4 API Key
                  </header>
                  <p style={styles.helperText}>
                    Necessary to enable the <strong>KnowBe4 Campaign</strong>{" "}
                    compliance report.
                  </p>

                  <Stack
                    sx={{ alignItems: "center" }}
                    direction="row"
                    spacing={2}
                  >
                    <Field
                      disabled={!isMissingKB4}
                      as={TextField}
                      multiline={isMissingKB4}
                      sx={{
                        width: isMissingKB4 ? "100%" : 200,
                      }}
                      value={
                        isMissingKB4
                          ? values.field_knowbe4_api_key
                          : "XXXXXXXXXX" +
                            values.field_knowbe4_api_key.slice(-4)
                      }
                      name="field_knowbe4_api_key"
                      labelId="label-text-field_knowbe4_api_key-select"
                      id="text-field_knowbe4_api_key-select"
                    />

                    {!isMissingKB4 && (
                      <Button
                        size="small"
                        onClick={() => {
                          setIsSaving(true)

                          dispatch(removeKB4APIKey()).then(() => {
                            setTimeout(() => {
                              window.location.reload()
                            }, 750)
                          })
                        }}
                      >
                        Remove Key
                      </Button>
                    )}
                  </Stack>
                </FormControl>

                <FormControl margin="normal">
                  <header style={styles.header}>
                    <img src={Mail} alt="Mail" style={styles.headerIcon} />
                    Welcome Email
                  </header>
                  <p style={styles.helperText}>
                    Customize the message sent to welcome new members of your
                    organization.{" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://knowledge.mobilemind.io/how-should-i-introduce-our-faculty-and-staff-to-mobilemind"
                    >
                      Click here to view sample emails.
                    </a>
                  </p>

                  <Stack
                    sx={{ alignItems: "left" }}
                    direction="column"
                    spacing={2}
                  >
                    <div style={{ maxWidth: 350 }}>
                      <SettingSwitch
                        checked={values.field_custom_mail}
                        label="Enable Custom Welcome Email"
                        onChange={(checked) => {
                          setFieldValue("field_custom_mail", checked)
                          setIsCustomEmail(checked)
                        }}
                      />
                    </div>
                    {isCustomEmail && (
                      <Field
                        disabled={!isCustomEmail}
                        as={TextField}
                        multiline={false}
                        sx={{
                          width: "100%",
                        }}
                        value={values.field_email_subject}
                        name="field_email_subject"
                        labelId="label-text-field_email_subject-text"
                        id="text-field_email_subject-text"
                        label="Subject"
                      />
                    )}
                    {isCustomEmail && (
                      <ReactQuill
                        theme={"snow"}
                        readOnly={!isCustomEmail}
                        onChange={(value) => {
                          setFieldValue("field_email_body", value)
                        }}
                        value={
                          values.field_email_body ? values.field_email_body : ""
                        }
                        modules={modules}
                        formats={formats}
                        placeholder={
                          "Email body supports bold, italics and hyperlinks."
                        }
                      />
                    )}
                  </Stack>
                </FormControl>

                <footer style={{ justifyContent: "center", display: "flex" }}>
                  <ButtonLarge onClick={submitForm}>Save Settings</ButtonLarge>
                </footer>
              </div>
            }
          </Card>
        )}
      </Formik>
    </div>
  )
}

const styles = {
  header: {
    fontSize: 18,
    fontWeight: "bold",
    display: "flex",
    marginTop: 10,
    alignItems: "center",
  },
  headerIcon: {
    width: 25,
    height: 25,
    marginRight: 10,
  },
  helperText: {
    marginTop: 8,
    opacity: 0.75,
    fontSize: 14,
  },
}
