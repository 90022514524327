import { Box, Button, FormControl, FormLabel, Typography } from "@mui/material"
import { createNextState } from "@reduxjs/toolkit"
import { UserSelectUpdateArgs } from "features/userSelect/types"
import { useFormikContext } from "formik"
import { has, remove } from "lodash"
import { useState } from "react"
import plusImg from "../../../img/plus.svg"
import UserSelect from "../../userSelect/UserSelect"
import { CourseFormValues } from "../types"
import { ReviewersListGroups } from "./ReviewersListGroups"
import { ReviewersListIndividuals } from "./ReviewersListIndividuals"

export const ReviewersField = () => {
  const { errors, setValues, submitCount, values } =
    useFormikContext<CourseFormValues>()
  const [isReviewerModalOpen, setReviewerModalOpen] = useState(false)

  return (
    <FormControl
      required={true}
      error={submitCount > 0 && has(errors, "reviewers")}
      sx={{ alignItems: "flex-start" }}
    >
      <FormLabel required={true} sx={{ color: "unset", marginBottom: 2 }}>
        <Typography variant="h3" color="inherit" display="inline">
          Reviewers
        </Typography>
      </FormLabel>
      <Box sx={{ flexWrap: "wrap", display: "flex" }}>
        <ReviewersListIndividuals />
        <ReviewersListGroups />
      </Box>
      <UserSelect
        open={isReviewerModalOpen}
        entity={values}
        update={(args: UserSelectUpdateArgs) => () => {
          const { field, value, type } = args
          setValues(
            createNextState((draft) => {
              // Add
              if (type === "add") {
                if (field === "author") {
                  draft[field] = value
                } else {
                  draft[field] ??= []
                  draft[field].push(value)
                }
              }
              // Remove
              else {
                if (field === "author") {
                  draft[field] = null
                } else {
                  draft[field] ??= []
                  remove(draft[field], (item) => item.id === value.id)
                }
              }
            })
          )
        }}
        onClose={() => setReviewerModalOpen(false)}
        visibleTabs={"users, reviewerGroups"}
        initialTab={"users"}
        userLabel={"reviewers"}
        searchText={"Search for reviewers to allow them to review this course."}
      />
      <Button
        onClick={() => setReviewerModalOpen(true)}
        size="small"
        startIcon={<img alt={"Add Reviewers"} src={plusImg} />}
        sx={{ marginTop: 4 }}
      >
        Add Reviewers
      </Button>
    </FormControl>
  )
}
