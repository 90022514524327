import { SubGroup } from "@mobilemind/common/src/types/groups"
import { List, ListItem } from "@mui/material"
import classNames from "classnames"
import { useAppSelector } from "store/hooks"
import { RootState } from "store/types"

type UserSelectOwnSubGroupsProps = {
  onAdd: (value: SubGroup) => void
  onRemove: (value: SubGroup) => void
  subGroupSearch: string
  entity: any
}

export const UserSelectOwnSubGroups = (props: UserSelectOwnSubGroupsProps) => {
  const session = useAppSelector((state: RootState) => state.session)
  const { entity, subGroupSearch, onAdd, onRemove } = props
  return (
    <List className="event-select-list" sx={{ marginTop: 2 }}>
      {session.subgroups.data.map((group: SubGroup, index: number) => {
        const isActive = entity?.subGroups?.find(
          (current: SubGroup) =>
            (current && current.id === group.id) ||
            entity?.attendeeMethod === "all"
        )

        const matchesSearch = group.attributes?.label
          ?.toLowerCase()
          .includes(subGroupSearch.toLowerCase())

        if (!subGroupSearch || matchesSearch) {
          return (
            <ListItem
              style={{ animationDelay: index * 0.05 + "s" }}
              onClick={() => {
                if (isActive) {
                  onRemove(group)
                } else {
                  onAdd(group)
                }
              }}
              key={group.id}
              className={classNames("event-select-item", isActive && "active")}
            >
              <div className="flexRow">
                <span
                  className={"icon group"}
                  style={{
                    borderRadius: 30,
                    borderWidth: 4,
                    borderColor: "white",
                    borderStyle: "solid",
                    display: "block",
                  }}
                />
                <strong>{group.attributes?.label}</strong>
              </div>
            </ListItem>
          )
        }
        return null
      })}
    </List>
  )
}
