import React from "react"
import { Box, Dialog, Tab } from "@mui/material"
import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "store/hooks"
import FormIcon from "../../img/pre-approval-form.svg"
import ArchiveIcon from "../../img/i-archive-black.svg"

import FormColor from "../../img/pre-approval-form-color.svg"

import {
  getPreApprovalForms,
  getPreApprovalStepsForForm,
  setPreApprovalFormsModalOpen,
} from "store/reducers/preApprovalForms"

import "../../styles/categories.scss"
import "../../styles/modal.scss"

import classNames from "classnames"
import { PreApprovalFormEntity } from "./types"
import ButtonSmall from "@mobilemind/common/src/components/ButtonSmall"

import { PreApprovalFormEdit } from "./PreApprovalFormEdit"
//import { generateTimestamp } from "@mobilemind/common/src/functions"
import { RootState } from "../../store/types"
import moment from "moment"
import Loading from "@mobilemind/common/src/components/Loading"
import { TabsCapsule } from "@mobilemind/common/src/components/tabsCapsule/TabsCapsule"

const emptyForm = {
  type: "mm_form--pre_approval",
  attributes: {
    description: "",
    label: "",
  },
  questions: [
    {
      attributes: {
        field_question_name: "",
        parent_field_name: "field_questions",
        parent_type: "mm_form",
        field_answer_op: ["", ""],
        field_required: true,
      },
      type: "paragraph--open_ended_questions",
    },
  ],
  approvalSteps: [
    {
      type: "mm_appr--mm_appr",
      attributes: {
        field_approver_instructions: "",
        field_turn_time: 1,
      },
      reviewers: [],
      reviewerGroups: [],
    },
  ],
}

const styles = {
  listHeader: {
    display: "flex",
    alignItems: "center",
    marginTop: 15,
    marginBottom: -10,
    fontSize: 12,
    opacity: 0.6,
  },
  listItem: {
    padding: 2,
    paddingRight: 3,
    paddingLeft: 3,
    display: "flex",
    alignItems: "center",
    marginLeft: -2,
    marginRight: -2,
    marginBottom: 2,
    borderRadius: 12,
    backgroundColor: "white",
    transition: ".3s",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#ebebeb",
    },
  },
}

function PreApprovalFormsModal() {
  const preApprovalForms = useAppSelector((state: RootState) => {
    return state.preApprovalForms
  })

  const { data, fetched, isOpen } = preApprovalForms

  const [editingForm, setEditingForm] = useState<PreApprovalFormEntity | null>(
    null
  )
  const [loadingSteps, setLoadingSteps] = useState<boolean>(false)
  const [currentTab, setCurrentTab] = useState<string>("published")

  const dispatch = useAppDispatch()
  useEffect(() => {
    if (!fetched) {
      dispatch(getPreApprovalForms())
    }
  }, [fetched, dispatch])

  let minWidth = editingForm ? "75vw" : 650
  if (window.innerWidth > 1400 && editingForm) {
    minWidth = 1000
  }

  return (
    <Dialog
      data-no-focus-lock
      PaperProps={{
        sx: {
          minWidth,
          transition: ".3s",
          maxWidth: 500,
        },
      }}
      onClose={() => {
        dispatch(setPreApprovalFormsModalOpen(false))
        setEditingForm(null)
      }}
      className={classNames("modal feedback-forms-modal")}
      open={isOpen}
    >
      <h2 style={{ padding: 2, margin: "0 0 15px" }}>
        {editingForm && (
          <ButtonSmall
            onClick={() => {
              setEditingForm(null)
            }}
          >
            <span className="icon caret" />
          </ButtonSmall>
        )}
        <img
          src={FormColor}
          alt="Pre-Approval Form"
          style={{
            marginLeft: editingForm ? 10 : 0,
            marginRight: 10,
            width: 35,
            height: 35,
          }}
        />
        {!editingForm ? (
          <>
            <strong style={{ flex: 1 }}>Pre-Approval Forms</strong>
            <ButtonSmall
              onClick={() => {
                setEditingForm(emptyForm)
              }}
            >
              <span className="icon add" />
              <span style={{ textTransform: "uppercase" }}>Create Form</span>
            </ButtonSmall>
          </>
        ) : (
          <>
            <strong style={{ flex: 1, marginRight: 15 }}>
              {editingForm.attributes?.label ? (
                <>{editingForm.attributes?.label}</>
              ) : (
                <>New Pre-Approval Form</>
              )}
            </strong>
          </>
        )}
      </h2>
      {!editingForm && (
        <>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <TabsCapsule
              value={currentTab}
              onChange={(event, newTab) => {
                setCurrentTab(newTab)
              }}
            >
              <Tab value={"published"} label={"Published"} />
              <Tab value={"archived"} label={"Archived"} />
            </TabsCapsule>
          </Box>

          <Box
            style={{
              maxHeight: 500,
              overflowY: "auto",
              overflowX: "hidden",
              marginRight: -80,
            }}
          >
            <div style={styles.listHeader}>
              <label style={{ width: "75%" }}>Name</label>
              <label>Last Updated</label>
            </div>
            <ul
              style={{
                listStyleType: "none",
                pointerEvents: loadingSteps ? "none" : "all",
              }}
            >
              {data.map((form: PreApprovalFormEntity) => {
                const isArchived = form.attributes?.field_archive
                const shouldReturn =
                  (currentTab === "archived" && isArchived) ||
                  (currentTab === "published" && !isArchived)

                if (shouldReturn) {
                  return (
                    <Box
                      onClick={async () => {
                        setLoadingSteps(true)
                        // Get approval steps for form then set
                        const formSteps = await dispatch(
                          getPreApprovalStepsForForm({ form })
                        )

                        const completeForm = { ...form }
                        completeForm.approvalSteps = formSteps.payload

                        setEditingForm(completeForm)
                        setLoadingSteps(false)
                      }}
                      key={form.id}
                      sx={styles.listItem}
                    >
                      <div
                        style={{
                          width: "75%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {isArchived ? (
                          <img
                            alt={"Archived"}
                            src={ArchiveIcon}
                            style={{ marginRight: 10, width: 20, height: 20 }}
                          />
                        ) : (
                          <img
                            alt={"Feedback Form"}
                            src={FormIcon}
                            style={{ marginRight: 10, width: 20, height: 20 }}
                          />
                        )}

                        <strong>{form.attributes?.label}</strong>
                      </div>
                      <span style={{ fontSize: 14, paddingLeft: 3 }}>
                        {moment(form.attributes?.changed).format("M/D/YYYY")}
                      </span>
                    </Box>
                  )
                }
                return null
              })}
            </ul>
          </Box>
        </>
      )}
      {loadingSteps && (
        <Box sx={{ padding: 2, display: "flex", justifyContent: "center" }}>
          <Loading message="Loading form..." />
        </Box>
      )}
      {!fetched && (
        <Box sx={{ padding: 2, display: "flex", justifyContent: "center" }}>
          <Loading message="Getting pre-approval forms..." />
        </Box>
      )}
      {editingForm && (
        <PreApprovalFormEdit
          onSave={() => setEditingForm(null)}
          editingForm={editingForm}
        />
      )}
    </Dialog>
  )
}

export default PreApprovalFormsModal
