import { styled } from "@mui/material"
import LearningBuddyMessage from "./LearningBuddyMessage"
import Maintenance from "../img/maintenance.svg"
type MaintenanceModeMessageProps = {
  className?: string
}

export const MaintenanceModeMessage = styled(
  (props: MaintenanceModeMessageProps) => {
    return (
      <div className={props.className}>
        <img
          className="maintenance icon"
          style={{ marginBottom: 15, marginTop: -15, width: 50, height: 50 }}
          src={Maintenance}
          alt="Maintenance Mode"
          width={80}
          height={80}
        />
        <LearningBuddyMessage
          message={
            <>
              <strong>MobileMind is currently under maintenance.</strong>
              <br />
              Don&apos;t worry, we&apos;re working on it and will be right back!
            </>
          }
        />
      </div>
    )
  }
)(({ theme }) => {
  return {
    padding: theme.spacing(8),
    paddingBlockStart: 0,
    maxWidth: 400,
    marginInline: "auto",
  }
})
