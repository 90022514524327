import { MaintenanceModeMessage } from "@mobilemind/common/src/components/MaintenanceModeMessage"
import theme from "@mobilemind/common/src/theme/theme"
import { Button } from "@mui/material"
import { useAppSelector } from "store/hooks"
import { selectMaintenanceMode } from "store/selectors"
import logoImg from "../img/logo.png"
import "../styles/login.scss"

function Login() {
  const maintenanceMode = useAppSelector(selectMaintenanceMode)
  return (
    <div className="page login">
      <div
        className="backgroundWallpaper"
        style={{
          transition: "1s",
          position: "fixed",
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
          filter: "grayscale(1)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundImage: "url(" + theme.mixins.images.hqBackground + ")",
        }}
      />

      <div
        style={{
          opacity: 0.85,
          position: "fixed",
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
          background:
            "linear-gradient(120deg, " +
            "black" +
            " 0%, " +
            "#eee" +
            " 70%, " +
            "#eee" +
            " 100%)",
        }}
        className="background"
      />
      <div className="loginWrapper">
        <div className="flexRow">
          <img style={{ height: 65 }} src={logoImg} alt="MobileMind" />
        </div>
        {maintenanceMode ? (
          <MaintenanceModeMessage />
        ) : (
          <>
            <div className="flexRow">
              <Button
                className="button login"
                href={`${process.env.REACT_APP_API_URL}/user/login/google`}
              >
                <span className="icon google official" />
                <span> Sign in with Google</span>
              </Button>
            </div>
            <div className="flexRow">
              <Button
                className="button login microsoft"
                href={`${process.env.REACT_APP_API_URL}/user/login/microsoft`}
              >
                <span className="icon microsoft" />
                Sign in with Microsoft
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default Login
