import { SubGroup } from "@mobilemind/common/src/types/groups"
import { Button, FormControl, FormLabel, Typography } from "@mui/material"
import { createNextState } from "@reduxjs/toolkit"
import classNames from "classnames"
import { FieldArray, useFormikContext } from "formik"
import { has, remove } from "lodash"
import { useState } from "react"
import DeleteButton from "../../../components/DeleteButton"
import visibilityImg from "../../../img/visibility.svg"
import UserSelect from "../../userSelect/UserSelect"
import { CourseFormValues } from "../types"
import { JobTitle } from "@mobilemind/common/src/types/taxonomy/jobTitle"
import { UserSelectUpdateArgs } from "features/userSelect/types"

export const CourseVisibilityField = () => {
  const { errors, setValues, submitCount, values } =
    useFormikContext<CourseFormValues>()

  const [isVisibilityModalOpen, setVisibilityModalOpen] = useState(false)

  return (
    <FormControl
      sx={{ marginBottom: 5 }}
      error={submitCount > 0 && has(errors, "subGroups")}
    >
      <FormLabel sx={{ color: "unset" }}>
        <Typography variant="h3" display="inline" color="inherit">
          Course Visibility
        </Typography>
      </FormLabel>
      <ul style={{ marginTop: 10 }} className={"visibility-list"}>
        {!values.subGroups.length && !values.jobTitles.length ? (
          <li>Everyone</li>
        ) : (
          <>
            <FieldArray
              name="subGroups"
              render={(arrayHelpers) => (
                <>
                  {values.subGroups.map((group: SubGroup, index: number) => {
                    return (
                      <li key={group.id}>
                        <span className="icon group" />
                        <span className="label">{group.attributes?.label}</span>
                        <DeleteButton
                          onClick={() => arrayHelpers.remove(index)}
                        />
                      </li>
                    )
                  })}
                </>
              )}
            />
            <FieldArray
              name="jobTitles"
              render={(arrayHelpers) => (
                <>
                  {values.jobTitles.map((jobTitle: JobTitle, index: number) => {
                    return (
                      <li key={jobTitle.id}>
                        <span
                          className={classNames(
                            "icon role",
                            jobTitle.attributes.name.toLowerCase()
                          )}
                        />
                        <span className="label">
                          {jobTitle.attributes.name}
                        </span>

                        <DeleteButton
                          onClick={() => arrayHelpers.remove(index)}
                        />
                      </li>
                    )
                  })}
                </>
              )}
            />
          </>
        )}
      </ul>

      <UserSelect
        open={isVisibilityModalOpen}
        entity={values}
        update={(args: UserSelectUpdateArgs) => () => {
          const { field, value, type } = args
          setValues(
            createNextState((draft) => {
              // Add
              if (type === "add") {
                if (field === "author") {
                  draft[field] = value
                } else {
                  draft[field] ??= []
                  draft[field].push(value)
                }
              }
              // Remove
              else {
                if (field === "author") {
                  draft[field] = null
                } else {
                  draft[field] ??= []
                  remove(draft[field], (item) => item.id === value.id)
                }
              }
            })
          )
        }}
        onClose={() => setVisibilityModalOpen(false)}
        visibleTabs={"groups, job-titles"}
      />

      <Button
        size="small"
        onClick={() => setVisibilityModalOpen(true)}
        startIcon={<img alt={"Change Visibility"} src={visibilityImg} />}
      >
        Change Visibility
      </Button>
    </FormControl>
  )
}
