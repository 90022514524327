import { useState } from "react"
import {
  Box,
  Card,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { Formik, Field } from "formik"

import "../../styles/categories.scss"
import "../../styles/modal.scss"
import DeleteButton from "components/DeleteButton"

import { PreApprovalFormEntity } from "./types"
import ButtonSmall from "@mobilemind/common/src/components/ButtonSmall"
import ButtonLarge from "@mobilemind/common/src/components/ButtonLarge"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { useAppDispatch, useAppSelector } from "store/hooks"
import {
  deletePreApprovalQuestion,
  savePreApprovalForm,
} from "store/reducers/preApprovalForms"
import { RootState } from "../../store/types"

import { getPreApprovalForms } from "store/reducers/preApprovalForms"

import Loading from "@mobilemind/common/src/components/Loading"
import DragHandleIcon from "@mui/icons-material/DragHandle"
import UserSelect from "features/userSelect/UserSelect"
import { GroupGroup } from "@mobilemind/common/src/types/jsonapi"
import { ApprovalStep } from "./components/ApprovalStep"
import classNames from "classnames"

export function PreApprovalFormEdit(props: {
  editingForm: PreApprovalFormEntity
  onSave: () => void
}) {
  const editingForm = props.editingForm
  const dispatch = useAppDispatch()
  const [isSaving, setIsSaving] = useState(false)
  const [highlightFields, setHighlightFields] = useState(false)
  const [isUserSelectOpen, setUserSelectOpen] = useState(false)
  const [editingStepIndex, setEditingStepIndex] = useState<number>(0)

  const reviewerGroups = useAppSelector((state: RootState) => {
    return state.assessments.reviewerGroups
  })

  const { onSave } = props

  const initialValues = editingForm

  const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values) => {
        setIsSaving(true)
        await dispatch(savePreApprovalForm({ values }))

        dispatch(getPreApprovalForms())

        onSave()
        setIsSaving(false)
        setHighlightFields(false)
      }}
    >
      {({ values, setFieldValue, submitForm }) => {
        const missingRequiredFields =
          values.questions?.some(
            (question) => !question.attributes.field_question_name
          ) || !values.attributes?.label

        let currentStep =
          values.approvalSteps && values.approvalSteps[editingStepIndex]

        return (
          <>
            <UserSelect
              open={isUserSelectOpen}
              initialTab="users"
              entity={currentStep}
              selectedUsers={currentStep?.reviewers ?? []}
              update={(selected: any) => {
                const existing = values.approvalSteps
                  ? [...values.approvalSteps]
                  : []
                // If we're adding or removing a user
                if (selected.field === "attendees") {
                  if (selected.type === "add") {
                    existing[editingStepIndex].reviewers.push(selected.value)
                  } else {
                    existing[editingStepIndex].reviewers = existing[
                      editingStepIndex
                    ].reviewers.filter(
                      (reviewer) => reviewer.id !== selected.value.id
                    )
                  }
                }
                // OR a reviewer group
                else if (selected.field === "reviewerGroups") {
                  if (selected.type === "add") {
                    // @ts-ignore
                    const fullGroup: GroupGroup = reviewerGroups.data.find(
                      (group: GroupGroup) => {
                        return (
                          group.relationships.entity_id.data.meta
                            .drupal_internal__target_id ===
                          selected.value.meta.drupal_internal__target_id
                        )
                      }
                    )

                    fullGroup &&
                      existing[editingStepIndex].reviewerGroups.push(fullGroup)
                  } else {
                    existing[editingStepIndex].reviewerGroups = existing[
                      editingStepIndex
                    ].reviewerGroups.filter((reviewerGroup) => {
                      return (
                        reviewerGroup.relationships.entity_id.data.meta
                          .drupal_internal__target_id !==
                        selected.value.meta.drupal_internal__target_id
                      )
                    })
                  }
                }

                setFieldValue("approvalSteps", existing)
              }}
              onClose={() => setUserSelectOpen(false)}
              visibleTabs={"users,reviewerGroups"}
              userLabel={"approvers"}
              searchText={"Search for users to add them to this approval step."}
            />

            {isSaving && (
              <Loading
                fullPage={true}
                message={
                  <div style={{ textAlign: "center" }}>
                    <strong>
                      Saving form...
                      <br />{" "}
                      <span
                        style={{
                          color: "rgb(245, 107, 107)",
                        }}
                      >
                        Please do not navigate away from this page.
                      </span>
                    </strong>
                  </div>
                }
              />
            )}

            <div
              style={{
                pointerEvents: isSaving ? "none" : "all",
                opacity: isSaving ? 0.5 : 1,
                ...styles.questionContainer,
              }}
            >
              <Card
                style={{
                  backgroundColor: "white",
                  padding: 10,
                  borderRadius: 12,
                  marginBottom: 15,
                }}
              >
                <h3 style={{ ...styles.subheading, display: "flex" }}>
                  <strong style={{ flex: 1 }}>About This Form</strong>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: 25,
                    }}
                  >
                    <Checkbox
                      checked={values?.attributes?.field_archive}
                      onChange={(event) => {
                        setFieldValue(
                          "attributes.field_archive",
                          event.target.checked
                        )
                      }}
                    />
                    <span style={{ fontSize: 16, fontWeight: "normal" }}>
                      Archived
                    </span>
                  </Box>
                </h3>

                <Box
                  component="div"
                  sx={{ paddingInline: 2, marginBottom: 3 }}
                  style={styles.requiredIndicator.container}
                >
                  <Field
                    style={{ flex: 1 }}
                    as={TextField}
                    label="Form Name"
                    id="label"
                    name="attributes.label"
                  />

                  <div
                    // @ts-ignore
                    style={{
                      opacity:
                        highlightFields && !values.attributes?.label ? 1 : 0,
                      ...styles.requiredIndicator,
                    }}
                  />
                </Box>

                <Box
                  component="div"
                  sx={{ paddingInline: 2, marginBottom: 3 }}
                  style={styles.requiredIndicator.container}
                >
                  <Field
                    style={{ flex: 1 }}
                    as={TextField}
                    multiline
                    label="Description"
                    id="description"
                    name="attributes.description.value"
                  />

                  <div
                    // @ts-ignore
                    style={{
                      opacity:
                        highlightFields && !values.attributes?.description
                          ? 1
                          : 0,
                      ...styles.requiredIndicator,
                    }}
                  />
                </Box>
              </Card>

              <Card
                style={{
                  backgroundColor: "white",
                  padding: 10,
                  borderRadius: 12,
                  marginBottom: 15,
                }}
              >
                <h3 style={{ ...styles.subheading }}>
                  Learner Questions
                  <span
                    className="icon info learner-questions-popover"
                    style={{ width: 20, height: 20 }}
                  ></span>
                  <Card>
                    The learner's name, event name, and the date of the event
                    will be automatically recorded and don't need to be added as
                    questions.
                  </Card>
                </h3>

                <DragDropContext
                  onDragEnd={(result) => {
                    if (values.questions) {
                      if (!result.destination) {
                        return
                      }

                      setFieldValue(
                        "questions",
                        reorder(
                          values.questions,
                          result.source.index,
                          result.destination.index
                        )
                      )
                    }
                  }}
                >
                  <Droppable droppableId={"questionList"}>
                    {(provided) => (
                      <>
                        <Box
                          sx={{
                            textTransform: "uppercase",
                            display: "flex",
                            alignItems: "center",
                            marginLeft: 8,
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: 12,
                              flex: 1,
                            }}
                          >
                            Question
                          </Typography>

                          <Typography
                            style={{
                              fontSize: 12,
                              width: 180,
                            }}
                          >
                            Type
                          </Typography>
                          <Typography
                            style={{
                              width: 75,
                              fontSize: 12,
                            }}
                          >
                            Required
                          </Typography>
                        </Box>

                        <ul
                          style={{ listStyleType: "none" }}
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          className="questionList"
                        >
                          {values.questions &&
                            values.questions.map((question, index) => {
                              const {
                                field_question_name,
                                field_multi_select,
                                field_required,
                              } = question.attributes
                              const allowMultipleResponses =
                                field_multi_select === -1

                              return (
                                <Draggable
                                  key={index}
                                  draggableId={"question-" + index}
                                  index={index}
                                >
                                  {(provided) => (
                                    <li
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <Box
                                        key={question.id}
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          marginBottom: 20,
                                        }}
                                      >
                                        <DragHandleIcon
                                          sx={{
                                            color: "grey.500",
                                            gridRowStart: 2,
                                            marginRight: 3,
                                          }}
                                        />
                                        <Box
                                          style={
                                            styles.requiredIndicator.container
                                          }
                                        >
                                          <Field
                                            style={{
                                              marginBottom: 0,
                                              marginRight: 15,
                                              postMessage: "relative",
                                              zIndex: 3,
                                            }}
                                            placeholder="Enter a question"
                                            required
                                            as={TextField}
                                            value={field_question_name}
                                            label={"Question " + (index + 1)}
                                            id={`questions.${index}.attributes.field_question_name`}
                                            name={`questions.${index}.attributes.field_question_name`}
                                          />
                                          <div
                                            // @ts-ignore
                                            style={{
                                              opacity:
                                                highlightFields &&
                                                !question.attributes
                                                  .field_question_name
                                                  ? 1
                                                  : 0,
                                              ...styles.requiredIndicator,
                                            }}
                                          />
                                        </Box>
                                        <Box
                                          className={classNames(
                                            "questionTypeSelect"
                                          )}
                                        >
                                          <Field
                                            disabled={question.id}
                                            as={Select}
                                            value={question.type}
                                            label="Response Type"
                                            id={`questions.${index}.type`}
                                            name={`questions.${index}.type`}
                                          >
                                            <MenuItem value="paragraph--fixed_choice_questions">
                                              Multiple Choice
                                            </MenuItem>
                                            <MenuItem value="paragraph--date_question">
                                              Date
                                            </MenuItem>
                                            <MenuItem value="paragraph--open_ended_questions">
                                              Paragraph
                                            </MenuItem>
                                          </Field>

                                          <Field
                                            as={Checkbox}
                                            value={field_required}
                                            checked={field_required}
                                            label="Required"
                                            id={`questions.${index}.attributes.field_required`}
                                            name={`questions.${index}.attributes.field_required`}
                                          />
                                        </Box>

                                        {values?.questions && (
                                          <Box
                                            style={{
                                              pointerEvents:
                                                values?.questions.length > 1
                                                  ? "all"
                                                  : "none",
                                              opacity:
                                                values?.questions.length > 1
                                                  ? 1
                                                  : 0,
                                            }}
                                          >
                                            <DeleteButton
                                              onClick={() => {
                                                // Go through the questions and find the one we're in
                                                if (values.questions) {
                                                  const existing = [
                                                    ...values.questions,
                                                  ]

                                                  // Remove by index since several new questions may not have IDs
                                                  const updated = existing
                                                    .slice(0, index)
                                                    .concat(
                                                      existing.slice(index + 1)
                                                    )

                                                  // Remove the question from the array
                                                  setFieldValue(
                                                    "questions",
                                                    updated
                                                  )

                                                  if (question.id) {
                                                    dispatch(
                                                      deletePreApprovalQuestion(
                                                        {
                                                          questionId:
                                                            question.id,
                                                          values,
                                                        }
                                                      )
                                                    )
                                                  }
                                                }
                                              }}
                                            />
                                          </Box>
                                        )}
                                      </Box>

                                      {question.type ===
                                        "paragraph--fixed_choice_questions" && (
                                        <Stack
                                          spacing={1}
                                          sx={{
                                            marginLeft: 8,
                                            padding: 3,
                                            borderRadius: 1,
                                            marginBottom: 4,
                                            backgroundColor: "#f9f9f9",
                                          }}
                                        >
                                          {question.attributes.field_answer_op.map(
                                            (
                                              answer: string,
                                              answerIndex: number
                                            ) => {
                                              return (
                                                <Box
                                                  sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                  }}
                                                  key={answerIndex}
                                                >
                                                  <Field
                                                    style={{
                                                      marginBottom: 0,
                                                      marginRight: 15,
                                                    }}
                                                    required
                                                    onChange={(event: any) => {
                                                      setFieldValue(
                                                        `questions.${index}.attributes.field_answer_op.${answerIndex}`,
                                                        event.target.value.replaceAll(
                                                          ",",
                                                          ""
                                                        )
                                                      )
                                                    }}
                                                    as={TextField}
                                                    value={answer}
                                                    id={`questions.${index}.attributes.field_answer_op.${answerIndex}`}
                                                    name={`questions.${index}.attributes.field_answer_op.${answerIndex}`}
                                                  />
                                                  <DeleteButton
                                                    onClick={() => {
                                                      // Go through the questions and find the one we're in
                                                      if (values.questions) {
                                                        const existing = [
                                                          ...values.questions,
                                                        ]
                                                        // Remove the answer from the array
                                                        existing[
                                                          index
                                                        ].attributes.field_answer_op.splice(
                                                          answerIndex,
                                                          1
                                                        )

                                                        setFieldValue(
                                                          "questions",
                                                          existing
                                                        )
                                                      }
                                                    }}
                                                  />
                                                </Box>
                                              )
                                            }
                                          )}

                                          <Box
                                            sx={{
                                              display: "flex",
                                              justifyContent: "flex-end",
                                              paddingTop: 3,
                                            }}
                                          >
                                            <Box
                                              key={index}
                                              role="listitem"
                                              sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                marginRight: 4,
                                              }}
                                            >
                                              <FormControlLabel
                                                sx={{ marginRight: -1 }}
                                                label="Allow multiple responses"
                                                control={
                                                  <Checkbox
                                                    onChange={(event) => {
                                                      if (values.questions) {
                                                        const existing = [
                                                          ...values.questions,
                                                        ]

                                                        existing[
                                                          index
                                                          // We will set this to 1 if they want to restrict the number of answers, and set it to the actual max number they want to allow once we have that
                                                        ].attributes.field_multi_select =
                                                          event?.target.checked
                                                            ? -1
                                                            : 1

                                                        setFieldValue(
                                                          "questions",
                                                          existing
                                                        )
                                                      }
                                                    }}
                                                    checked={
                                                      allowMultipleResponses
                                                    }
                                                  />
                                                }
                                              />
                                            </Box>
                                            <ButtonSmall
                                              onClick={() => {
                                                if (values.questions) {
                                                  const existing = [
                                                    ...values.questions,
                                                  ]

                                                  existing[
                                                    index
                                                  ].attributes.field_answer_op.push(
                                                    ""
                                                  )
                                                  setFieldValue(
                                                    "questions",
                                                    existing
                                                  )
                                                }
                                              }}
                                            >
                                              <span className="icon add" />
                                              <span
                                                style={{
                                                  textTransform: "uppercase",
                                                }}
                                              >
                                                Add Answer
                                              </span>
                                            </ButtonSmall>
                                          </Box>
                                        </Stack>
                                      )}
                                    </li>
                                  )}
                                </Draggable>
                              )
                            })}
                        </ul>
                      </>
                    )}
                  </Droppable>
                </DragDropContext>

                <ButtonSmall
                  onClick={() => {
                    if (values.questions) {
                      const existing = [...values.questions]
                      existing.push({
                        attributes: {
                          field_minimum_label: "",
                          field_maximum_label: "",
                          field_question_name: "",
                          field_answer_op: ["", ""],
                          field_multi_select: -1,
                          parent_field_name: "field_questions",
                          field_required: true,
                        },
                        type: "paragraph--open_ended_questions",
                      })

                      setFieldValue("questions", existing)
                    }
                  }}
                >
                  <span className="icon add" />
                  <span style={{ textTransform: "uppercase" }}>
                    Add Question
                  </span>
                </ButtonSmall>
              </Card>

              <DragDropContext
                onDragEnd={(result) => {
                  if (values.approvalSteps) {
                    if (!result.destination) {
                      return
                    }

                    setFieldValue(
                      "approvalSteps",
                      reorder(
                        values.approvalSteps,
                        result.source.index,
                        result.destination.index
                      )
                    )
                  }
                }}
              >
                <Droppable droppableId={"questionList"}>
                  {(provided) => (
                    <ul
                      style={{
                        listStyleType: "none",
                        marginTop: 0,
                        marginBottom: 0,
                      }}
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className="questionList"
                    >
                      {values.approvalSteps &&
                        values.approvalSteps.map((step, index) => {
                          return (
                            <Draggable
                              key={index}
                              draggableId={"question-" + index}
                              index={index}
                            >
                              {(provided) => (
                                <li
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <ApprovalStep
                                    setEditingStepIndex={() =>
                                      setEditingStepIndex(index)
                                    }
                                    setUserSelectOpen={() =>
                                      setUserSelectOpen(true)
                                    }
                                    index={index}
                                  />
                                </li>
                              )}
                            </Draggable>
                          )
                        })}
                    </ul>
                  )}
                </Droppable>
              </DragDropContext>

              <Box
                sx={{ display: "flex", justifyContent: "center", marginTop: 3 }}
              >
                <ButtonSmall
                  onClick={() => {
                    if (values.approvalSteps) {
                      const existing = [...values.approvalSteps]
                      existing.push({
                        attributes: {
                          field_approver_instructions: "",
                          field_turn_time: 1,
                        },
                        reviewers: [],
                        reviewerGroups: [],
                        type: "mm_appr--mm_appr",
                      })

                      setFieldValue("approvalSteps", existing)
                    }
                  }}
                >
                  <span className="icon add" />
                  <span style={{ textTransform: "uppercase" }}>
                    Add Approval Step
                  </span>
                </ButtonSmall>
              </Box>
            </div>

            <div
              style={{
                display: isSaving ? "none" : "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: 30,
              }}
            >
              {missingRequiredFields && highlightFields && (
                <div
                  style={{
                    color: "red",
                    fontWeight: "bold",
                    fontSize: 12,
                    marginBottom: 15,
                  }}
                >
                  Please complete all required fields.
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ButtonLarge
                  onClick={() => {
                    if (missingRequiredFields) {
                      setHighlightFields(true)
                    } else {
                      submitForm()
                    }
                  }}
                >
                  <span>Save Form</span>
                </ButtonLarge>
              </div>
            </div>
          </>
        )
      }}
    </Formik>
  )
}

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    marginBottom: 15,
    marginTop: 25,
  },
  subheading: {
    fontSize: 18,
    marginLeft: 10,
    marginTop: 10,
    color: "black",
    textTransform: "none" as "none",
    position: "relative" as "relative",
  },

  listItem: {
    padding: 2,
    paddingRight: 3,
    paddingLeft: 3,
    display: "flex",
    alignItems: "center",
    marginLeft: -2,
    marginRight: -2,
    marginBottom: 2,
    borderRadius: 12,
    backgroundColor: "white",
    transition: ".3s",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgb(241 241 241)",
    },
  },

  questionContainer: {
    backgroundColor: "#ebebeb",
    padding: "15px 25px",
    marginLeft: -20,
    marginBottom: -10,
    marginRight: -20,
    overflowY: "auto" as "auto",
  },
  requiredIndicator: {
    fontSize: 12,
    transition: ".15s",
    position: "absolute" as "absolute",
    top: -1,
    left: -1,
    right: -1,
    borderRadius: 12,
    border: "3px dotted red",
    pointerEvents: "none" as "none",
    height: 52,
    container: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column" as "column",
      flex: 1,
      position: "relative" as "relative",
      marginRight: 15,
    },
  },
}
