import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import moment from "moment"
import qs from "qs"

import theme from "../../theme/theme"
import Values from "values.js"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
} from "@mui/material"
import he from "he"

import { DatePicker } from "@mui/x-date-pickers"
import classNames from "classnames"
import ButtonLarge from "../../components/ButtonLarge"
import BackpackProps from "../../types/backpack"
import { Categories, CategoryTaxonomy } from "../../types/taxonomy/category"
import LearningBuddyMessage from "../LearningBuddyMessage"
import "./styles/backpack.scss"

type SidebarProps = {
  backpack: BackpackProps
  userId?: number

  // eslint-disable-next-line
  changeNavbar?: (tab: string) => { payload: any; type: "navbar/changeNavbar" }
  categories: Categories

  // eslint-disable-next-line
  goTo?: (location: string) => any

  // eslint-disable-next-line
  rubrics?: any[]
  goToRubric?: (rubricId: string, userId: number) => void

  // eslint-disable-next-line
  session: any
  updateFilters?: (params: {
    dateRange: string
    category: number[]
    // eslint-disable-next-line
  }) => { payload: any; type: "backpack/updateFilters" }
  setDateMin?: (arg: string) => { payload: string; type: "backpack/setDateMin" }
  setDateMax?: (arg: string) => { payload: string; type: "backpack/setDateMax" }

  setSaveDateSetting?: (arg: boolean) => {
    payload: boolean
    type: "backpack/setSaveDateSetting"
  }
}

const BackpackSidebar = (props: SidebarProps) => {
  const {
    updateFilters,
    setDateMin,
    setDateMax,
    setSaveDateSetting,
    backpack,
    categories,
    userId,
    session,
    rubrics,
    goToRubric,
  } = props

  const backgroundColor = new Values(theme.palette.secondary.main)
    .tint(70)
    .hexString()
  const { dateRange, dateMin, dateMax, category } = backpack.filters

  const dispatch = useDispatch()

  const [isEditing, setIsEditing] = useState(false)
  const [selectedRubric, selectRubric] = useState<string | null>(null)

  const [selectedDateRange, selectDateRange] = useState(
    backpack.filters.dateRange
  )
  const [selectedCategories, selectCategories] = useState<number[]>([])

  const activeCategories = categories.topCategories.filter(
    (cat: CategoryTaxonomy) =>
      category.includes(cat.attributes.drupal_internal__tid)
  )

  function applyFilters() {
    dispatch(
      // eslint-disable-next-line
      // @ts-ignore
      updateFilters({
        dateRange: selectedDateRange,
        category: selectedCategories,
      })
    )

    const newFilters = { ...backpack.filters }
    newFilters.dateRange = selectedDateRange
    newFilters.category = selectedCategories

    backpack.saveDateSetting &&
      localStorage.setItem("backpackSavedFilters", JSON.stringify(newFilters))

    setIsEditing(false)
  }

  function clearFilters() {
    selectDateRange("all")
    selectCategories([])
    // eslint-disable-next-line
    // @ts-ignore
    dispatch(updateFilters({ dateRange: "all", category: [] }))
  }

  useEffect(() => {
    if (!selectedRubric && rubrics && rubrics.length) {
      selectRubric(rubrics[0].uuid)
    }
  }, [selectedRubric, rubrics])

  const hasSchoolYear: boolean =
    session.group.field_rewind_begin &&
    session.group.field_rewind_begin[0] &&
    session.group.field_rewind_begin[0].value &&
    session.group.field_rewind_end &&
    session.group.field_rewind_end[0] &&
    session.group.field_rewind_end[0].value
      ? true
      : false

  function exportBackpack() {
    const { category, dateRange, dateMin, dateMax } = backpack.filters

    type Query = {
      category?: number[]
      start?: string
      end?: string
      uid?: number
    }

    const query: Query = {}

    if (userId) {
      query.uid = userId
    }

    if (dateRange === "year" || dateRange === "week" || dateRange === "month") {
      query.start = moment().startOf(dateRange).format("YYYY-MM-DD")
      query.end = moment().add(1, "day").endOf(dateRange).format("YYYY-MM-DD")
    } else if (dateRange === "custom") {
      if (dateMin) {
        query.start = moment(dateMin.split("T")[0]).format("YYYY-MM-DD")
      }
      query.end = moment(dateMax.split("T")[0])
        .add(1, "day")
        .format("YYYY-MM-DD")
    } else if (dateRange === "schoolYear") {
      if (hasSchoolYear) {
        query.start = moment(session.group.field_rewind_begin[0].value).format(
          "YYYY-MM-DD"
        )
        query.end = moment(session.group.field_rewind_end[0].value).format(
          "YYYY-MM-DD"
        )
      } else {
        // Set up our default school year of June 1st - May 31st
        const today = moment().format("MM-DD-YYYY")
        let june = moment("06-01-" + moment(today).format("YYYY")).format(
          "MM-DD-YYYY"
        )
        let may = moment("05-31-" + moment(today).format("YYYY")).format(
          "MM-DD-YYYY"
        )

        // If we're before June in the current year, use last years June 1st date, otherwise we are in between June and next May
        if (moment(today).isBefore(june)) {
          june = moment(june).subtract(1, "year").format("MM-DD-YYYY")
        }

        // If we're in the same year as our correctly marked June 1st, go to next May
        // Otherwise we'll have last June to this May
        if (moment(june).format("YYYY") === moment(may).format("YYYY")) {
          may = moment(may).add(1, "year").format("MM-DD-YYYY")
        }

        query.start = moment(june).format("YYYY-MM-DD")
        query.end = moment(may).add(1, "day").format("YYYY-MM-DD")
      }
    }

    if (category.length > 0) {
      query.category = category
    }

    window.location.assign(
      process.env.REACT_APP_API_URL +
        "/mm-backpack/export?" +
        qs.stringify(query)
    )
  }

  let printUrl = "/backpack/print?"
  if (userId) {
    printUrl = "/backpack/" + userId + "/print?"
  }

  const printFilters: {
    dateRange: string
    dateMin: string | null
    dateMax: string
    category?: string
  } = {
    dateRange: backpack.filters.dateRange,
    dateMin: backpack.filters.dateMin,
    dateMax: backpack.filters.dateMax,
  }

  if (Array.isArray(backpack.filters.category)) {
    if (backpack.filters.category.length > 0) {
      printFilters.category = backpack.filters.category.join()
    }
  }
  const allRoles = session.groupRoles.concat(session.orgRoles).join(",")
  const canCompleteRubrics =
    allRoles.includes("observer") || allRoles.includes("observation")
  const canDownload =
    allRoles.includes("organization-admin") ||
    allRoles.includes("group-admin") ||
    allRoles.includes("reporter") ||
    !userId

  const canSeeCategories =
    !userId ||
    allRoles.includes("organization-admin") ||
    allRoles.includes("reporter") ||
    allRoles.includes("group-admin")

  const baseQuery = {
    dateMin: backpack.filters.dateMin,
    dateMax: backpack.filters.dateMax,
    uid: userId,
  }

  if (Array.isArray(backpack.filters.category)) {
    backpack.filters.category.forEach((category: number, index: number) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      baseQuery[`category[${index}]`] = category
    })
  }
  return (
    <div id="backpack-sidebar" style={{ paddingTop: userId ? 70 : 10 }}>
      <section style={{ paddingBottom: 60 }}>
        <header>Date Range</header>
        <ul>
          {!isEditing && (
            <li
              className={classNames("active")}
              style={{ borderRadius: 0, padding: "5px 0", marginTop: -10 }}
            >
              {dateRange === "all" && <>All Time</>}
              {dateRange === "week" && <>This Week</>}
              {dateRange === "month" && <>This Month</>}
              {dateRange === "schoolYear" && <>This School Year</>}
              {dateRange === "year" && <>This Year</>}
              {dateRange === "custom" && <>Custom Date Range</>}
            </li>
          )}
        </ul>
        <ul className={classNames("filterOptions", !isEditing && "shrink")}>
          <li
            className={classNames(selectedDateRange === "all" && "active")}
            style={{
              backgroundColor:
                selectedDateRange === "all" ? backgroundColor : "transparent",
            }}
            onClick={() => {
              selectDateRange("all")
            }}
          >
            All Time
          </li>
          <li
            className={classNames(selectedDateRange === "week" && "active")}
            style={{
              backgroundColor:
                selectedDateRange === "week" ? backgroundColor : "transparent",
            }}
            onClick={() => {
              selectDateRange("week")
            }}
          >
            This Week
          </li>
          <li
            className={classNames(selectedDateRange === "month" && "active")}
            style={{
              backgroundColor:
                selectedDateRange === "month" ? backgroundColor : "transparent",
            }}
            onClick={() => {
              selectDateRange("month")
            }}
          >
            This Month
          </li>
          <li
            className={classNames(
              selectedDateRange === "schoolYear" && "active"
            )}
            style={{
              backgroundColor:
                selectedDateRange === "schoolYear"
                  ? backgroundColor
                  : "transparent",
            }}
            onClick={() => {
              selectDateRange("schoolYear")
            }}
          >
            This School Year
          </li>
          <li
            className={classNames(selectedDateRange === "year" && "active")}
            style={{
              backgroundColor:
                selectedDateRange === "year" ? backgroundColor : "transparent",
            }}
            onClick={() => {
              selectDateRange("year")
            }}
          >
            This Year
          </li>
          <li
            className={classNames(selectedDateRange === "custom" && "active")}
            style={{
              backgroundColor:
                selectedDateRange === "custom"
                  ? backgroundColor
                  : "transparent",
            }}
            onClick={() => {
              selectDateRange("custom")
            }}
          >
            Custom Date Range
          </li>
        </ul>

        {selectedDateRange === "custom" && isEditing && (
          <div id="date-range-container">
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label="Date From"
                value={dateMin ? moment(dateMin) : null}
                onChange={(date) => {
                  // eslint-disable-next-line
                  // @ts-ignore
                  dispatch(setDateMin(JSON.stringify(date)))
                }}
              />
              <DatePicker
                label="Date To"
                value={dateMax ? moment(dateMax) : null}
                onChange={(date) => {
                  // eslint-disable-next-line
                  // @ts-ignore
                  dispatch(setDateMax(JSON.stringify(date)))
                }}
              />
            </LocalizationProvider>
          </div>
        )}

        {canSeeCategories && (
          <div style={{ marginTop: isEditing ? 15 : -5 }}>
            <header>Category</header>
            <ul>
              {!isEditing && (
                <li
                  className={classNames("active")}
                  style={{
                    borderRadius: 0,
                    padding: "5px 0",
                    marginTop: -10,
                    height: "auto",
                  }}
                >
                  {!activeCategories.length && "All"}
                  {activeCategories.length > 0 &&
                    activeCategories.map((category: CategoryTaxonomy) => {
                      return (
                        <li
                          style={{
                            padding: 0,
                            marginBottom: 10,
                            height: "auto",
                            pointerEvents: "none",
                            overflow: "visible",
                          }}
                          key={category.id}
                        >
                          {category.attributes.name}
                        </li>
                      )
                    })}
                </li>
              )}
            </ul>
            <ul className={classNames("filterOptions", !isEditing && "shrink")}>
              <li
                className={!selectedCategories.length ? "active" : ""}
                style={{
                  backgroundColor: !selectedCategories.length
                    ? backgroundColor
                    : "transparent",
                }}
                onClick={() => {
                  selectCategories([])
                }}
              >
                All
              </li>
              {categories.topCategories.map((cat: CategoryTaxonomy) => {
                const isActive = selectedCategories.includes(
                  cat.attributes.drupal_internal__tid
                )
                return (
                  <li
                    key={cat.id}
                    className={isActive ? "active" : ""}
                    style={{
                      backgroundColor: isActive
                        ? backgroundColor
                        : "transparent",
                    }}
                    onClick={() => {
                      if (!isActive) {
                        selectCategories(
                          selectedCategories.concat([
                            cat.attributes.drupal_internal__tid,
                          ])
                        )
                      } else {
                        selectCategories(
                          selectedCategories.filter(
                            (id: number) =>
                              id !== cat.attributes.drupal_internal__tid
                          )
                        )
                      }
                    }}
                  >
                    {cat.attributes.name}
                  </li>
                )
              })}
            </ul>
          </div>
        )}

        {!isEditing ? (
          <div className="secondary">
            <ButtonLarge
              style={{ marginTop: 10 }}
              onClick={() => setIsEditing(true)}
            >
              <span className="icon filter" />
              Edit Filters
            </ButtonLarge>
            {(selectedDateRange !== "all" || selectedCategories.length > 0) && (
              <ButtonLarge
                style={{ marginTop: 15 }}
                onClick={() => clearFilters()}
              >
                <span className="icon clear-filter" />
                Clear All
              </ButtonLarge>
            )}
          </div>
        ) : (
          <div
            style={{
              backgroundColor: "white",
              position: "fixed",
              padding: 15,
              paddingBottom: 50,
              left: 0,
              width: 220,
              bottom: 0,
            }}
          >
            <ButtonLarge
              onClick={() => {
                applyFilters()
              }}
            >
              <span className="icon filter" />
              Apply Filters
            </ButtonLarge>
            {userId && (
              <FormControlLabel
                className="saveDateSetting"
                label="Remember These Filters"
                control={
                  <Checkbox
                    style={{ color: theme.palette.secondary.main }}
                    checked={backpack.saveDateSetting}
                    onChange={(event) => {
                      setSaveDateSetting &&
                        dispatch(setSaveDateSetting(event.target.checked))

                      if (event.target.checked) {
                        localStorage.setItem(
                          "backpackSavedFilters",
                          JSON.stringify(backpack.filters)
                        )
                      } else {
                        localStorage.removeItem("backpackSavedFilters")
                      }
                    }}
                  />
                }
              />
            )}
          </div>
        )}
      </section>

      {!isEditing && canDownload && (
        <section>
          <header>Download Backpack</header>
          <div style={{ marginTop: 15 }}>
            <ButtonLarge onClick={exportBackpack}>
              <span className="icon spreadsheet" />
              CSV
            </ButtonLarge>
          </div>
          <div style={{ marginTop: 15 }}>
            <a
              href={printUrl + qs.stringify(printFilters)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <ButtonLarge>
                <span className="icon document" />
                PDF
              </ButtonLarge>
            </a>
          </div>
        </section>
      )}

      {userId &&
        !isEditing &&
        rubrics &&
        rubrics.length > 0 &&
        canCompleteRubrics && (
          <section className="rubricSelect">
            <header>Submit Rubric</header>

            <FormControl
              sx={{ marginLeft: "0px !important" }}
              variant="standard"
            >
              <Select
                variant="standard"
                value={selectedRubric}
                onChange={(event) => {
                  event.target.value && selectRubric(event.target.value)
                }}
              >
                {rubrics.map((rubric) => {
                  if (rubric.field_draft !== "1") {
                    return (
                      <MenuItem key={rubric.uuid} value={rubric.uuid}>
                        {he.decode(rubric.name)}
                      </MenuItem>
                    )
                  }
                  return null
                })}
              </Select>
            </FormControl>

            <div style={{ marginTop: 15 }}>
              <ButtonLarge
                onClick={() => {
                  selectedRubric &&
                    goToRubric &&
                    goToRubric(selectedRubric, userId)
                }}
              >
                <span className="icon rubric" />
                Start Rubric
              </ButtonLarge>
            </div>
          </section>
        )}

      {backpack.totalTime === 0 && backpack.fetched && !isEditing && (
        <section>
          {userId ? (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <LearningBuddyMessage
              message={
                "It looks like " +
                backpack.userData.field_first_name_value +
                " didn't record any PD credit for this Date Range and/or Category."
              }
            />
          ) : (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <LearningBuddyMessage
              message={
                "It looks like you didn't record any PD credit for this Date Range and/or Category."
              }
            />
          )}
        </section>
      )}
    </div>
  )
}

export default BackpackSidebar
